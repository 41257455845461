
import { Box, Spinner, Text, Tip } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../appContext";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import "./widgets.css"
import { MdInfo, MdInfoOutline } from "react-icons/md";
import ChartWidget from "./chartWidget";


const SIZES={
    "small":{

        width: "100px"
        
    },
    "medium":{

        width:"275px"
    },
    "large":{

        width:"530px",
        
    },
    "max":{

        width:"100%",
        
    },
   
}

export  default function WidgetBox({caption, size="medium", topRightCorner, loading, children}:{
    caption:any,
    topRightCorner?:any
    loading?:boolean
    children:any,
    size?:"small"|"medium"|"large"|"max"
}){
    
    
    return (
        <div className="widgetBox" style={{
            //height:  SIZES[size].width  + "px",
            width:  SIZES[size].width ,
            //minHeight:  SIZES[size].width  + "px",
            minWidth:  SIZES[size].width 
            }} >

            {loading?(
                <Box >
                <Text  size="xlarge" weight={900} alignSelf="start" color={"light-4"}> {caption}</Text>
                <Box align="center" justify="center" fill>
                      
                    <Spinner />
                </Box>
                </Box>
            ):(
            <Box flex={false}>   
            <Box direction="row" justify="between">
                <Text size="xlarge" weight={900}>{caption}</Text>
                {topRightCorner ? (<Box >{topRightCorner}</Box> ):(<div></div>)}
            </Box> 
                {children}
             </Box>
            )}
            

        </div>
    )
}
