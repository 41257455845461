


import { Box,   Drop,   Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useRef, useState } from "react"

import { IoMdClose } from "react-icons/io"


export  default function DeleteWithConfirmation({text, icon, onDelete,  disabled, ...rest}:{
    text?:string
    icon?:any
    
    disabled?:boolean
    onDelete?:()=>any
    [key:string]:any
}){
  
    
    const [deletePending, setDeletePending] = useState(false)
    const targetRef = useRef()
    return (
        <Box 
            focusIndicator={false}
            flex={false}
            className="button"
            round="5px"
            
            
            direction="row"            
            align="center" justify="center"
            
            pad={(rest as any).pad?undefined:"5px"} 
            gap={(rest as any).gap?undefined:"3px"} 
            {...rest}  onClick={(e)=>{
                e.stopPropagation()
                setDeletePending(true)}}
        >
            {(
            <Box ref={targetRef} gap="5px" direction="row" align="center" justify="center">
                {icon|| <IoMdClose/>} 
                {text && (<Text size={(rest as any).size || "small"}>{text}</Text>)}
            </Box>
            )}
           {deletePending && 
           <Drop align={{"top":"bottom"}} target={targetRef.current} 
          
           onClickOutside={(e)=>{
            e.stopPropagation()
            setDeletePending(false)}}>

            <Box direction="row" align="center" pad="5px" gap="5px" onClick={(e)=>{
                e.stopPropagation()
                onDelete()}}><IoMdClose/>Confirm delete</Box>
           </Drop>}
           
        </Box>    
    )

    
}

