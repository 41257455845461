


import { Box, Spinner, Text, TextArea, TextInput } from "grommet"
import {  PromptTemplateDefinition, PromptTemplateVersion } from "../../models/dataModel"


import { useEffect, useState } from "react"
import SearchBox from "../SearchBox/searchBox"
import { getApi } from "../../apiService"

import "./promptTemplateBox.css"
import { PromptTemplateIcon } from "../CommonIcons/commonIcons"
import Button from "../Button/button"
import Badge from "../Badge/badge"
import Popup from "../Popup/popup"
import { FormattedPrompt } from "../FormatedPrompt/formatedPrompt"
import { LlmPlayground } from "../LlmPlayground/LlmPlayground"
import Moment from "react-moment"


import { LoadingCover } from "../LoadingCover/loadingCover"
import { useSearchParams } from "react-router-dom"

export default function PromptTemplatesList({ promptTemplateDefinition, data, hasMore, onPromptTemplateChange, onSearch , loading}: {
    promptTemplateDefinition?:PromptTemplateDefinition,
    onPromptTemplateChange:(val:PromptTemplateDefinition)=>any,
    data:PromptTemplateDefinition[]|undefined, 
    hasMore?:boolean,
    onSearch:(val:string)=>any,
    loading?:boolean
}) {
    const [popup,setPopup]=useState<any>()
    
    const [searchQuery,setSearchQuery]=useState<string>()
    const [currentPromptTemplate,_setCurrentPromptTemplate]=useState<any>()
    //const [loading, setLoading]=useState<boolean>()
    //const [data,setData]=useState<PromptTemplate[]>()

  
    useEffect(()=>{
        if(promptTemplateDefinition && promptTemplateDefinition!=currentPromptTemplate){
            _setCurrentPromptTemplate(promptTemplateDefinition)
        }
    }
    ,[promptTemplateDefinition])

    const setCurrentPromptTemplate = (val)=>{
        if (val!=currentPromptTemplate){
            _setCurrentPromptTemplate(val)
            onPromptTemplateChange&&onPromptTemplateChange(val)
        }
    }

    

    useEffect(()=>{
  
        onSearch&&onSearch(searchQuery)
       
    },[searchQuery])


    return (
        <Box direction="row" className="second-level-sidebar" >
            
        {loading&&<LoadingCover/>}  
        <Box  background="dark-2" fill>
            {popup}
            <Box pad="5px" direction="row" justify="stretch" className="pageHeader" height="60px">
                 
                <Box margin="10px"  style={{maxWidth:"600px"}} flex="grow">
                    <SearchBox value={searchQuery} onApplySearch={setSearchQuery}/>
                </Box>
                
            </Box>
            <Box 
                background="dark-4" 
                flex={false}
                height="calc(100vh - 100px)"
                overflow="auto"
                >
                {data?.map((pt,i)=>(
                    <Box key={i} className="prompt-template-box" flex={false} 
                           onClick={()=>setCurrentPromptTemplate(pt)}
                        >
                        <Box direction="row" justify="between"  width="100%" > 
                            <Box direction="row" align="center" gap="2px" > 
                                <PromptTemplateIcon/> 
                                <Text size="small">Prompt template</Text>
                            </Box>
                                <Badge text={<Text size="small" color="dark-1">
                                    Last run:  <b><Moment fromNow>{pt.last_run}</Moment></b>
                                    </Text>}/>
                        </Box>
                            <Box>
                                <Text weight={900}>{pt.template_name}</Text>
                            </Box>
                    </Box>
                ))}
                { (hasMore)&&(
                    <Box margin="20px" round="5px" background="dark-2" width="200px" alignSelf="center" pad="5px">
                        <Text color="white" weight={900}>There seems to be more. Please use filter to narrow down the search. </Text>
                    </Box>
                )}
                {data && !data.length&&(
                    <Box pad="20px">
                        <Text color="white" weight={900}>No prompt templates yet</Text>
                    </Box>
                )}
            </Box>

        </Box>

       
        </Box>
    )


}

