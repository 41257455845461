


import { Box,  Spinner, Tab, Tabs, Text, TextArea, TextInput, ThemeContext } from "grommet"
import { PromptTemplateDefinition, PromptTemplateVersion, PromptUnitTest, PromptUnitTestRun } from "../../models/dataModel"


import { useEffect, useState } from "react"
import SearchBox from "../SearchBox/searchBox"
import { getApi } from "../../apiService"

import "./styles.css"
import { PromptTemplateIcon, UnitTestIcon, UnitTestRunIcon } from "../CommonIcons/commonIcons"
import Button from "../Button/button"
import Badge from "../Badge/badge"
import Popup from "../Popup/popup"
import { FormattedPrompt } from "../FormatedPrompt/formatedPrompt"
import { LlmPlayground } from "../LlmPlayground/LlmPlayground"
import Moment from "react-moment"

import LlmPromptComponent from "../Activities/LlmPrompt"
import { LoadingCover } from "../LoadingCover/loadingCover"
import { GrRefresh } from "react-icons/gr"
import { MdSearch } from "react-icons/md"
import PromptTestRunCard from "./promptTestRunCard"
import { useSearchParams } from "react-router-dom"



const PromptUnitTestCard = ({ promptUnitTest: put, onClick }: { promptUnitTest: PromptUnitTest, onClick }) => {
    const [searchParams,setSearchParams]=useSearchParams()
    return <Box className="prompt-test-box" flex={false}
        onClick={onClick}
    >
        <Box direction="row" justify="between" width="100%" >
            <Box direction="row" align="center" gap="2px" >
                <UnitTestIcon />
                <Text size="small" weight={900}>Unit test</Text>
            </Box>
        </Box>
        <Box>
            <Text weight={900}>{put.test_name}</Text>
            {put.definition?.for_template_name}
        </Box>
        <Box width="70%" border="bottom" alignSelf="center" margin="10px 0px 2px"></Box>
        <Box direction="row" justify="between" align="center">
            <b>Last run:</b>
            <Button primary text="Show run results" onClick={(e)=>{
                setSearchParams(new URLSearchParams({"unit-test-run":put.last_run.run_id}))
                e.stopPropagation()
                }}  pad="1px 2px"/>
        </Box>
        <PromptTestRunCard promptTestRun={put.last_run} />
    </Box>
}

const PromptUnitTestRunCard = ({ promptTestRun: run, onClick }: { promptTestRun: PromptUnitTestRun, onClick }) => {
    const [searchParams,setSearchParams]=useSearchParams()
    const selectedRunId = searchParams.get("unit-test-run")
    const selectedTestName = searchParams.get("test_name")
    return <Box 
        className={"prompt-test-box" + (selectedRunId === run.run_id ? " selected" : "")}
        flex={false}
        onClick={onClick}
        
        
    >
         <Box direction="row" justify="between" width="100%" >
            <Box direction="row" align="center" gap="2px" >
                <UnitTestRunIcon />
                <Text size="small"  weight={900}>Unit test run</Text>
            </Box>
        </Box>
        <Box>
            <Text weight={900}>{run.test_name}</Text>
        </Box>
        <PromptTestRunCard promptTestRun={run} />
    </Box>
}


export default function PromptUnitTestsList({ onSelectedChange,updatedUnitTestRun  }: {
    updatedUnitTestRun:PromptUnitTestRun // if set, refresh the item in the list
    onSelectedChange: (change:{test?: PromptUnitTest, run?:PromptUnitTestRun}) => any,
}) {
    const [popup, setPopup] = useState<any>()


    const [activeView,setActiveView]=useState<"tests"|"runs">("tests")
    const [loading, setLoading]=useState<boolean>()
    const [unitTests,setUnitTests]=useState<PromptUnitTest[]>()
    const [unitTestsRuns,setUnitTestsRuns]=useState<PromptUnitTestRun[]>()
    const [testHaveMoreData,setTestsHaveMoreData]=useState<boolean>()
    const [runsHaveMoreData,setRunsHaveMoreData]=useState<boolean>()
    
    const [pageSize, setPageSize]=useState<number>()
    const [testSearchQuery,setTestsSearchQuery]=useState<string>()
    const [runsSearchQuery,setRunsSearchQuery]=useState<string>()
    
    
    

    function search(searchQuery:string){
        
        setLoading(true)
        if (activeView=="tests"){

            getApi().getUnitTests(searchQuery,0,pageSize).then(d=>{
                setTestsSearchQuery(searchQuery)
                setTestsHaveMoreData((d.length>=pageSize))
                
                setUnitTests(d)
            }).finally(()=>setLoading(false))
        }
        else{
            getApi().getUnitTestRuns(searchQuery,0,pageSize).then(d=>{
                setRunsSearchQuery(searchQuery)
                setRunsHaveMoreData((d.length>=pageSize))
                
                setUnitTestsRuns(d)
            }).finally(()=>setLoading(false))
        }
    }


    useEffect(()=>{
        if (updatedUnitTestRun){
            // find this index among unit tests runs and update it
            const run_index=unitTestsRuns?.findIndex(r=>r.run_id==updatedUnitTestRun.run_id)
            if (run_index>=0){
                const newRuns=[...unitTestsRuns]
                newRuns[run_index]=updatedUnitTestRun
                setUnitTestsRuns(newRuns)
            }
            
            // and among unit tests (as last_run)
            const test_index=unitTests?.findIndex(r=>r.last_run.run_id==updatedUnitTestRun.run_id)
            if (test_index>=0){
                const newTests=[...unitTests]
                newTests[test_index].last_run=updatedUnitTestRun
                setUnitTests(newTests)
            }
        }
    },[updatedUnitTestRun])

    useEffect(()=>{
        if ((
                (activeView=="tests" && !unitTests) || 
                (activeView=="runs" && !unitTestsRuns) 
            ) ||
            testSearchQuery!==runsSearchQuery)
        {

            search(activeView=="runs"?runsSearchQuery:testSearchQuery)
        }
    },[activeView])


    



    return (
        <Box direction="row" className="second-level-sidebar" >
            {loading && <LoadingCover />}
            <Box background="dark-2" fill>
                {popup}
                <Box pad="5px" direction="row" justify="stretch"  height="60px" align="center">

                    <Box margin="10px 0px 10px 10px" style={{ maxWidth: "600px" }} flex="grow" >
                        <SearchBox onApplySearch={search} withButton />
                    </Box>


                </Box>


                
        <ThemeContext.Extend value={
            {   
                tab: {
                active: {
                  color: 'accent-1',
                },
                border: {
                    color: 'dark-4',
                  active: {
                    color: 'accent-1',
                  },
                 
                },
              }
            }
        }>
            
                    <Tabs alignControls="start" activeIndex={activeView=="tests"?0:1} onActive={(i)=>setActiveView(["tests","runs"][i] as any)}>
                    <Tab title="Unit tests">

                <Box
                    background="dark-4"
                    flex={false}
                    height="calc(100vh - 100px)"
                    overflow="auto"
                >
                    {unitTests?.map((pt, i) => (
                        <PromptUnitTestCard key={i} promptUnitTest={pt} onClick={() => onSelectedChange({test:pt})} />


                    ))}
                    {(testHaveMoreData) && (
                        <Box margin="20px" round="5px" background="dark-2" width="200px" alignSelf="center" pad="5px">
                            <Text color="white" weight={900}>There seems to be more. Please use filter to narrow down the search. </Text>
                        </Box>
                    )}
                    {unitTests && !unitTests.length && (
                        <Box pad="20px">
                            <Text color="white" weight={900}>No prompt unit tests yet</Text>
                        </Box>
                    )}
                </Box>


                </Tab>
                <Tab title="Recent runs">
                        
                <Box
                    background="dark-4"
                    flex={false}
                    height="calc(100vh - 100px)"
                    overflow="auto"
                >
                    {unitTestsRuns?.map((run, i) => (
                        

                        <PromptUnitTestRunCard key={i} promptTestRun={run} onClick={() => onSelectedChange({run:run})} />
                        


                    ))}
                    {(runsHaveMoreData) && (
                        <Box margin="20px" round="5px" background="dark-2" width="200px" alignSelf="center" pad="5px">
                            <Text color="white" weight={900}>There seems to be more. Please use filter to narrow down the search. </Text>
                        </Box>
                    )}
                    {unitTests && !unitTests.length && (
                        <Box pad="20px">
                            <Text color="white" weight={900}>No tests runs yet</Text>
                        </Box>
                    )}
                </Box>

                </Tab>
                </Tabs>
                
        </ThemeContext.Extend>

            </Box>


        </Box>
    )


}

