import { Accordion, AccordionPanel, Box, CheckBox, Spinner, Text, TextArea, TextInput, Tip } from "grommet"
import { useEffect, useMemo, useState } from "react"
import Button from "../Button/button"
import "./LlmPlayground.css"
import { Buffer } from "buffer"
import { MdAdd, MdError, MdHistory, MdPlayArrow, MdWarning } from "react-icons/md"
import { Minibadge } from "../MiniBadge/minibadge"
import {replaceMatches, wrapParamsInSpan} from "../../utils/helpers"
import { ClipboardEventHandler } from "react"
import { FocusEventHandler } from "react"
import { useRef } from "react"
import { ChatMessage, ChatMessageTemplate } from "../../models/dataModel"
import { ChatMessageBox } from "../ChatMessageBox/chatMessageBox"
import EditableBlock from "../EditableBlock/editableBlock"
import EditableTextBlock from "../EditableText/editableText"
import DeleteWithConfirmation from "../DeleteWithConfirmation/deleteWithConfirmation"
import {OpenAI} from "../../llms/openai"
import Popup from "../Popup/popup"
import sanitizeHtml from 'sanitize-html';
import FunctionCallBox from "../FunctionsCalls/functionCallBox"
import FunctionsBox from "../FunctionsCalls/functionsBox"
import FunctionEdit from "../FunctionsCalls/functionEdit"
import Moment from "react-moment"
const openAIApiKeyLocalStorageKey = "openAiApiKey"



type finalPromptType = (ChatMessage|{messages:ChatMessage[], _template:number})[] 

interface LlmPlaygroundParams {
    sourcePrompt?: string|ChatMessage[],
    promptTemplate: string|(string|ChatMessage|ChatMessageTemplate)[],
    params: { [key: string]: string|ChatMessage[] }
    model?: string
    llm_params?: { [key: string]: any }
    functions?:any,
    function_call?:string,

    generated?: string
    generated_function_call?:any
}

interface HistoryRecord extends LlmPlaygroundParams{
    timestamp:Date
} 
export const LlmPlayground = ({ sourcePrompt, promptTemplate, params, model, llm_params,functions, function_call, generated,generated_function_call }: LlmPlaygroundParams) => {

    const [history, setHistory] = useState<HistoryRecord[]>([{
        timestamp:new Date(),
        sourcePrompt,
        promptTemplate,
        params,
        model,
        llm_params,
        functions,
        function_call, 
        generated, 
        generated_function_call
    }])
    const addHistory=(rec:HistoryRecord)=>{
        const _historyCopy = JSON.parse(JSON.stringify([...history,rec]))
        setHistory(_historyCopy)
    }

    const [isWaiting, setIsWaiting] = useState<boolean>()
    const [apiKey, setApiKey] = useState<string>()
    const [popup, setPopup] = useState<any>()
    const [saveApiKey, setSaveApiKey] = useState<boolean>()
    const [errorText, _setErrorText] = useState<string>()

    const [_functions, set_functions]=useState(functions||[])
    const [_function_call, set_function_call]=useState(function_call)

    const setErrorText = (val) => {
        if (val!=errorText){
            _setErrorText(val)
        }
    }
    useEffect(() => {
        let val = apiKey;
        if (val && saveApiKey) {
            localStorage.setItem(openAIApiKeyLocalStorageKey, apiKey);
        } else if (val) {
            localStorage.removeItem(openAIApiKeyLocalStorageKey);
        }
    }, [apiKey, saveApiKey])

    const tryToLoadApiKeyFromLocalStorage = () => {
        // Retrieve the encrypted data and IV from local storage
        try {
            const encryptedDataStr = localStorage.getItem(openAIApiKeyLocalStorageKey);
            if (encryptedDataStr) {

                setApiKey(encryptedDataStr)
                setSaveApiKey(true)
            }

        }
        catch {

        }
    }

    useEffect(() => {
        tryToLoadApiKeyFromLocalStorage()
    }, [])

    const deepCopy = (obj) => {
        if (obj && typeof (obj) == "object"){
            return JSON.parse(JSON.stringify(obj))
        }
        else if (Array.isArray(obj)){
            return [...obj]
        }
        else{
            return obj
        }
    }


    function moveItemInArray(array, item, relativeMove) {
        let oldIndex =undefined
        if (typeof(item)==="number"){
            oldIndex=item
            item=array[item]
        }else{
            oldIndex =  array.indexOf(item);

        }
        let newIndex = oldIndex + relativeMove;
        if (newIndex < 0 || newIndex == array.length) return array;
        let newArray = [...array];
        newArray.splice(oldIndex, 1);
        newArray.splice(newIndex, 0, item);
        return newArray;
    }

    const [finalPrompt, _setFinalPrompt] = useState<string|finalPromptType>(deepCopy(sourcePrompt) || "")
    useEffect(()=>{
        if (!promptTemplate){
            //Initialize if we dont have prompt template
            _setFinalPrompt(deepCopy(sourcePrompt))
        }
    },[promptTemplate])

    const [_promptTemplate, __setPromptTemplate] = useState<string|(string|ChatMessage|ChatMessageTemplate)[]>(deepCopy(promptTemplate) || "")
    const _setPromptTemplate=(val)=>{
        __setPromptTemplate(val)
    }
    const [_params, _setParams] = useState(deepCopy(params))
    const [isChat, setIsChat] = useState(false)

    useEffect(() => {
        if(isChat){
            let newParams=params?{..._params}:{}
            // update the params with values from all records in _promptTemplate
            if (Array.isArray(_promptTemplate)){
                _promptTemplate.forEach((msg)=>{
                    if ((msg as ChatMessageTemplate).prompt_input_params){
                        Object.keys((msg as ChatMessageTemplate).prompt_input_params).forEach((k)=>{
                            if (!newParams[k]){
                                newParams[k]=""
                            }
                        })
                    }
                })
            }
        
        }
    },[isChat])
    
    const [isGenerationPending, setIsGenerationPending] = useState(0)

    
    useEffect(() => {
        setIsChat(typeof(sourcePrompt)!="string")
    }, [sourcePrompt])

    const [_generated, setGenerated] = useState(generated)
    const [_generated_function_call,setGenerated_function_call]=useState(generated_function_call)

    const [modelSettings, setModelSettings] = useState({
        "model": model || "gpt-3.5-turbo",

        "temperature": 0,
        "max_tokens": 1000,
        "top_p": 1,
        "frequency_penalty": 0.0,
        "presence_penalty": 0.0,
        "stop": [],
    })

    
    useEffect(() => {
        if (llm_params) {
            let newModelSettings = { ...modelSettings }
            if (llm_params.model_name) {
                newModelSettings.model = llm_params.model_name
            }
            Object.keys(newModelSettings).forEach(k => {
                if (llm_params[k] !== undefined) {
                    try {
                        newModelSettings[k] = llm_params[k]
                    } catch {

                    }
                }
            })
            setModelSettings(newModelSettings)
        }


    }, [llm_params])

    const [visualizationType, setVisualizationType] = useState(promptTemplate?"decomposed":"interpolated")
    const [errors, setErrors] = useState<{type:"error"|"warning",message:string, src:string}[]>()
    const addError=(type:"error"|"warning",message:string,src:string)=>{
        setErrors((oldList)=>{
            if (oldList?.find(e=>e.type==type && e.message==message && e.src==src)){
                return oldList
            }
            else{
                let newList = [...(oldList||[])]
                newList.push({
                    type,message,src
                })
                return newList
            }

        })
    }
    const clearErrors = (src:string)=>{
        setErrors((oldList)=>{
            return oldList?.filter(e=>e.src!==src)
        })
    }

    useEffect(() => {
        if (_params && _promptTemplate &&  (_promptTemplate != promptTemplate || _params != params)) {
            clearErrors("interpolation")
            let interpolated=undefined
            if (typeof(_promptTemplate)==="string"){
                interpolated = formatFString(_promptTemplate, _params)
            }
            else if (Array.isArray(_promptTemplate)){
                interpolated=[ ] as finalPromptType
                let offset =0 //offset form the history (message placeholders)
                _promptTemplate.forEach((msg, i)=>{
                    if ((msg as ChatMessageTemplate).prompt_template !==undefined){
                        const msgTemplate = msg as ChatMessageTemplate;
                        let newMsg = {role:msgTemplate.role, text:msgTemplate.prompt_template, _template:i } as ChatMessage
                        newMsg.text = formatFString(msgTemplate.prompt_template, _params)
                        interpolated.push(newMsg)
                    }
                    else if (typeof(msg)==="string"){
                        interpolated.push({
                            messages:_params[msg]||[],
                            _template:i
                        })
                    }

                })

            }
            setFinalPromptText(interpolated)

        }
        else if (isGenerationPending) {
             invokeOpenAI(finalPrompt)
        }

    }, [_promptTemplate, _params, isGenerationPending])



    const setFinalPromptText = (newVal) => {
        if (newVal) {
            _setFinalPrompt(newVal)
        }
        if (isGenerationPending) {
            invokeOpenAI(newVal)
        }
    }




    function formatFString(template, parameters) {
        // Replace each {key} in the template with its corresponding value from the parameters object
        //return template.replace(/\{([^{}]+)\}/g, (_, key) => parameters[key] || '');

        // let regexPattern = Object.keys(parameters).map(param => {
        //     return `(?<!\\{)\\{\\s*${param}\\s*\\}(?!\\})`
        // }).join("|")
        // let regex = new RegExp(regexPattern, "g")
        // const strip = s=> s.substring(1, s.length - 1);
        // let regexPattern = /(?<!\{)\{\s*\w+\\s*\}(?!\})/
        // let regex = new RegExp(regexPattern, "g")
        // const strip = s=> s.substring(1, s.length - 1);
        // return replaceMatches(template, regex, (match)=>{
        //     return parameters[strip(match[0]).trim()] ||""
        // }).join("").replace(/\{\{/g, "{").replace(/\}\}/g, "}")
        return template.replace(/(?<!\{)\{([^{}]+)\}(?!\})/g, (_, key) => {
            return parameters.hasOwnProperty(key) ? parameters[key] : '';
        });
    }

    function invokeOpenAI(prompt) {
        setIsGenerationPending(0)
        setIsWaiting(true)
        setErrorText(null)
        setGenerated("")
        let openai = new OpenAI(apiKey, isChat?"chat":"completion")
        
        let absoluteFinalPrompt = prompt
        if (Array.isArray(prompt)){
            absoluteFinalPrompt = prompt.map(m=>m.messages || m).flat().filter(m=>m.text?.length).map(m=>{
                return m.role!=="function"? ({role:m.role, text: m.text}) :({role:m.role, text: m.text, name:m.metadata?.name || "function"})
            })
        }
        let funcCallOptions = _functions?.length?{functions:_functions}:{}
        if (_function_call&&_functions){
            funcCallOptions["function_call"]=_function_call
        }

        addHistory({
            timestamp:new Date(),
            sourcePrompt:absoluteFinalPrompt,
            promptTemplate:_promptTemplate,
            params:_params,
            model:modelSettings.model || ((modelSettings as any).model_name),
            llm_params:{
                stop:modelSettings.stop,
                temperature:modelSettings.temperature,
                max_tokens:modelSettings.max_tokens,
                top_p:modelSettings.top_p,
                presence_penalty:modelSettings.presence_penalty,
                frequency_penalty:modelSettings.frequency_penalty,
            },
            functions:_functions?.length?_functions:undefined,
            function_call:_function_call, 
            generated:_generated, 
            generated_function_call:_generated_function_call
        })

        openai.execute(absoluteFinalPrompt,{...modelSettings, ...funcCallOptions}, token=>{
            setGenerated((oldGenerated)=>oldGenerated+token)
        }).then((newGenerated) => {
            setIsWaiting(false)
            setGenerated(newGenerated.text)
            setGenerated_function_call(newGenerated.function_call)
            console.log(newGenerated.function_call)
            
        }).catch((error) => {
            setTimeout(() => {
            setErrorText(error.message || error.toString())
            setIsWaiting(false)
            setGenerated(undefined)
            setGenerated_function_call(undefined)
            },10)
        })

    }

    function modifyParam(param, newVal) {
        let newParameters = { ..._params }
        newParameters[param] = newVal ||""
        _setParams(newParameters)
    }

    function modifyModelSettings(param, newVal) {
        let newModelSettings = { ...modelSettings }
        newModelSettings[param] = newVal
        setModelSettings(newModelSettings)
    }

    function displayWhitespace(str) {
        return str.replace(/ /g, '␣')  // replace spaces with '␣' character
            .replace(/\t/g, '→') // replace tabs with '→' character
            .replace(/\n/g, '↵\n'); // replace line breaks with '↵' character
    }

 

    const [formattedPromptText, setFormattedPromptText] = useState()
    

    function tryToFormatPromptText(text, template?:string){
        let spans: any = [text||template].map((text, i) => <span key={i}>{text}</span>)
        try {
            
            if(template ){

                if (visualizationType == "interpolated") {
                    spans = wrapParamsInSpan(text, template, _params
                        , (match,i)=>(
                        <span key={i}  id={Object.keys(_params).find(key => _params[key] === match)} className={"prompt-parameter"}>
                            {match}
                        </span>
                        ))
                }
                else {
                    if ( template && _params &&  Object.keys(_params).length){
                        
                        // let regexPattern = Object.keys(_params).map(param => {
                        //         return `(?<!\\{)\\{\\s*${param}\\s*\\}(?!\\})`
                        //     }).join("|")
                            let regex = null;
                            try{

                                regex = new RegExp('(?<!\\{)\\{\\s*[\\w|_|-]*\\s*\\}(?!\\})', 'g')
                            }
                            catch(e){
                                console.log(e)
                                //safari doesn't support negative lookbehind
                                regex =RegExp(/([^\\])\{\s*[\w|_|-]*\s*\}(?!\})/, "g")

                            }
                            
                            spans =replaceMatches(template, regex, (match)=>{
                                const valid = Object.keys(_params).find(key => "{"+key+"}" === match[0])?true:false
                                return (
                                <span key={match[0]} id={match[0]} className={valid?"prompt-parameter":"prompt-parameter missing"}>
                                    
                                    {match[0]}
                                
                                </span>
                                )})
                        }
                    }
            }
                
            return spans
                
        }
        catch (error) {
            console.log(error)
        }
        return text
        
    }
    function tryToExtractParametersChange(editedHtml){
        const pattern = /<span\s+id="(\w+)"[^>]*>([^<]*)<\/span>/g;
        
        let match;
        const result = {};
        let htmlString= editedHtml.replaceAll(new RegExp("<div>","g"),"<br><div>").replace(/<br>/g, "\n");
        
        while ((match = pattern.exec(editedHtml)) !== null) {
          const id = match[1];
          const content = match[2];
          if (_params[id] !== content){
              result[id] = content;
          }
          htmlString = htmlString.replace(match[0], `{${id}}`);
        }
        const sanitized = sanitizeHtml(htmlString, {
            allowedTags: [],
            allowedAttributes: {},
            allowedSchemes: [],
          });

        return {changed_params: result, clean_text:sanitized.trim()};
        
    }
    
    useEffect(()=>{
        if (_promptTemplate && typeof(_promptTemplate)==="string"){
            let formatted_prompt= tryToFormatPromptText(finalPrompt,_promptTemplate )
            setFormattedPromptText(formatted_prompt)
        }
      
    },[_promptTemplate,_params, visualizationType])



    const [isEditing, setIsEditing] = useState(false)



/////// edit section

    function matchMessagesWithTemplate(messages,templates):{msg_index:number, template_index:number, param_index?:number, message:ChatMessage, template:ChatMessageTemplate}[]{
        let offset=0
        if (!templates || !messages) return undefined;
        // try to match template with messages
        let promptMessagesWithTemplate =  (templates as (string|ChatMessageTemplate)[])?.map((template,i)=>{
            if (typeof(template)==="string"){
                let messagesInParams:any =  _params[template] || []
                let previous_offset=offset
                offset+=((messagesInParams.length))-1

                let messageParamsTest= messagesInParams.every((messageInParams, j)=>messageInParams.text == (messages[i+previous_offset+j] as ChatMessage).text)
                
                if (messageParamsTest){
                    return  messagesInParams.map((messageInParams, j)=>{
                        return {
                            msg_index:i+previous_offset+j,
                            param_index:j,
                            template_index:i,
                            message:messageInParams,
                            template:template
                        }
                    })
                }
                else{
                    return [] // this will mark that there was a problem with matching and we can't use this, because the number of message wont match
                }
            }
            else{
                return {msg_index:i+offset, template_index:i, message:messages[i+offset] as ChatMessage, template:template}
                
            }
        }).flat()

        if (promptMessagesWithTemplate.length!=messages.length){
            return null
        }
        else
            return promptMessagesWithTemplate

       
    }
    function escapeRegex(string) {
        return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    function deleteTemplateMessage(i:number){
        // let matches=matchMessagesWithTemplate(finalPrompt,_promptTemplate as (string|ChatMessageTemplate)[])
        // if (!matches){
        //     setErrorText("Something went wrong with matching messages with template.")
        // }
        let finalMsgMatchIndex = (finalPrompt as any[]).findIndex(p=>p._template==i)

        let newPromptTemplate = [...(_promptTemplate as any[])]
        newPromptTemplate.splice(i,1)
        _setPromptTemplate(newPromptTemplate)

        //also update the final messages
        let newPrompt = [...(finalPrompt as any[])]
        newPrompt.splice(finalMsgMatchIndex, 1)
        setFinalPromptText(newPrompt)
    }

    function getEditSection() {
        
        if (isChat && Array.isArray(finalPrompt)) {
            // TEMPLATE EDITING
            let messageElements=undefined
            if (visualizationType!="interpolated"){
                messageElements=   <Box flex={false}>
                {Array.isArray(_promptTemplate) && (_promptTemplate as (string|ChatMessageTemplate)[])?.map((template, i)=>(
                 typeof(template)==="string"?(
                    
                    <Box key={i}  gap="xsmall"  pad="5px" background="light-2" border margin="2px 10px" round="5px" direction="row" justify="center" align="center">
                        <Box flex="grow">

                        <Text  textAlign="center" size="large">{`{${template}}`}</Text>
                        </Box>
                        <DeleteWithConfirmation onDelete={()=>deleteTemplateMessage(i)}/>
                
                    </Box>
                   ):(
                     <div key={i} className="prompt-message">
                        <ChatMessageBox chatMessageTemplate={template}
                        editable={true}
                        formatFunction={tryToFormatPromptText}
                        onMove={(direction)=>{
                            if (template){
                                _setPromptTemplate(moveItemInArray((_promptTemplate as any[]),template,direction=="up"?-1:1))
                            }
                         
                        }}
                        onDelete={()=>{
                            deleteTemplateMessage(i)
                        }}
                        onChanged={(text, role, html,name)=>{
                            if (role!=template.role||text!=template.prompt_template){
                                let newPrompt = [...(_promptTemplate as any[])]
                                if (role){

                                    newPrompt[i].role = role
                                }
                                if (name!=undefined){
                                    newPrompt[i].name = name
                                }
                                if (text!==undefined){

                                    newPrompt[i].prompt_template = text
                                }
                                _setPromptTemplate(newPrompt)
                            }            
                        }}
                        /> 
                    </div>)
                ))}
                
                </Box>
            }
            else{
                // FINAL PROMPT EDITING
                // let matches=matchMessagesWithTemplate(finalPrompt,_promptTemplate as (string|ChatMessageTemplate)[])
                // let promptMessagesWithTemplate = matches?.map((match,i)=>{
                //     let res = {...match.message} as any
                //     res.template=match.template
                //     return res
                // })

                // if (_promptTemplate && !promptMessagesWithTemplate){
                //     setErrorText("Something went wrong with matching messages with template.")
                // }

                

                messageElements= <Box flex={false}>
                    {((finalPrompt) as finalPromptType)?.map((message, i) => {
                       const template =  typeof(message._template)==="number" ? _promptTemplate[message._template]:undefined
                       let subMessages=undefined
                       if ((message as ChatMessage).role){
                            subMessages=[ message as ChatMessage]
                       }
                       else if ((message as any).messages !==undefined){
                            subMessages=(message as any).messages
                       }
                        const result = subMessages.map((chatMessage,subI)=> (
                            <div key={1000*i+subI} className="prompt-message">
                            <ChatMessageBox chatMessage={chatMessage} chatMessageTemplate={template as any}
                                editable={true}
                                formatFunction={tryToFormatPromptText}
                                onMove={(direction)=>{
                                    if ((message as any).messages && template){
                                        
                                        if (typeof(template)==="string" &&  _params[template]){
                                            let newParams = {..._params}
                                            newParams[template] = moveItemInArray(newParams[template],subI,direction=="up"?-1:1)
                                            _setParams(newParams)
                                            return
                                        }
                                    }
                                    //delete the original parameter
                                    let newPrompt = [...(finalPrompt as any[])]
                                    newPrompt = moveItemInArray(newPrompt,i,direction=="up"?-1:1)
                                    setFinalPromptText(newPrompt)
                                    
                                    if (template){
                                        _setPromptTemplate(moveItemInArray((_promptTemplate as any[]),template,direction=="up"?-1:1))
                                    }
                                }}
                                onDelete={() => {
                                    if ((message as any).messages && template){
                                        
                                        if (typeof(template)==="string" &&  _params[template]){
                                            let newParams = {..._params}
                                            delete newParams[template][subI]
                                            _setParams(newParams)
                                            return
                                        }
                                    }

                                    //delete the original parameter
                                    let newPrompt = [...(finalPrompt as any[])]
                                    newPrompt.splice(i, 1)
                                    setFinalPromptText(newPrompt)
                                    
                                    if (template){
                                        _setPromptTemplate((_promptTemplate as any[]).filter((t,i)=>t!=template))    
                                    }

                                }}
                                onChanged={(text, role, html, name) => {
                                    if (role != chatMessage.role || text != chatMessage.text) {
                                            
                                            if (template && text){
                                                let newPromptTemplate = [...(_promptTemplate as ChatMessageTemplate[])]
                                                let extracted = undefined
                                                if (html || text){
                                                    extracted = tryToExtractParametersChange(html || text)
                                                }
                                                let {changed_params, clean_text} = extracted ||{changed_params:undefined, clean_text:undefined}
                                                


                                                let newParams = {..._params}
                                                if (changed_params&& Object.keys(changed_params).length){
                                                    newParams={..._params, ...changed_params}
                                                }
                                             
                                                if (_params && template && typeof(template)==="string"){
                                                    try{
                                                        
                                                        let placeholderMessages = newParams[template] || []
                                                        newParams[template]=placeholderMessages
                                                        
                                                        if (placeholderMessages[subI]){
                                                            placeholderMessages[subI]={
                                                                text:text===undefined?placeholderMessages[subI].text:text, 
                                                                role:role===undefined?placeholderMessages[subI].role:role, 
                                                                
                                                                
                                                            }
                                                            if (name!=undefined && placeholderMessages[subI].name!=undefined){

                                                                placeholderMessages[subI].name=name
                                                            }
                                                        }
                                                        else if (subI = placeholderMessages.length ){
                                                            let newMsg = {text:text, role:role, name:name}
                                                            if (name!=undefined){
                                                                newMsg=name
                                                            }
                                                            placeholderMessages.push(newMsg)
                                                        }
                                                        
                                                    }
                                                    catch(e){
                                                        setErrorText("Something went wrong with matching messages with template.")
                                                        console.error(e)
                                                        return
                                                    }
                                                }
                                                else{
                                                    if (role!==undefined){
                                                        newPromptTemplate[message._template].role = role
                                                    }
                                                    if (clean_text!==undefined){
                                                        let _clean_text = clean_text
                                                        _clean_text = clean_text.replace(/\{/g,"{{").replace(/\}/g,"}}")
                                                        if (_params){
                                                            Object.keys(_params).forEach((k)=>{
                                                                //replace back the 
                                                                _clean_text = _clean_text.replace(`{{${k}}}`,`{${k}}`)
                                                            })
                                                        }
                                                        newPromptTemplate[message._template].prompt_template =_clean_text
                                                    }
                                                    _setPromptTemplate(newPromptTemplate)
                                                }
                                                _setParams(newParams)

                                            }else{
                                                let newPrompt = [...(finalPrompt as any[])]
                                                if (role){
                                                    newPrompt[i].role = role
                                                }
                                                if (text!=undefined){
                                                    newPrompt[i].text = text
                                                }
                                                if (name!=undefined){
                                                    newPrompt[i].name=name
                                                }
                                                setFinalPromptText(newPrompt)
                                            }
                                        }
                                    }

                                }
                            />
                        </div>))
                        if ((message as any).messages ){
                            return (<Box pad="2px" border align="stretch" round="2px" margin="0px 5px">
                                        <Text textAlign="center" size="10px">{_promptTemplate[message._template] as string}</Text>
                                        {result}
                                </Box>)
                        }
                        else{
                            return result
                        }
                       })
                    }
            
           
                </Box>

                }
                
                return <>
                {messageElements}
                
                {isWaiting ? (
                    <Box align="center" pad="5px">
                        <Spinner/>
                    </Box>
                ):(
                <>
                {_generated && 
                    <ChatMessageBox isGenerated chatMessage={{role:"assistant", text: _generated}} /> 
                }
                {_generated_function_call && <FunctionCallBox function_call={_generated_function_call} />}
                </>
                )}
                <Box flex={false} pad="5px">
                                <Button secondary
                                    text="Add message" 
                                    icon={<MdAdd/>}
                                    onClick={()=>{
                                        let newPrompt = [...(finalPrompt as any[])]
                                        let newPromptTemplate = [...(_promptTemplate as any[])]
                                        // assigning new msg to template means that we need to add the last message and its reply (generation) into history placeholder
                                        let generated_msg=undefined
                                        if (_generated){
                                            generated_msg = { role: "assistant", text: _generated }

                                        
                                            newPromptTemplate.push({ role: "assistant", prompt_template: ((_generated as any).text || _generated).replace(/\{/,"{{").replace(/\}/,"}}") }) // we need to escape { because it is used in template
                                        }
                                        newPromptTemplate.push({ role: "user", prompt_template: "" })
                                        if (generated_msg)
                                            newPrompt.push(generated_msg)
                                        newPrompt.push({ role: "user", text: "" })
                                        setGenerated(undefined)
                                        setFinalPromptText(newPrompt)
                                        _setPromptTemplate(newPromptTemplate)
                                }}/>
                            </Box>
                </>
        }
        else if (typeof(finalPrompt)=="string" && typeof(_generated)=="string"){
            return (<EditableBlock
            onBeginEditing={()=>setIsEditing(true)} onFinishEditing={(text, html )=> {
                setIsEditing(false)
                if (visualizationType=="interpolated"){
                    let {changed_params, clean_text} = tryToExtractParametersChange(html)
                    _setParams(changed_params)
                    _setFinalPrompt(text)
                    _setPromptTemplate(clean_text)
                }
                else{
                    _setPromptTemplate(text)
                }
                }}
        >
                {visualizationType=="interpolated" ? formattedPromptText:_promptTemplate}
            </EditableBlock>)
            if (visualizationType=="interpolated"){
                return (<Box>
                  


                <EditableBlock
                onBeginEditing={()=>setIsEditing(true)} 
                onFinishEditing={(text,html) => {
                        setIsEditing(false)
                        setFinalPromptText(text)
                        }}>
                    {isEditing?_promptTemplate||finalPrompt:formattedPromptText}
                </EditableBlock>
                {(_generated || isWaiting)&&<Box className="generated-box" flex={false}>
                <span className="prompt-generated">{isWaiting ? (<Spinner />) : (<mark>{(_generated)}</mark>)}</span>
                </Box>}
                </Box>)
            }
            else{
                return  <Box>

                <EditableBlock 
                onFocus={()=>setIsEditing(true)} onFinishEditing={text => {
                        setIsEditing(false)
                        _setPromptTemplate(text)
                    }}>
                    {isEditing?_promptTemplate:formattedPromptText}
                </EditableBlock>
                {(_generated || isWaiting)&&<Box className="generated-box" flex={false}>

                <span className="prompt-generated">{(isWaiting&&!_generated) ? (<Spinner />) : (<mark>{_generated}</mark>)}</span>
                </Box>}
            </Box>
            }
        }
    }

    const editSection = useMemo(() => getEditSection(),[isChat,isEditing, finalPrompt,formattedPromptText,_generated,_promptTemplate, visualizationType, _params, isWaiting, _generated_function_call])


/////// edit section

    const [apiKeyFocused, setApiKeyFocused] = useState(false)



    return (
        <Box pad="2px"  flex="grow">
            {popup}
            <Box direction="row" flex={false} justify="start" align="center" gap="5px" style={{ position: "sticky", top: 0 }} background="white" pad="5px">
                <Text size="small">Edit as: </Text>
                <Button background={visualizationType == "interpolated" ? "brand" : undefined} text="Interpolated" onClick={() => setVisualizationType("interpolated")} />
                
                <Button background={visualizationType == "decomposed" ? "brand" : undefined} text="Template and parameters" 
                disabled={!(params && promptTemplate)}
                onClick={() => setVisualizationType("decomposed")} />
                
            </Box>
            <Box direction="row-responsive" background="light-3" round="15px" overflow="auto" height="80vh" width="80vw"  >
                <Box height="100%" overflow="auto" align="stretch" basis="3/4" >
                    <Box  style={{ position: "sticky", top: 0 }} flex={false}>
                    {errorText && (
                        <Box background="rgba(255,240,240)" pad="5px" direction="row" round="5px" gap="5px" flex={false} >
                            <MdError color="red" />
                            <Text size="small" color="red">{errorText}</Text>
                        </Box>
                    )}
                    {errors?.length>0 && (
                        errors.map(e=>(

                            <Box background={e.type=="error"?"rgba(255,240,240)":"#F5D44E"} pad="5px" direction="row"  gap="5px" flex={false}>
                            {e.type=="error"?<MdError color="red" />:<MdWarning/>}
                            <Text size="small" color={e.type=="error"?"red":"black"}  >{e.message}</Text>
                        </Box>
                            ))
                    )}
                    </Box>
                    <Box flex={false}>
                        {_functions&&(
                            <Box margin="10px 5px 0px">
                            <FunctionsBox functions={_functions} function_call={_function_call}
                            onDelete={(func)=>{
                                set_functions([..._functions.filter(f=>f!=func)])
                            }}
                            onFunctionCallChange={(funcCall)=>{
                                set_function_call(funcCall)
                            }}
                            onFunctionEdit={(funcData)=>{
                                let index = _functions.indexOf(funcData)
                                setPopup(<Popup
                                    caption="Edit available functions"
                                    onClose={()=>setPopup(undefined)}
                                    >
                                        <FunctionEdit 
                                        onCancel={()=>setPopup(undefined)}
                                        functionInfo={funcData} 
                                        onApplyEdit={(newFuncData)=>{
                                            let newFuncs=[..._functions]
                                            newFuncs[index]=newFuncData
                                            set_functions(newFuncs)
                                            setPopup(undefined)
                                            }} />
                                    </Popup>
                                    )
                            }}
                            onAdd={()=>{
                                setPopup(<Popup
                                    caption="Edit available functions"
                                    onClose={()=>setPopup(undefined)}
                                    >
                                        <FunctionEdit 
                                        onCancel={()=>setPopup(undefined)}
                                        functionInfo={{
                                            name:"new_function", 
                                            
                                            parameters:{
                                                type:"string",
                                                description:"",
                                                properties:{
                                                },
                                                required:[]
                                            }
                                        }}
                                        onApplyEdit={(newFuncData)=>{
                                            let newFuncs=[..._functions]
                                            newFuncs.push(newFuncData)
                                            set_functions(newFuncs)
                                            setPopup(undefined)
                                            }} />
                                     
                                    </Popup>
                                    )
                            }}
                            />
                            </Box>
                        )}
                        {editSection}
                        
                     
                    </Box>

                </Box>

                <Box width="300px" height="79vh" direction="column" flex={false} overflow="auto" style={{ position: "sticky", top: 0 }} >
                    <Box style={{ position: "sticky", top: 0, zIndex: 100 }} pad="5px" flex={false} background="light-2">
                        <Box pad="5px">
                            <Text size="small" weight={900}>Api key</Text>
                            <TextInput value={apiKeyFocused?apiKey:"*".repeat((apiKey?.length||0))} onFocus={()=>setApiKeyFocused(true)} onBlur={()=>setApiKeyFocused(false)} className="editField" suggestions={[]} onChange={e => setApiKey((e.target as any).value)} />
                            <CheckBox checked={saveApiKey} onChange={e => setSaveApiKey(e.target.checked)} label={
                                <Box>
                                    <Text size="12px" weight={900}>Save</Text>
                                    <Text size="9px" weight={300}>The api key is stored in your browser. It is never sent to our servers</Text>
                                </Box>
                            } />

                        </Box>

                        <Button primary={!isWaiting} secondary={isWaiting} disabled={isWaiting}
                            icon={isWaiting ? (<Spinner size="small" />) : <MdPlayArrow />}
                            text={isWaiting ? ("Generating") : "Run!"} pad="5px" onClick={() => setIsGenerationPending(isGenerationPending + 1)} />
                    </Box>
                    <Box margin="10px 5px 0px">
                        <Text size="small" weight={900}>Model</Text>
                        <TextInput className="editField" suggestions={["gpt-3.5-turbo","gpt-4", "gpt-3.5-turbo-1106","gpt-4-1106-preview","gpt-4-0125-preview","gpt-3.5-turbo-instruct"]} value={modelSettings.model} 
                        onSuggestionSelect={(e)=>{
                            modifyModelSettings("model", e.suggestion)
                        }}
                        onChange={e => modifyModelSettings("model", e.target.value)}  />
                        <Accordion>
                            <AccordionPanel label={<Text weight={900}>Model params</Text>} >
                                <Text size="small" weight={900}>temperature</Text>
                                <TextInput className="editField" value={modelSettings.temperature} onChange={e => modifyModelSettings("temperature", (e.target.value))} onBlur={e => modifyModelSettings("temperature", (e.target.value))} />
                                <Text size="small" weight={900}>max_tokens</Text>
                                <TextInput className="editField" value={modelSettings.max_tokens} onChange={e => modifyModelSettings("max_tokens", (e.target.value))} onBlur={e => modifyModelSettings("max_tokens", parseInt(e.target.value))} />
                                <Text size="small" weight={900}>top_p</Text>
                                <TextInput className="editField" value={modelSettings.top_p} onChange={e => modifyModelSettings("top_p", parseInt(e.target.value))} onBlur={e => modifyModelSettings("top_p", parseInt(e.target.value))} />
                                <Text size="small" weight={900}>presence_penalty</Text>
                                <TextInput className="editField" value={modelSettings.presence_penalty} onChange={e => modifyModelSettings("presence_penalty", (e.target.value))} onBlur={e => modifyModelSettings("presence_penalty", parseInt(e.target.value))} />
                                <Text size="small" weight={900}>frequency_penalty</Text>
                                <TextInput className="editField" value={modelSettings.frequency_penalty} onChange={e => modifyModelSettings("frequency_penalty", parseInt(e.target.value))} onBlur={e => modifyModelSettings("frequency_penalty", parseInt(e.target.value))} />
                                <Text size="small" weight={900}>stop</Text>
                                <Box direction="row">
                                    {modelSettings.stop?.map((s, i) => (
                                        <Box key={i} flex={false} direction="row" background="light-4" round="5px" pad="0px 8px" justify="center" align="center" gap="5px"><Text size="9pt">{displayWhitespace(s)}</Text>
                                            <Box onClick={() => modifyModelSettings("stop", modelSettings.stop.filter(t => t != modelSettings.stop[i]))}><Text size="12pt">x</Text></Box>
                                        </Box>
                                    ))}
                                    <TextInput className="editField" suggestions={["Hit enter to add the value"]} onKeyDown={e => {
                                        let theVal = (e.target as any).value;
                                        if (theVal && e.code == "Enter") {
                                            if (theVal.startsWith("\\")) {
                                                const mapping = { "\\n": "\n", "\\t": "\t", "\\g": "\g" }
                                                theVal = mapping[theVal] || theVal
                                            }
                                            let newVal = [...modelSettings.stop, theVal];
                                            modifyModelSettings("stop", newVal);
                                            (e.target as any).value = "";
                                        }
                                    }} />
                                </Box>
                            </AccordionPanel>
                            
                            <AccordionPanel label={<Box direction="row" alignContent="start">
                                <MdHistory size="16px"/>
                                <Text weight={900} margin="0px 4px"> Test runs history</Text> {history.length>1 && <Minibadge color="cyan"><Text color="dark-1">{ history.length}</Text></Minibadge>}
                            </Box>}>
                                <Box gap="5px" margin="5px">
                                {history.map((h,i)=>(
                                    <Box key={i} 
                                    border round align="center"
                                    onClick={()=>{
                                        _setFinalPrompt(h.sourcePrompt)
                                        _setPromptTemplate( h.promptTemplate)
                                        _setParams(h.params)
                                        setGenerated(h.generated)
                                        setGenerated_function_call(h.generated_function_call)
                                        setModelSettings({...h.llm_params, model:h.model} as any)
                                        set_functions(h.functions)
                                        

                                    }
                                    }
                                    >
                                        <Text>{i!==0&&i<history.length-1?<Moment fromNow>{h.timestamp?.toISOString && h.timestamp.toISOString()}</Moment>:i==0?"Original":"Last run"}</Text>
                                    </Box>
                                ))}
                                </Box>
                                </AccordionPanel>

                                { (
                                <AccordionPanel label={<Box direction="row" alignContent="start"><Text weight={900}>Template parameters</Text> <Minibadge color="cyan"><Text color="dark-1">{_params && Object.keys(_params).length}</Text></Minibadge></Box>}>
                                    <Box width="600px" gap="small" margin="20px 5px 5px 0px" flex={false} >

                                        {_params && Object.keys(_params).map((p,i) => (
                                            <Box key={i} flex={false}>
                                                {/* <Text size="small" weight={600}>{p}</Text> */}
                                                <Box direction="row" justify="between">
                                                    <Box flex="grow">
                                                <EditableTextBlock
                                                readonly={(typeof( _params[p])!=="string")}
                                                weight={900}
                                                 text={p} 
                                                onChange={newKey=>{ 
                                                    if (newKey===p) return;
                                                    let newParams={..._params};

                                                    if (newParams.hasOwnProperty(newKey)){
                                                        newKey=newKey+"_duplicate"
                                                    }
                                                        if (newKey){
                                                            newParams[newKey]=newParams[p];
                                                        }
                                                        delete newParams[p];
                                                    
                                                    _setParams(newParams);
                                                    
                                                }}
                                                initValue="newParam"
                                                />
                                                </Box>
                                                {(typeof( _params[p])==="string")&&(
                                                    <DeleteWithConfirmation onDelete={()=>{
                                                        let newParams={..._params};
                                                        delete newParams[p];
                                                        _setParams(newParams);
                                                    
                                                    }}/>
                                                )}
                                                </Box>
                                                {(typeof( _params[p])==="string")?(

                                                    <EditableBlock onFinishEditing={text=> modifyParam(p, text)}>
                                                    {_params[p]}
                                                    
                                                    </EditableBlock>    
                                                ):(
                                                    <Box margin="2px 10px" background="light-4" round="5px" pad="5px">
                                                        
                                                        <Text>Value of this parameter can't be edited here</Text>
                                                    </ Box>
                                                )}
                                               
                                                
                                            </Box>
                                        ))}
                                        <Button icon={<MdAdd/>} text="Add new" onClick={()=>{
                                            let newParams=_params?{..._params}:{};
                                            newParams["newParam"]="new value";
                                            _setParams(newParams)
                                        }}/>
                                    </Box>

                                </AccordionPanel>
                            )}
                        </Accordion>
                    </Box>
                </Box>


            </Box>
        </Box>
    )
}