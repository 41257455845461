


import { Box,   BoxProps,   Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useState } from "react"
import "./button.css"


export  default function Button({text, className, icon, onClick, primary, secondary, children, disabled, border,background, ...rest}:{
    text?:string
    className?:string
    primary?:boolean
    secondary?:boolean
    icon?:any
    children?:any
    disabled?:boolean
    onClick?:(e?:any)=>any
    border?:boolean
    background?:string
    [key:string]:any
}){
  
    
    const defaultBorder = (!primary && !secondary && (border===undefined && !background)) ? (true):border;

    return (
        <Box 
            focusIndicator={false}
            flex={false}
            className={(disabled?"disabled-button":("button" + (primary?" primary":(secondary?" secondary":"")))) 
            + (defaultBorder?" default-border":"")
            + (className?" "+className:"")
            }
            round="5px"
            direction="row"            
            align="center" justify="center"
            //border={!!(rest as any).background?undefined:true} 
            background={background}
            pad={(rest as any).pad?undefined:"3px 10px"} 
            gap={(rest as any).gap?undefined:"3px"} 
            {...rest}  onClick={(!onClick || disabled)?undefined:(e)=>onClick(e)}
            
        >
            {children||(
            <Box gap="5px" direction="row" align="center" justify="center">
                {icon} 
                {text && (<Text size={(rest as any).size || "small"}>{text}</Text>)}
            </Box>
            )}
           
           
        </Box>    
    )

    
}

