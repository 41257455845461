
import { Box, Button, Text } from "grommet";
import { memo, useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { PromptTemplateDefinition, Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import SessionDetail from "../components/SessionDetail/sessionDetail";
import PromptTemplatesList from "../components/PromptTemplatesList/promptTemplatesList";
import MaterDetailPageWrapper from "./materDetailPageWrapper";
import PromptRuns from "../components/PromptRuns/promptRuns";
import { LoadingCover } from "../components/LoadingCover/loadingCover";
import CodeBlock from "../components/CodeBlock/codeBlock";
import { MdInbox, MdInfo } from "react-icons/md";
import PromptTemplatesVersions from "../components/PromptTemplatesList/promptTemplatesVersions";
import HowToRegisterTemplate from "../components/CodeSnippets/howToRegisterTemplate";

const PromptTemplatesPage = ()=>{
    
    
    const [queryParams,setQueryParams]=useSearchParams()
    
    const [promptTemplate, setPromptTemplate]=useState<PromptTemplateDefinition>()

    const [loading, setLoading]=useState<boolean>()
    const [data,setData]=useState<PromptTemplateDefinition[]>()
    const [hasMoreData,setHasMoreData]=useState<boolean>()

    const pageSize = 50
    function search(searchQuery:string){
        
        setLoading(true)
        let template_name_to_select = queryParams.get("template_name")
        getApi().getPromptTemplates(searchQuery,pageSize+1).then(d=>{
            
            setHasMoreData((d.length>=pageSize))

            //Find and preselect the template from url query params
            if (template_name_to_select){   
                let found= d.find(t=>t.template_name===template_name_to_select)
                if (found){
                    setPromptTemplate(found)
                }
                else{
                    getApi().getPromptTemplates(searchQuery,pageSize).then(d=>{
                        found= d.find(t=>t.template_name===template_name_to_select)
                        if (found){
                            setPromptTemplate(found)
                        }
                    })
                }
            }
            setData(d)

        }).finally(()=>setLoading(false))
    }

    return (
        <Box fill >
       
       <MaterDetailPageWrapper pageHeading="Prompt templates"
       supportedModes={["minimized" , "normal"]}
       master={
           <PromptTemplatesList loading={loading} hasMore={hasMoreData} data={data} onSearch={search}  promptTemplateDefinition={promptTemplate} onPromptTemplateChange={setPromptTemplate} />
       }
       detailOpen={true}
       onDetailClose={()=>setPromptTemplate(null)}
        >
            <Box  height={"100vh"} overflow="auto" flex="grow" >
            
            {!!promptTemplate ? <PromptTemplatesVersions promptTemplateDefinition={promptTemplate}/>:(
                <Box fill background="dark-1" pad="50px" gap="20px" justify="center">
                    <Text weight={900} size="large" color="dark-4">
                    Registered prompt templates allows you to track all its changes, executions and run unit tests for each version...
                    </Text>
                    <Text weight={900} size="large" color="dark-4">
                    This is how you register a prompt template
                    </Text>
                <HowToRegisterTemplate/>
                </Box>
            )}
            </Box>

       </MaterDetailPageWrapper>


        
        </Box>
    )
}



export  default PromptTemplatesPage