

import { Box,   Drop,    Text} from "grommet"

import { useMemo, useRef, useState } from "react"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import { SiAzurefunctions } from "react-icons/si"
import FunctionCallArguments from "./functionCallArguments"



function formatFunc(argsSchema:{[arg:string]:any}){

    function resolveRef(schema: any): any {
        if (schema["$ref"] !== undefined) {
            const ref = schema["$ref"].split("/").pop();
            schema = argsSchema["parameters"]["definitions"][ref];
        }
        else if (Object.values(schema).find(v=>typeof(v)=="string" &&v.startsWith("#/definitions"))) {
            let ref = Object.values(schema).find(v=>typeof(v)=="string" &&v.startsWith("#/definitions")) as string
            ref = ref.split("/").pop();
        
            schema = argsSchema["parameters"]["definitions"][ref];
        }
        return schema;
    }
    
    function formatSchema(schema: any, indent: number): any {
        schema = resolveRef(schema);
        if ("enum" in schema) {
            return formatEnum(schema, indent);
        } else if (schema["type"] === "object") {
            return formatObject(schema, indent);
        } else if (schema["type"] === "integer") {
            return  <Text color="purple" weight={900}>number</Text>;
        } else if (["string", "number","boolean"].includes(schema["type"])) {
            return  <Text color="purple" weight={900}>{schema["type"]}</Text>;
        } else if (schema["type"] === "array") {
            return <>{formatSchema(schema["items"], indent) } <Text weight={900} color="purple">{"[]"}</Text></>;
        } else {
            throw new Error("unknown schema type " + schema["type"]);
        }
    }
    
    function formatEnum(schema: any, indent: number): string {
        return  schema["enum"].map((o: any) =><> <Text>|</Text><Text color="blue">{JSON.stringify(o)}</Text></>)
    }
    
    
function formatObject(schema, indent) {
    const elements = [];

    elements.push(<span>{"{\n"}</span>);
    
    if (!("properties" in schema) || Object.keys(schema.properties).length === 0) {
        if (schema.additionalProperties) {
            return <span>object</span>;
        }
        return null;
    }

    for (const [key, value] of Object.entries(schema.properties)) {
        const resolvedValue = resolveRef(value);
        const valueRendered = formatSchema(resolvedValue, indent + 1);

        if (valueRendered === null) {
            continue;
        }

        if ("description" in (value as any) && indent === 0) {
            const descriptionLines = (value as any).description.trim().split("\n");
            for (const line of descriptionLines) {
                elements.push(
                    <span key={key + line}>
                        {`${'  '.repeat(indent)}`}// <Text color="tomato" weight={900}>{line}</Text>{"\n"}
                    </span>
                );
            }
        }

        const optional = schema.required !== undefined && !schema.required.includes(key) ? <Text color="blue" weight={900}>?</Text> : "";
        const comment = (value as any).default === undefined ? "" : <>{" // default: "}<Text color="brand">{formatDefault(value)}</Text></>;

        elements.push(
            <span key={key}>
                <>{`${'  '.repeat(indent)}`} <Text color="darkblue" weight={900}>{key}</Text>{optional}: {valueRendered},{comment}{"\n"}</>
            </span>
        );
    }

    if (indent) {
        elements.push(
            <span key="closing-brace">
                <>{`${'  '.repeat(indent)}`}{`}`}</>
            </span>
        );
    }

    return <span>{elements}</span>;
}
    
    function formatDefault(schema: any): any {
        const v = schema["default"];
        if (schema["type"] === "number") {
            return <Text> {Number(v) % 1 === 0 ? `${Number(v).toFixed(1)}`: String(v)}</Text>
        } else {
            return <Text>{String(v)}</Text>;
        }
    }
    try{

        const parameters: any = argsSchema["parameters"];
        const formatted = formatObject(parameters, 0);
        let result = <>
        <span>// </span><Text color="tomato" weight={900}>{argsSchema["description"]}</Text>{"\n"}
        <span>type</span> <Text color="darkblue" weight={900}>{argsSchema["name"]}</Text> = {"("}
         {(formatted !== null)&& (
             <><span>_ : </span>{formatted}</>
         )}
        {") => any;\n\n"}
        </>
        return result;
    }
    catch{
        return "[Error during formatting]"
    }
    }
    


export  default function FormatFunctionsNative({
    functions}:{
    functions:{name:string, parameters:{properties:{[key:string]:any}}}[]
}){

    return (
        <Box flex={false}>
            <pre>
            <Text color="gray"># Tools<br/>## functions<br/>namespace functions {"{"}<br/></Text>

            {functions.map(f=>formatFunc(f))}
            <Text color="gray">{"}"} // namespace functions</Text>
            </pre>
        </Box>
    )
   
    
}

