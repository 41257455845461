// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxT5pKLKOMsyPvoaYD8Hp6u5_QDTGeTxk",
  authDomain: "promptwatch-a7ee6.firebaseapp.com",
  projectId: "promptwatch-a7ee6",
  storageBucket: "promptwatch-a7ee6.appspot.com",
  messagingSenderId: "204055669094",
  appId: "1:204055669094:web:0cc541116a2c61c58600a7",
  measurementId: "G-VQ6LCPKSYX"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);



export {firebaseApp, analytics}