


import { Box,   Layer,  Spinner, Text, Tip } from "grommet"
import { useEffect, useState } from "react"
import { MdClose, MdError, MdInfo } from "react-icons/md"
import { getApi } from "../../apiService"
import { ActivityBase, LlmPrompt, Session } from "../../models/dataModel"
import ActionComponent from "../Activities/Action"
import AnswerComponent from "../Activities/Answer"
import LlmPromptComponent from "./LlmPrompt"
import LogComponent from "../Activities/Log"
import ParallelThoughtsComponent from "../Activities/ParallelThoughts"
import QuestionComponent from "../Activities/Question"
import ActivityHeader from "./ActivityHeader"
import ChainSequenceComponent from "./ChainSequence"
import "./activityStyles.css"
import RetrievedDocs from "./RetrievedDocsComponent"
import Badge from "../Badge/badge"
import { BsStopwatch } from "react-icons/bs"
import Moment from "react-moment"
import FeedbackComponent from "../FeedbackComponent/feedbackComponent"
import Popup from "../Popup/popup"
import Button from "../Button/button"
import InfoToast from "../InfoToast.tsx/infoToast"
import HowToRegisterTemplate from "../CodeSnippets/howToRegisterTemplate"
import HowToDefineTrackingProject from "../CodeSnippets/howToDefineTrackingProject"

export  default function ActivityComponent({session, activity, allActivities, onChange, onClick, ...rest }:{
    session?:Session
    activity:ActivityBase 
    allActivities?:ActivityBase[] 
    onChange?:(activity:ActivityBase)=>any
    onClick?:()=>any
    [key:string]:any
}){
  
const [popup, setPopup]=useState<any>()
const setActivityFeedback=(val:number)=>{
    if (onChange){

        getApi().patchActivity(activity.id,{feedback_label:val}).then(()=>{
            activity.feedback_label=val
            onChange(activity)
        })
    }
}

const setRootChainFeedback=(val:number)=>{
    if (session.tracking_project){
        setActivityFeedback(val)
    }
    else{
        setPopup(<Popup onClose={()=>setPopup(null)} caption="Please setup tracking project">
        <Box pad="20px" gap="5px">
            <InfoToast type="info" text="The session was executed without tracking project context"/>
            <Box pad="5px"  gap="15px">

                <Text size="large" >Please  define a <b>project identifier in your codebase</b> first</Text>
                <Text size="large" >We need to be able to pair these sessions with the code base, so that we wouldn't mix up data from different projects</Text>
                <Text size="large" >It's simple as this:</Text>
                <HowToDefineTrackingProject/>
            </Box>

            <Button size="large" margin="0px 20px" primary alignSelf="end" text="OK" onClick={()=>setPopup(null)}/>
            
            
        </Box>
    </Popup>)
    return false
    }
}

const setLlmPromptFeedback=(val:number)=>{
    if (!(activity as LlmPrompt).prompt_template?.template_name ){
        setPopup(<Popup onClose={()=>setPopup(null)} caption="Please register your template first">
            <Box pad="20px">
                <InfoToast type="info" text="The template for this prompt was not registered"/>
                <Box pad="5px">

                    <Text size="large" weight={900}>Please register your the template for this prompt to enable result annotation and unit testing</Text>
                    <Text size="large" weight={900}>This is necessary, so we can track changes and match executions of this prompt </Text>
                    <Text size="large" weight={900}>It's simple as this:</Text>
                    <HowToRegisterTemplate/>
                </Box>

                <Button size="large" margin="0px 20px" primary alignSelf="end" text="OK" onClick={()=>setPopup(null)}/>
                
                
            </Box>
        </Popup>)
        return false
    }
    else{

        setActivityFeedback(val)
    }
}

 function getActivityComponent(activity:any){
    if (activity?.activity_type=="action"){
        return <ActionComponent activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="question"){
        return <QuestionComponent activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="answer"){
        return <AnswerComponent activity={activity} allActivities={allActivities} onChange={onChange} {...rest}/>
    }
    else if (activity?.activity_type=="log"){
        return <LogComponent activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="llm-prompt"){
        return <LlmPromptComponent showInputs activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="parallel-prompt"){
        return <ParallelThoughtsComponent activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="retrieved-docs"){
        return <RetrievedDocs activity={activity} allActivities={allActivities} {...rest}/>
    }
    else if (activity?.activity_type=="chain-sequence"){
        return <ChainSequenceComponent activity={activity} allActivities={allActivities} {...rest} onFeedback={setRootChainFeedback} childProps={{onChange:onChange}}/>
    }
    else{
        return (<Text>{activity?.activity_type}</Text>)
    }
 }

const componentsWithoutBackground=["chain-sequence","question","answer"]
const componentsWithoutTiming=["question","answer"]
const componentsWithFeedback=["chain-sequence","llm-prompt"]

    return <Box 
        margin="5px" 
        
        className={(activity).activity_type}
        background={!componentsWithoutBackground.includes(activity?.activity_type)?"white":undefined}
        flex={false} 
        //border={{color:"gray", style:"dashed",size:"0.5px"}} round="5px" 
        pad="5px 5px 5px 5px">
        {popup}
        <ActivityHeader activity={activity}>
        
        </ActivityHeader>
        
        

        {getActivityComponent(activity)}
        
        <Box  direction="row" justify="between" align="start">
        
        
            <Box direction="row" justify="between" alignContent="stretch" width="100%">
                {("llm-prompt" ===activity?.activity_type && !activity.error ) ? <FeedbackComponent value={activity.feedback_label} onClick={(val)=>{
                    setLlmPromptFeedback(val)
                   
                }}/>:<div/>}
                {!componentsWithoutTiming.includes(activity?.activity_type) && (activity.end_time&&activity.start_time) && (
                    <Badge tip={`${activity.start_time} - ${activity.end_time}`}>
                        
                            <Box direction="row" gap="xsmall" >

                            <BsStopwatch size="10px"/>
                        
                            <Text size="xsmall" color="gray">
                            {activity.end_time.substring(0,23)>activity.start_time.substring(0,23)?(
                                

                            <Moment duration={activity.start_time} date={activity.end_time}/>
                            ):(
                                "0 ms"
                            )}
                            </Text>
                            </Box>
                            
                        </Badge>
            )}
            </Box>
       
            </Box>
            {activity.error&&
            <Box  direction="row" gap="small" >
                <MdError color="red"/>

                <Text size="small" textAlign="start" color="red">{activity.error}</Text>
            </Box>
            }
            {activity.info_message&&
            <Box  direction="row" gap="small" >
                <Tip content={activity.info_message}>
                    <Box>

                        <MdInfo color="rgba(100,120,255)"/>
                    </Box>

                </Tip>
            </Box>
            }
    </Box>
    
}