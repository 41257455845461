import { Box, Text } from "grommet"
import CodeBlock from "../CodeBlock/codeBlock"
import { MdInfo } from "react-icons/md"

const HowToRegisterTemplate = () => {
    return (
        <Box flex={false} pad="20px">
            
            <CodeBlock highlightLines={[1,5]}>
{`from promptwatch import register_prompt_template
from langchain import OpenAI, LLMChain, PromptTemplate

prompt_template = PromptTemplate.from_template("Finish this sentence {input}")
prompt_template = register_prompt_template("name_of_your_template",prompt_template)
my_chain = LLMChain(llm=OpenAI(), prompt=prompt_template)

with PromptWatch() as pw:
    my_chain("The quick brown fox jumped over")
`}
            </CodeBlock>

            <Box direction="row" gap="5px" align="center" margin="5px 5px 0px">
            <MdInfo color="gray"/>
            <Text  size="large" color="gray">
            Template will be registered after first run of a chain with this template
            </Text>
            </Box>
        </Box>
    )
}

export default HowToRegisterTemplate