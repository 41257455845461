


import { Box, Button,  Collapsible,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, ChainSequence, Log, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill} from "react-icons/bs"
import { useState } from "react"
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md"
import "./keyValuesComponent.css"

export const KeyValuesComponent = ({object, size, multiline=true}:
    {
        object:{[key:string]:any}
        size?:string
        multiline?:boolean
    })=>{


    function getValueElement(value:any){
        if (value==null){
            return <Text textAlign="start"   size={size}>null</Text>
        }
        else if (typeof(value)==="string"){
            return <Text textAlign="start" alignSelf="start" size={size} style={{ whiteSpace: 'pre-line' }}  >{value.replace(/\n/g, "\u21B5\n")}</Text>
        }
        else if (typeof(value)==="number"){
            return <Text textAlign="start"   size={size}>{Math.round(value*1000*1000)/(1000*1000)}</Text>
        }
        else if (typeof(value)==="boolean"){
            return <Text textAlign="start"   size={size}> {value?<MdOutlineCheckBox/> :<MdOutlineCheckBoxOutlineBlank/>} {value?"true":"false"}</Text>
        }
        else if (typeof(value)!=="string" && (Array.isArray(value))){
            return (
                <Box align="start" flex={false}> 
                    {value.map((item,i)=> <Box key={i} margin="2px" round="5px"  pad="0px 5px" border justify="center">
                            {(item && typeof(item) ==="object") ?<KeyValuesComponent object={item} size={size}/>:(
                                getValueElement(item)
                            )}
                         </Box>)}
                
                </Box>
            )
        }
        else if (typeof(value)=="object"){
            return <KeyValuesComponent object={value} size={size}/>
        }
        else{
            return <Text textAlign="start"   size={size}>?</Text>
        }
        
    }

    return <Box align="start" >
            <table className="keyValuesComponent">
                <tbody>
        {object && Object.keys(object).filter(key=>
                                typeof(object[key])=="string"
                            ||  typeof(object[key])=="number"
                            ||  typeof(object[key])=="boolean"
                            ||  Array.isArray(object[key])
                            || !object[key] 
                ).map((key,i)=>(
                <tr key={i}>
                
                    <td>
                        
                    <Box margin="0px 0px 0px 0px"  align="start">

                        <Text wordBreak="keep-all" textAlign="start"  size={size} weight={900}>{key}: </Text>
                        </Box>
                        
                    </td>
                    <td>
                        
                        <Box align="start" className={"keyvalue-value-cell" + (multiline?"":" withFadeout")}>

                        {getValueElement(object[key])}
                        {!multiline && <div className="fadeout"></div>}
                        </Box>
                    </td>
                </tr>
        ))}
         {object && Object.keys(object).filter(key=>
                            !Array.isArray(object[key])
                            && typeof(object[key])=="object"
                            &&  object[key] 
                ).map((key,i)=>(
                <tr key={i}>
                    
                    <td colSpan={2}>
                    <Box   align="start">
                        <Text textAlign="start"  size={size} weight={900}>{key}: </Text>
                        </Box>
                        <Box margin="0px 0px 0px 50px"  align="start">

                            <KeyValuesComponent object={object[key]} size={size}/>
                        </Box>
                    </td>
                  
                </tr>
        ))}
        </tbody>
    </table>
    </Box>
}