
import { Box, Button, Tab, Tabs, Text, ThemeContext } from "grommet";
import { useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { PromptTemplateDefinition, PromptUnitTest, PromptUnitTestRun, Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import SessionDetail from "../components/SessionDetail/sessionDetail";
import PromptTemplatesList from "../components/PromptTemplatesList/promptTemplatesList";
import MasterDetailPageWrapper from "./materDetailPageWrapper";
import PromptRuns from "../components/PromptRuns/promptRuns";
import { LoadingCover } from "../components/LoadingCover/loadingCover";
import CodeBlock from "../components/CodeBlock/codeBlock";
import { MdInbox, MdInfo } from "react-icons/md";

import PromptUnitTestsList from "../components/UnitTests/promptUnitTestsList";
import PromptUnitTestDefinition from "../components/UnitTests/promptUnitTestDefinition";
import PromptUnitTestRunResults from "../components/UnitTests/promptUnitTestRunResults";
import { FaLightbulb } from "react-icons/fa";

export  default function PromptUnitTestsPage(){
    
    const [updatedRun, setUpdatedRun]=useState<PromptUnitTestRun>()
    
    
    const [selectedUnitTest, setSelectedUnitTest]=useState<string>()
    const [selectedUnitTestRun, setSelectedUnitTestRun]=useState<PromptUnitTestRun>()

    const [searchQuery, setSearchQuery] = useSearchParams()


    
    

    useEffect(()=>{
        if (searchQuery.get("unit-test-run") && !(selectedUnitTestRun && searchQuery.get("unit-test-run") === selectedUnitTestRun.run_id)){

            getApi().getUnitTestRun(searchQuery.get("unit-test-run")).then(d=>{
                setSelectedUnitTestRun(d)
            })
        }
        else if (searchQuery.get("test_name") && !(selectedUnitTest && searchQuery.get("test_name") === selectedUnitTest)){
            setSelectedUnitTest(searchQuery.get("test_name"))
        }

        if (!searchQuery.get("unit-test-run") && selectedUnitTestRun){
            setSelectedUnitTestRun(null)
        }
    }, [searchQuery])

    return (
        <Box fill >
       
       <MasterDetailPageWrapper pageHeading="Unit tests"
       supportedModes={["minimized" , "normal"]}
       master={
                    <PromptUnitTestsList
                    updatedUnitTestRun={updatedRun}
                    onSelectedChange={({run, test})=>{

                        setSelectedUnitTest(test?.test_name)
                        if (test){
                            searchQuery.set("test_name", test.test_name)
                            setSearchQuery(new URLSearchParams(searchQuery))
                        }

                        setSelectedUnitTestRun(run)
                        if (run){
                            searchQuery.set("unit-test-run", run.run_id)
                            setSearchQuery(new URLSearchParams(searchQuery))
                        }
                        else{
                            if (searchQuery.get("unit-test-run")){
                                searchQuery.delete("unit-test-run")
                                setSearchQuery(new URLSearchParams(searchQuery))
                            }
                        }
                    }} 
                    />      
       }
       detailOpen={!!(selectedUnitTestRun || selectedUnitTest)}
       onDetailClose={()=>setSelectedUnitTestRun(null)}
       detailPlaceholder={<HowToRunUniTest/>}
        >
           {selectedUnitTestRun?(
         <Box  height={"100vh"} overflow="auto" flex={false} >             
                <PromptUnitTestRunResults unitTestRun={selectedUnitTestRun} applyTestRunUpdate={(val)=>{
                    setUpdatedRun(val)
                    setSelectedUnitTestRun(val)
                    }}/>   
        </Box>
           ):(
            <Box  height={"100vh"} overflow="auto" flex={false} >
                <PromptUnitTestDefinition unitTestName={selectedUnitTest}/>
            </Box>
            )}

       </MasterDetailPageWrapper>


        
        </Box>
    )
}


const HowToRunUniTest = () => {
    return (
        <Box fill background="dark-1" pad="50px" gap="20px" justify="center">
            <Box direction="row">
                <FaLightbulb color="orange"/> 
                <Text weight={900} size="large" color="dark-4">
                Set up unit tests for your templates and chains to ensure that your recent changes did not break anything
                </Text>
                
            </Box>
            <Text weight={900} size="large" color="dark-4">
               Here is an example of how to run a unit test:
            </Text>
            <CodeBlock >
{`from promptwatch.unit_tests import UnitTest

llmChain=create_chain()
with UnitTest("name_your_test").for_project_sessions("name_of_your_project") as test:
    # we need to replace the memory (if we use one), to reset it after each test
    llmChain.memory = test.langchain.get_test_memory() 
    for test_case in test.test_cases():
        test_case.evaluate(llmChain)
`}
            </CodeBlock>

            <Box direction="row" gap="5px" align="center" margin="-15px 5px" justify="center">
            <MdInfo color="gray"/>
            <Text  size="medium" color="dark-4">
            There are more options available, check out the <a style={{color:"white"}} href="https://docs.promptwatch.io/docs/category/unit-testing" target="_blank">documentation</a> for more details
            </Text>
            </Box>
        </Box>
    )
}