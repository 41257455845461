


import { Box, Drop, Text, Tip } from "grommet"

import { useMemo, useRef, useState } from "react"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import { SiAzurefunctions } from "react-icons/si"
import FunctionCallArguments from "./functionCallArguments"
import FormatFunctionsNative from "./formatFunctionsNative"
import { TbEdit, TbMathFunction } from "react-icons/tb"
import Button from "../Button/button"
import Popup from "../Popup/popup"
import "./functionCalls.css"
import DeleteWithConfirmation from "../DeleteWithConfirmation/deleteWithConfirmation"
import DropDownButton from "../DropDownButon/dropDownButton"


export default function FunctionsBox({ functions, function_call,onFunctionEdit, onFunctionCallChange, onDelete, onAdd }: {
    functions: any[]
    function_call: string|{name:string}|undefined
    onFunctionEdit?:(functionData:any)=>any
    onFunctionCallChange?:(functionData:any)=>any
    onDelete?:(func:any)=>any
    onAdd?:()=>any
}) {

    const [popup, setPopup] = useState<any>()
    return (
        <Box pad="small" border round="3px" flex={{ shrink: 0 }}  background="white">
            {popup}
            <Box direction="row" justify="between" gap="medium" align="center">
                <Box direction="row" gap="small" background="rgba(250,210,240)" pad="2px" round="3px">
                    <TbMathFunction />
                    <Text weight={900}>Functions</Text>
                </Box>
                {!onFunctionCallChange ?(

                <Box round="4px" border pad="0px 5px" >
                    <Text>function_call: <b>{(function_call as any)?.name || function_call || "undefined"}</b></Text>
                </Box>
                ):(

                <DropDownButton
                    text={`function_call: ${(function_call as any)?.name || function_call || "undefined"}`}
                    style={{padding:"2px 4px", border:"1px solid rgba(0,0,0,0.5)"}}
                    
                    onOptionSelected={(key)=>{key==null && onFunctionCallChange(null)}}
                    options={Object.fromEntries(["auto","none", ...functions.map((func) => func.name)].map(funcName=>[funcName,{label:funcName,onClick:()=>!funcName || (funcName ==="auto" || funcName ==="none")  ? onFunctionCallChange(funcName):onFunctionCallChange({"name": funcName})}]))}
                    />
                    )}
                <Button secondary text="Show in OpenAI's native format" tip="the function definition the exact way how LLM sees it" onClick={() => setPopup(
                    <Popup caption="Functions part of the prompt" onClose={() => setPopup(null)}>

                        <Box pad="small" flex={false}>

                            <Box margin="5px 10px 5px 0px" align="start" >
                                <Text textAlign="start" size="xs" color="brand" alignSelf="end">Note: The format below is a generated estimate of the format that is been feed into GPT (as a part of system message). This estimate has been reverse engendered. Although it has not been officially confirmed by OpenAI, it seems that it quite is accurate </Text>
                            </Box>
                            <Box overflow="auto" flex="shrink" style={{maxWidth:"90vw", maxHeight:"70vh"}}>
                            <FormatFunctionsNative functions={functions} />

                            </Box>
                        </Box>
                    </Popup>)


                } />
            </Box>
            <Box pad="4px" gap="6px">
                {functions?.map((func, index) => (
                    <Box key={index} onClick={onFunctionEdit?()=>onFunctionEdit(func):undefined} pad="0px 4px" className={onFunctionEdit?"funcEdit":"funcInfo"} >
                        
                        <Box  direction="row"  justify="between" >
                                
                        <Box  direction="row" >
                            <Text weight={900} >{func.name}</Text>
                             
                            
                            <Text >(
                                {func.parameters?.properties && Object.keys(func.parameters.properties).map((param, index) => (
                                    <>
                                        <Tip content={<Box><KeyValuesComponent object={func.parameters?.properties[param]} /></Box>}>
                                            <Box style={{ display: "inline" }}>
                                                <Text key={index} color="accent-2" weight={600}>{param}</Text>
                                            </Box>
                                        </Tip>
                                        {index == Object.keys(func.parameters.properties).length - 1 ? "" : <Text>, </Text>}
                                    </>
                                ))}
                                )
                            </Text>
                            </Box>
                             <Box direction="row" align="center">
                            <TbEdit className="editIcon"/>
                            {onDelete && <DeleteWithConfirmation onDelete={()=>{
                                    onDelete(func)
                                }}/>}
                            </Box>
                        </Box>

                        <Text color="gray" truncate>{func.description}</Text>

                    </Box>
                )
                )}

            </Box>
            {onAdd && <Button secondary text="Add function" onClick={() => onAdd()} />}

        </Box>
    )


}

