


import { Box,   Collapsible,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, ChainSequence, Log, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill, BsInfo} from "react-icons/bs"
import { useState } from "react"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import ActionComponent from "./Action"
import ActivityComponent from "./ActivityComponent"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import Badge from "../Badge/badge"
import FeedbackComponent from "../FeedbackComponent/feedbackComponent"
import { ImLink } from "react-icons/im"
import Button from "../Button/button"
import Popup from "../Popup/popup"


const  InputOutputHeader=({caption, data, onClick}:any)=>{
    return (
        <Box justify="start" direction="row" pad="3px" align="start" wrap onClick={onClick?()=>onClick():undefined} flex={false}>

                            <Box pad="2px 5px 0px 0px"><Text weight={900} color="brand" size="large">{caption}</Text></Box>
                            {data &&  Object.keys(data).map(k=>(
                                <Badge background={!!data[k]?"light-2":"white"} text={k} tip={data[k]?.toString()}/>
                            ))}
                    </Box>
    )

}

const  ChainHeader=({activity, onFeedback}:any)=>{
    const [popup,setPopup] = useState<any>();
    return (
        <Box direction="row" gap="5px" pad="2px 5px"  flex="grow" justify="between" >
            {popup}
            <Box direction="row" gap="5px" >

            <ImLink/>
            <Text size="small" color="brand" weight={700}>{activity.sequence_type}</Text>
            </Box>
            <Box direction="row">
                {!activity.parent_activity_id && (
                    <FeedbackComponent value={activity.feedback_label} alignSelf="end" onClick={onFeedback}/>
                )}
                 <Button 
                        border={false}
                        
                        icon={<BsInfo size="20px"/>} size="10px" pad="0px"
                        onClick={()=>{
                            setPopup(<Popup caption="Additional info" onClose={()=>setPopup(null)}>
                                <Box pad="small">
                                    
                                <KeyValuesComponent object={activity} size="small"/>
                                </Box>
                            </Popup>)
                        }}
                    />
            </Box>
        </Box>
    )

}

export  default function ChainSequenceComponent({activity, allActivities, childProps, onFeedback, onClick}:{
    activity:ChainSequence
    allActivities?:ActivityBase[]
    childProps?:any
    onFeedback?:(value:null|number)=>any
    onClick?:()=>any
}){
  
    
    const [open, _setOpen]=useState(false)
    const setOpen = (val)=>{
        if (!val){
            setOpenInputs(val)
            setOpenOutputs(val)
        }
        _setOpen(val)

    }
    const [openInputs, setOpenInputs]=useState(false)
    const [openOutputs, setOpenOutputs]=useState(false)
    const childActivities = allActivities?.filter(a=>a.parent_activity_id==activity.id)
    if (childActivities?.length!=1){

    
        return (
            <Box flex="grow"  className="chain-sequence-inside" direction="row" round="5px"  justify="stretch" align="start" onClick={onClick ?()=> onClick():undefined} focusIndicator={false}>
            { 
                <>
                
                <Box justify="between" flex={false} alignSelf="stretch" >

                {<Box  direction="row"  flex={false} justify="stretch" align="start" focusIndicator={false} onClick={()=>setOpen(!open)} pad="0px 0px">
                {!open?<MdKeyboardArrowDown size="25px"/>: <MdKeyboardArrowUp size="25px"/>}
                </Box>}
                {open && <Box  direction="row"  flex={false} justify="stretch" align="start" focusIndicator={false} onClick={()=>setOpen(!open)} pad="0px 0px">
                {!open?<MdKeyboardArrowDown size="25px"/>: <MdKeyboardArrowUp size="25px"/>}
                </Box>}
                </Box>

                <Box gap="5px" width="100%">
                    <Box direction="row"width="100%" align="center" justify="between">

                    <ChainHeader activity={activity} onFeedback={onFeedback}/>
                    
                    </Box>
                    <Box  className={"sequence-info input"+((open || openInputs)?" open":" collapsed fadeout-parent ")} onClick={()=>setOpenInputs(!openInputs)} flex={false}>
                        <InputOutputHeader caption="Inputs" object={activity.inputs} />
                      
                        <KeyValuesComponent object={activity.inputs} size="small"/>
                        {!(open || openInputs) && <div className="fadeout"></div>}
                    </Box>
                    {!(open || openInputs) && !!childActivities?.length &&(
                        
                        <Badge alignSelf="center" onClick={()=>setOpen(true)}>
                            <Box direction="row" align="center" gap="5px">
                                <MdKeyboardArrowDown size="25px"/>
                                Show all <Text weight={900} color="brand">{childActivities?.length}</Text> nested steps
                            </Box>
                        </Badge>
                        
                    )}
                    <Collapsible open={open}>
                        <Box direction="row">
                            {/* <Box direction="row" flex={false} alignSelf="start" focusIndicator={false}  onClick={()=>setOpen(false)}><MdKeyboardArrowUp size="25px"/></Box> */}
                            <Box  align="stretch" pad="5px" width="100%">

                                {childActivities?.map((a,i)=>(
                                    <ActivityComponent key={i} activity={a} allActivities={allActivities} {...childProps}/>
                                ))}
                                
                            </Box>
                        </Box>  
                    </Collapsible>
                    <Box  className={"sequence-info output"+((open || openOutputs)?" open":" collapsed fadeout-parent ")}  onClick={()=>setOpenOutputs(!openOutputs)} flex={false}>
                    <InputOutputHeader caption="Outputs" object={activity.outputs}/>
                    <KeyValuesComponent object={activity.outputs} size="small"/>
                    {!(open || openOutputs) && <div className="fadeout"></div>}
                    </Box>
                </Box>
               
                </>
                }
            
            
            </Box>    
        )
    }
    else {
        // Skip the chain boundary if only one item inside
        return (
            <Box flex="grow" className="chain-sequence" round="5px" border  onClick={onClick ?()=> onClick():undefined} focusIndicator={false}>
                <ChainHeader activity={activity} onFeedback={onFeedback}/>
                
                <ActivityComponent activity={childActivities[0]} allActivities={allActivities}  {...childProps}/>   
            </Box>
        )
    }
    

    
}

