import { Box } from "grommet"
import { useState } from "react"
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs"
import { GiStarsStack } from "react-icons/gi"
import { TbStars } from "react-icons/tb"

const Rating = ({size=10, value, onChange}:{
    value:number,
    size?:number,
    onChange?:(value:number)=>void
}) => {
const show_stars=[0,0,0,0,0]

const [hoverStar,setHoverStar]=useState<number>()
const [hover, setHover]=useState<boolean>()
    return (
        
        <Box direction="row" gap="1px" onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} flex={false}>
            {(value ===undefined && !hover )&&(
                <TbStars color="gray" size="18px"/>
            )}
            {(value !==undefined  || hover) && show_stars.map((s,i)=>(
                <Box key={i} onClick={onChange?()=>onChange(i+1): undefined} onMouseEnter={()=>setHoverStar(i+1)} onMouseLeave={()=>setHoverStar(undefined)} focusIndicator={false}>
                    {Math.round((hoverStar || value)*10)/10>=(i+1)?<BsStarFill color="gray" size={size+"px"}/>:(
                        Math.round((hoverStar || value)*10)/10>=(i+0.5)?<BsStarHalf color="gray" size={size+"px"} />:
                            <BsStar  size={size+"px"} color="gray"/>
                            )}
                </Box>
            ))}
        </Box>
        
    )

}

export default Rating