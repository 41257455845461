import { Box, Text, TextInput } from "grommet"
import { ChatMessage, ChatMessageTemplate } from "../../models/dataModel"
import { BiUser } from "react-icons/bi"
import { SiProbot } from "react-icons/si"
import { VscSettingsGear } from "react-icons/vsc"
import "./chatMessageBox.css"
import EditableBlock from "../EditableBlock/editableBlock"
import { useCallback, useMemo, useState } from "react"
import DropDownButton from "../DropDownButon/dropDownButton"
import { IoMdArrowDown, IoMdArrowUp, IoMdClose } from "react-icons/io"
import DeleteWithConfirmation from "../DeleteWithConfirmation/deleteWithConfirmation"
import Button from "../Button/button"
import { TbMathFunction } from "react-icons/tb"

const ROLES=["system","assistant","user","function"]

export const ChatMessageBox=({chatMessage, chatMessageTemplate, isGenerated, editable, formatFunction, onChanged, onDelete, onMove }:{
    chatMessage?:ChatMessage,
    chatMessageTemplate?:ChatMessageTemplate|ChatMessage,
    isGenerated?:boolean, 
    editable?:boolean
    formatFunction?:(text:string, template?:string)=>any,
    onChanged?:(text,role, html?,name?)=>any
    onDelete?:()=>any
    onMove?:(direction:"up"|"down")=>any
})=>{
    
    const [isEditing, setIsEditing] = useState(false)
    
    const role = (chatMessage||chatMessageTemplate)?.role
    // const text = useMemo(()=>{
    //     if ((chatMessage as ChatMessage).text){
    //         return (chatMessage as ChatMessage).text
    //     }
    //     else{

    //         return (chatMessage as ChatMessageTemplate).prompt_template
    //     }
    // },[chatMessage])

    
    const content=useMemo(()=>{
        
        const text = (chatMessageTemplate as ChatMessageTemplate)?.prompt_template || (chatMessage as ChatMessage)?.text || (chatMessageTemplate as ChatMessage)?.text
        
        if (formatFunction /*&& !isEditing*/){
            try{

                let res =  formatFunction((chatMessage as ChatMessage)?.text||(chatMessageTemplate as ChatMessage)?.text, (chatMessageTemplate as ChatMessageTemplate)?.prompt_template )
                return res
            }
            catch(error){
                console.log(error)
                return [text]
            }
        }
        return text ? [<span>{text}</span>] :undefined
    },[formatFunction,isEditing, chatMessage, chatMessageTemplate])


    const funcCallArgs = useMemo(()=>{
        if (chatMessage?.metadata?.function_call?.arguments){
            try{

                return JSON.stringify(JSON.parse(chatMessage.metadata.function_call.arguments), null, 2)
            }
            catch{
                return chatMessage.metadata.function_call.arguments
            }
        }
        return undefined
    },[chatMessage])
    return ( 
    
    <Box className={"chat-message role-"+role}  style={(chatMessage && !((chatMessage as any)?.text || chatMessage.metadata) )?{opacity:0.5}:undefined}>
        <Box direction="row" align="center">
        <Box flex="grow" direction="row">
            <Box alignSelf={role=="user"?"end":"start"}  className={"chat-message-role-info role-"+role} direction="row" gap="3px">
                {editable?(

                    <DropDownButton
                    icon={role=="user"?<BiUser/>:role=="assistant"?<SiProbot/>:<VscSettingsGear/>}
                    text={role}
                    options={ROLES.reduce((options, role)=>{
                        options[role]={
                            label:role,
                            onClick:()=>onChanged(undefined,role)
                        }
                        return options
                    },{})}
                    />
                    ):(
                        <>
                        {role=="user" && <BiUser/>}
                        {role=="assistant" && <SiProbot/>}
                        {role=="system" && <VscSettingsGear/>}
                        {role=="function" && <TbMathFunction/>}
                        <Text>{role}</Text>
                        </>
                    )}
            </Box>
        {onMove&&(
            <Box direction="row" gap="2px">
            <Button pad="4px" border={false} icon={<IoMdArrowUp/>} onClick={()=>onMove("up")}/>
            <Button  pad="4px" border={false} icon={<IoMdArrowDown/>} onClick={()=>onMove("down")}/>
            </Box>
        )}
        </Box>
        {onDelete&&<DeleteWithConfirmation onDelete={onDelete}/>}
        </Box>
        {role=="function"&&(
            <Box  margin="0px 10px" gap="10px">
            <Box direction="row">
                <Box width="130px" justify="center">
                <Text weight={900} wordBreak="keep-all" >Function name</Text>
                </Box>
                {onChanged ? <TextInput size="small" value={chatMessage?.metadata?.function_call?.name} onChange={(e)=>onChanged(undefined, undefined,undefined, e.target.value)}  />:<Box round="xsmall" border pad="2px 5px"><Text weight={900} >{chatMessage?.metadata?.function_call?.name}</Text></Box>}
            </Box>
            <Text wordBreak="keep-all"  weight={900}>Output</Text>
            </Box>
        )}
        
        {editable?(

        content &&<EditableBlock
                onBeginEditing={()=>setIsEditing(true)} onFinishEditing={(text, html )=> {
                    setIsEditing(false)
                    onChanged(text, role, html)
                    }}
            >
                    {content}
                </EditableBlock>
        ):(
        <Text className={"content" +( isGenerated?" generated-msg":"")}>{isGenerated?<mark>{(chatMessage as any).text ||(chatMessage as any).prompt_template }</mark> : (
            (chatMessage as any).text ||(chatMessage as any).prompt_template 
        ) }</Text>
        )}
        {chatMessage?.metadata && !funcCallArgs && (
            
            <pre >
                <Text>Metadata</Text>
                <code style={{fontSize:"9px"}}>
                    {JSON.stringify(chatMessage.metadata, null, 2)}
                </code>
            </pre>
            
        )}
        {funcCallArgs &&  (
            <Box>
            <Text size="large">Function name: "<b>{chatMessage?.metadata?.function_call?.name}</b>"</Text>
            <pre>
                <code>
                    {funcCallArgs}
                </code>
            </pre>
            </Box>
        )}
    </Box>)
    
}