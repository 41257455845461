


import { Box, Button,  Layer,  Tip, Text } from "grommet"
import { ChatMessage, ChatMessageTemplate } from "../../models/dataModel"
import { ChatMessageBox } from "../ChatMessageBox/chatMessageBox"



export  default function ChatPromptTemplate({items}:{
    items:(ChatMessage|string|ChatMessageTemplate)[]
}){
  

    
    return (
        <Box flex={false}>
            {
                items.map((item,i)=>{
                    if (typeof item=="string"){
                        return (
                            <Box key={i}  gap="xsmall"  pad="5px" background="light-2" border margin="2px 10px" round="5px" >
                                <Text textAlign="center" size="large">{`{${item}}`}</Text>
                            </Box>
                        )
                    }
                    else if ((item as ChatMessageTemplate).prompt_template){
                        return (
                                                 
                            <ChatMessageBox  key={i} chatMessage={{role:item.role, text:((item as ChatMessageTemplate).prompt_template)}} />
                            
                        )
                    }
                    else if ((item as ChatMessage).text){
                        return (
                            <ChatMessageBox  key={i} chatMessage={(item as ChatMessage)} />
                        )
                    }
                    else{
                        return ("unsupported item type")
                    }
                }
                )
            }
        </Box>
        )
    
}