


import { use } from "echarts"
import { Box, Button,  Layer,  Tip, Text } from "grommet"
import { useMemo, useState } from "react"





export  default function Badge({children, text,icon, tip,textProps, onClick, ...rest}:{
    children?:any
    text?:any
    textProps?:any
    icon?:any
    tip?:any
    onClick?:()=>any
    [key:string]:any
}){
  
    const tipMemo = useMemo(()=>{
        if (typeof(tip)==="function") 
            return <Box>{tip()}</Box>
        else if (typeof(tip)=="string")
            return tip
        else 
            return tip
        
        },[tip])
    
    const res = (
        <Box direction="row" align="center"  onClick={onClick} focusIndicator={false} 
        flex={false}
        border round background="light-1" pad="2px 5px" margin="2px"
        {...rest}>
            {children||(
                <Box direction="row" align="center" gap={(icon&&text)?"2px":undefined} pad="0px">{icon} <Text {...textProps}>{text}</Text></Box>
            )}
        </Box>
    )

    if (tip){
        return (<Tip plain  content={
            <Box background="white" pad="5px" elevation="medium" round="5px" margin="5px" border>

                {tipMemo}
            </Box>
        }>
            {res}
        </Tip>)
    }
    else{
        return res
    }
    
}