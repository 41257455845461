
import { Box,  Text } from "grommet";
import { useEffect, useMemo, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import  SessionsFilter  from "../components/StatisticsWindow/sessionsFilter";
import ShrinkingHeader from "../components/ShrinkingHeader/shrinkingHeader";
import ShrinkingHeaderContainer from "../components/ShrinkingHeader/shrinkingHeader";
import Sessions from "../components/Sessions/sessions";
import {AiOutlineDoubleLeft,AiOutlineDoubleRight} from "react-icons/ai"
import SessionDetail from "../components/SessionDetail/sessionDetail";
import { SessionsIcon } from "../components/CommonIcons/commonIcons";
import { FaExpandAlt } from "react-icons/fa";
import Button from "../components/Button/button";
import MaterDetailPageWrapper from "./materDetailPageWrapper";


export  default function SessionsPage(){
    
  const [queryParams, setQueryParams] =useSearchParams()

  function openSession(session_id){
    if (!session_id){
      queryParams.delete("session_id")
    }else{

      queryParams.set("session_id",session_id)
    }
    setQueryParams(new URLSearchParams(queryParams))

  }
  const session_id = useMemo(()=>{

    return queryParams && queryParams.get("session_id")

  },[queryParams])

  return   (<Box fill >
       
  <MaterDetailPageWrapper pageHeading="Sessions"
  master={
    <Sessions
    darkMode
    onSessionClick={openSession} />
  }
  detailOpen={!!session_id}
  onDetailClose={()=>openSession(null)}
   >

        <Box  height={"100vh"} overflow="auto" flex="grow" >
              <SessionDetail sessionId={session_id} />
              </Box>
  </MaterDetailPageWrapper>


   
   </Box>)
}

// function SessionsPage_bckp(){
    
//   const [queryParams, setQueryParams] =useSearchParams()

//   function openSession(session_id){
//     queryParams.set("session_id",session_id)
//     setQueryParams(new URLSearchParams(queryParams))

//   }

//   const session_id = useMemo(()=>{

//     return queryParams && queryParams.get("session_id")

//   },[queryParams])

//   const [sessionSidebarSize,setSessionSidebarSize]=useState<"minimized"|"normal"|"fill">()
//   useEffect(()=>{
//     if (session_id){
//         setSessionSidebarSize("normal")

//     }
//     else{
//       setSessionSidebarSize("fill")
//     }
//   },[session_id])
//     return (
        
//             <>

//         <Box direction="row" justify="between" >
          
//         {sessionSidebarSize=="minimized"?(
//             <Box flex={false} pad="5px 0px" align="center" gap="10px" className="second-level-sidebar" background="dark-2">
//                 <Button
//                 border={false}
//                 icon={<AiOutlineDoubleRight size="15px"/>}
//                 onClick={()=>setSessionSidebarSize("normal")}
//                 />
                
//             </Box>
//         ):(
            

//             <Box
//               background="dark-1"
//               fill={sessionSidebarSize == "fill"}


//               style={sessionSidebarSize == "normal" ? { width: "600px", minWidth: "400px" } : undefined} >

//               <Sessions
//                 darkMode
//                 header={
//                   <Box direction="row" justify="between" align="center" pad="5px 0px 5px 5px" flex={false}>
//                     <Box direction="row" pad="5px">

//                       <Text size="xlarge" weight={900} color="accent-1"> Sessions</Text>
//                     </Box>
//                     {sessionSidebarSize != "fill" && <Box direction="row" >
//                       {session_id && <Button
//                         border={false}
//                         alignSelf="end"
//                         pad="2px"
//                         icon={<AiOutlineDoubleLeft size="15px" />}
//                         onClick={() => setSessionSidebarSize("minimized")}
//                       />}
//                       {session_id && <Button
//                         border={false}
//                         alignSelf="end"
//                         icon={<FaExpandAlt size="15px" />}
//                         onClick={() => {
//                           setSessionSidebarSize("fill")
//                           queryParams.delete("session_id")

//                           setQueryParams(new URLSearchParams(queryParams))
//                         }}
//                       />}
//                     </Box>}
//                   </Box>

//                 } onSessionClick={openSession} />
//             </Box>
//           )}
//           {session_id && sessionSidebarSize != "fill" &&
//             <Box height="100vh" width="100%" >
//               <SessionDetail sessionId={session_id} />
//             </Box>
//           }
//         </Box>

//             </>
//     )
// }