


import { use } from "echarts"
import { Box, Button,  Layer,  Tip, Text } from "grommet"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import { PromptUnitTestRun } from "../../models/dataModel"
import PromptTestRunCard from "./promptTestRunCard"
import { UnitTestIcon } from "../CommonIcons/commonIcons"
import Badge from "../Badge/badge"
import { getApi } from "../../apiService"
import { useNavigate } from "react-router-dom"
import { useInView } from 'react-intersection-observer';


export const  UnitTestBadgeForTemplate = ({template_name,template_version_number,  ...rest}:{
    template_name:string,
    template_version_number:string,
    [key:string]:any
})=>{
    const [lastRun, setLastRun] = useState<PromptUnitTestRun>()
    const timeoutRef = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>()
    // useEffect(()=>{
    //     if (!lastRun){

    //         getApi().getUnitTestForTemplate(template_name,template_version_number).then((res)=>{
    //             setLastRun(res)
    //         })
    //     }
    // },[template_name,template_version_number])

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
      });
    
    useEffect(() => {
        // Cleanup function to cancel the timeout on re-renders
        return () => {
          clearTimeout(timeoutRef.current);
        };
      }, []);
    
      useEffect(() => {
        
        // Delayed API call after 1 second
        if (!lastRun||lastRun.conditions.for_template_name!=template_name) {
            setIsLoading(true)
            if (inView) {
                timeoutRef.current = setTimeout(() => {
                        getApi()
                        .getUnitTestForTemplate(template_name, template_version_number)
                        .then((res) => {
                            setIsLoading(false)
                            setLastRun(res||null);
                        });
                    }, 1000);
            }
            
        }
    
        // Cleanup function to cancel the timeout if dependencies change before the delay
        return () => {
          clearTimeout(timeoutRef.current);
        };
      }, [template_name, template_version_number,inView]);

      return <div ref={ref} >
        {!isLoading && lastRun!==undefined?( 
            <UnitTestBadge unitTestRun={lastRun} {...rest} />
        ):( <></>)}
      </div>
    

}


export const UnitTestBadge =({unitTestRun,  ...rest}:{
    unitTestRun: PromptUnitTestRun|null
    [key:string]:any
    
    
})=>{
  
    const tipMemo = useMemo(()=>{
        if (!unitTestRun) return <Text>This template has not been tested yet</Text>
        return <Box style={{maxWidth:"400px"}}>
            <Box pad="10px" justify="center" direction="row">
                <UnitTestIcon/>
                <Text weight={900}> {unitTestRun.test_name}</Text>
                
            </Box>
            <Text weight={900}>Last run:</Text>
                <PromptTestRunCard promptTestRun={unitTestRun}   />
             </Box>
        
        },[])

    const navigate=useNavigate()
    const onClick = (e:any)=>{
        navigate(`/unit-tests?unit-test-run=${unitTestRun?.run_id}`)
        e.stopPropagation()

    }
    function getBadgeColor(){
        if (!unitTestRun){
            return "status-unknown"
        }
        else if (!unitTestRun.results?.total_processed) return "status-unknown"
        else if (unitTestRun.results?.passed==unitTestRun.results?.total_processed) return "status-ok"
        else if (unitTestRun.results?.passed==0) return "status-critical"
        else return "status-warning"
    }
    
    const res = (
        <Box direction="row" align="center"  onClick={onClick} focusIndicator={false} 
        flex={false}
        border round background="light-1" pad="1px 1px 1px 5px" margin="2px"
        {...rest}>
            
            <UnitTestIcon/>
            {unitTestRun? (

                
                <>
                <Text size="small" margin="0px 5px">{unitTestRun.test_name}</Text>
                <Badge background={getBadgeColor()} border={false} > 
                <Text size="small" margin="0px 5px">{unitTestRun.results?.passed}/{unitTestRun.results?.total_processed}</Text>
                </Badge>
                </>
                )
            :(
                <Text color="dark-4" size="small" margin="0px 5px">no tests runs yet</Text>
            )
            }
            
        </Box>
    )

  
        return (<Tip plain  content={
            <Box background="white" pad="5px" elevation="medium" round="5px" margin="5px" border>

                {tipMemo}
            </Box>
        }>
            {res}
        </Tip>)
   
    
}