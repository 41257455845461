

import { Box, DateInput, DropButton, MaskedInput, Spinner, Text, TextInput, Tip } from "grommet"
import { Edit, StatusGood, Validate } from "grommet-icons";
import { useEffect, useRef, useState } from "react"
import { BiCalendar } from "react-icons/bi"
import { BsCalendar3 } from "react-icons/bs";
import { MdCalendarViewMonth, MdDone } from "react-icons/md";
import Button from "../Button/button";

function addSeconds(date: Date, seconds: number) {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}

const getRelativeDate = (month?: number | undefined, days?: number | undefined, hours?:number|undefined) => {
    let now = new Date();
    if (!hours)
        now.setHours(0, 0, 0, 0)

    let result = now;
    if (month !== undefined) {
        result = new Date(now.getFullYear(), now.getMonth() + month, days ? now.getDay() : 1);
    }
    if (days) {
        result = new Date()
        result.setDate(now.getDate() + days)
    }
    if (!hours)
        result.setHours(0, 0, 0, 0)
    else{
        result= new Date(result.getTime() + (hours * 60 * 60 * 1000))
    }

    return result
}

interface DateRangeParams {
    onRangeChanged: (range: { since: Date, until: Date }) => any
    range?: { since: Date, until: Date }
    options?: undefined | { [label: string]: () => { since: Date, until: Date } }
}

const DateRangePicker = ({ range, onRangeChanged, options = undefined }: DateRangeParams
) => {



    const now = new Date();
    now.setHours(now.getHours()+1, 0, 0, 0)
    const today = new Date();
    today.setHours(0, 0, 0, 0)

    const _options = options || {
        "Last 24 hours": () => ({ since: new Date(new Date().getTime()-(60*60*24*1000)), until: null }),
        "Last 3 days": () => ({ since: getRelativeDate(undefined, -3), until: now }),
        "Last 7 days": () => ({ since: getRelativeDate(undefined, -7), until: now }),
        "Last 30 days": () => ({ since: getRelativeDate(undefined, -30), until: (now) }),
        "Last 90 days": () => ({ since: getRelativeDate(undefined, -90), until: (now) }),
        "This month": () => ({ since: getRelativeDate(-0), until: now}),
        "Previous month": () => ({ since: getRelativeDate(-1), until: addSeconds(getRelativeDate(0), -1) }),
        "Custom range": "custom"
    }
    const [currentOption, setCurrentOption] = useState<string | undefined>()
    const [currentRange, _setCurrentRange] = useState<{ since: Date, until: Date }>()

    function equalDateRanges(range1, range2, tolerance=0) {
        // Check if both ranges are null
        if (range1 == null && range2 == null) {
          return true;
        }
        
        // Check if either range is null
        if (range1 == null || range2 == null) {
          return false;
        }
        
        // Check if the since and until properties are equal
        const sinceEqual = range1 && range2&&(range1.since == null && range2.since == null) || (range1.since != null && range2.since != null && Math.abs(range1.since.getTime() - range2.since.getTime())<tolerance);
        const untilEqual = range1 && range2&& (range1.until == null && range2.until == null) || (range1.until != null && range2.until != null && Math.abs(range1.until.getTime() - range2.until.getTime())<tolerance);
        
        return sinceEqual && untilEqual;
      }

    const setCurrentRange = (range: { since: Date, until: Date }) => {
        if (!(currentRange && range && equalDateRanges(currentRange,range))) {
            let matchedOption = null
            if (range && _options) {
                matchedOption = Object.keys(_options).find(k => {
                    if (typeof (_options[k]) == "function") {
                        let optionsRange = _options[k]()
                        return equalDateRanges(optionsRange, range, 15*60*1000)
                    }
                })
            }
            if (matchedOption) {
                setCurrentOption(matchedOption)
                setCustomMode(false)
                setCustomModeTempRange(null)

            }
            else {
                setCustomModeTempRange({ since:range?.since?.toISOString(), until:range?.until?.toISOString() })
                setCustomMode(true)
                setCurrentOption("Custom range")
            }
            _setCurrentRange(range)
            if (currentRange){

                onRangeChanged(range)
            }
        }
    }
    useEffect(() => {
        setCurrentOption(Object.keys(_options)[0])
        //setCurrentRange((_options as any)[Object.keys(_options)[0]]())
    }, [])

    const [dropOpen, setDropOpen] = useState(false)
    const [customMode, setCustomMode] = useState(false)
    const [customModeTempRange, setCustomModeTempRange] = useState<{ since: string, until: string }>()

   
    // useEffect(() => {
    //     if (range) {
    //         setCurrentRange(range)
    //         setCustomModeTempRange(null)
    //     }
       
    // }, [range])

    function startCustomMode(){
        setCustomMode(true)
        setCustomModeTempRange({ since:currentRange.since?.toISOString(), until:currentRange.until?.toISOString() })
    }

    function endCustomMode(){
        
        try {
            const parseDate=(dateStr)=>{
                const dateString = dateStr.trim();
                const [day, month, year] = dateString.split('/').map(Number);

                // Months are 0-indexed in JavaScript, so we subtract 1 from the month value
                return new Date(year, month - 1, day);
            }
            
            setCurrentRange({ since: (customModeTempRange.since?new Date(customModeTempRange.since):null), until: customModeTempRange.until?(new Date(customModeTempRange.until)):null })
            setCustomModeTempRange(null)
            setCustomMode(false)
        } catch { }
    }
    
    const formatDate=(date:Date|string)=>{
        let theDate;
        if (!date) return ""
        if (typeof(date)=="string"){
            theDate=new Date(date)
        }
        else {
            theDate = date as Date
        }

        const year = theDate.getFullYear();
        const month = String(theDate.getMonth() + 1).padStart(2, '0');
        const day = String(theDate.getDate()).padStart(2, '0');

        const hour = String(theDate.getHours()).padStart(2, '0');
        const minutes = String(theDate.getMinutes()).padStart(2, '0');
        const seconds = String(theDate.getSeconds()).padStart(2, '0');
        if (year&&month&&seconds)
            return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
        else {
            return ((date as string)||"")
        }
    }
    const dateInputRef = useRef()

    const maskFormat= [
        {
            length: [4],
            options: ([(new Date().getFullYear())-1, (new Date().getFullYear())]).map((a,i)=>(a).toString()),
            regexp:  /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
            //regexp:  /^[0-9][0-9]?[0-9]?[0-9]?$/,
            placeholder: 'YYYY',
          },
       
          { fixed: '-' },
          {
            length: [1, 2],
            options: (new Array(12)).map((a,i)=>(i).toString()),
            regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
            placeholder: 'MM',
          },
          { fixed: '-' },
          {
            length: [1, 2],
            options: (new Array(31)).map((a,i)=>(i).toString()),
            regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
            placeholder: 'DD',
          },
          
        { fixed: 'T' },
        {
          length: [1, 2],
          //options: (new Array(24)).map((a,i)=>(i).toString()),
          regexp: /^[0-9][0-9]?$/,
          placeholder: 'hh',
        },
        { fixed: ':' },
        {
          length: 2,
          options: ['00', '15', '30', '45'],
          regexp: /^[0-9][0-9]?$/,
          placeholder: 'mm',
        },
        { fixed: ':' },
        {
          length: 2,
          options: ['00', '15', '30', '45'],
          regexp: /^[0-9][0-9]?$/,
          placeholder: 'ss',
        },
       
    ]
    return (
        <Box direction="row-reverse" align="center" gap="small" wrap  >
            
            
   
            {customModeTempRange?(
                <Button onClick={()=>endCustomMode()} icon={<MdDone />} text="Done"/>
            ):(
            <DropButton
                
                dropAlign={{ top: "bottom" }}
                open={dropOpen}
                onOpen={() => setDropOpen(true)}
                onClose={() => setDropOpen(false)}
                label={
                    <Box direction="row" align="center" justify="center" gap="small">
                        <BiCalendar />
                        <Text size="small">
                            {currentOption}
                        </Text>
                    </Box>
                }
                dropContent={
                    <Box pad="5px" round="2px" gap="2px">
                        {Object.keys(_options).map(o => (
                            <Box key={o} onClick={() => {
                                setCurrentOption(o)
                                setDropOpen(false)
                                let selected = (_options as any)[o]
                                if (typeof (selected) === "function") {
                                    setCustomMode(false)
                                    setCurrentRange((_options as any)[o]() as any)
                                }
                                else {
                                    if (selected == "custom") {
                                        setCustomMode(true)
                                        setCustomModeTempRange({ since:range?.since?.toISOString(), until:range?.until?.toISOString() })
                                    }
                                }
                            }
                            }>
                                {o}
                            </Box>
                        ))}
                    </Box>}
            />)}

{!(customMode && customModeTempRange) ? (
                <Box direction="row" align="center" gap="2px" onClick={()=>startCustomMode()} focusIndicator={false}>
                    <Text size="small">{` ${currentRange?.since?.toDateString()}`}</Text>
                    <Text size="small">-</Text>
                    <Text size="small">{currentRange?.until?`${currentRange?.until?.toDateString()}`:"now"}</Text>
                </Box>
            ) : (
                <Box direction="row" align="center" gap="2px" >
                    <MaskedInput
                    mask={maskFormat}
                    style={{padding:"2px", width:"110px"}}
                    size="small"
                    value={formatDate(customModeTempRange.since)}
                    onChange={(event) =>{
                        try{
                            setCustomModeTempRange({since: (event.target.value as string),until:customModeTempRange.until})
                        }catch{}
                    }}
                    />
                     <MaskedInput
                    mask={maskFormat}
                    size="small"
                    style={{padding:"2px", width:"110px"}}
                    value={formatDate(customModeTempRange.until)}
                    onChange={(event) =>{
                        try{
                            setCustomModeTempRange({since:customModeTempRange.since ,until:(event.target.value as string)})
                        }catch{}
                    }}
                    />
                    <DateInput
                        ref={dateInputRef}
                        
                        buttonProps={{ style:{padding:"2px"},  size: "10px", icon:<BsCalendar3/> }} 
                        defaultValue={[customModeTempRange?.since, customModeTempRange?.until]}
                        onChange={event=>{
                            setCustomModeTempRange({ since: (event.value[0]), until: (event.value[1]) })
                        }}
                        
                        calendarProps={{ size: "small" }} 
                        size="xsmall"
                         />

                </Box>
            )}

        </Box>

    )
}

export { DateRangePicker, getRelativeDate, addSeconds }