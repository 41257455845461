import {  useState, useEffect, useRef, useMemo } from "react";
import { Box, Text} from "grommet";
import Showdown from "showdown";


const HighlightableText = ({text, size, highlightText})=>{

    const highlight = (text, highlightText=undefined)=>{
        if (!text) return "";
        const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        let matchedSubStrings =[];
       
        if (highlightText){
            let hightligthMathes=[];
            for (const subText of highlightText.split(" ")) {
                const matchRegex = new RegExp(regEscape(subText),"gi");
                for (const match of text.matchAll(matchRegex)){
                    match && hightligthMathes.push(match)
                }
            }
            
            if (matchedSubStrings && matchedSubStrings.length>0)
                matchedSubStrings =  [...hightligthMathes,...matchedSubStrings ]
            else
                matchedSubStrings =  hightligthMathes;

        }
        const splits=[{from:-1,to:text.length,colors:null}]
        for (const matchId in matchedSubStrings){
            let match = matchedSubStrings[matchId]
            if (match[0]){
                let color = "yellow";
                splits.push({from:match.index, to:match.index+match[0].length, color:color })
            }
        }

        let ranges = new Set;
        
        splits.forEach(({ from, to }) => (ranges.add(from), ranges.add(to)));
        let parts = [...ranges].sort((a, b) => a - b);
        let resultSplits = parts.slice(1).map(function (a, i, aa) {
            var from = i ? aa[i - 1] : parts[0],
                to = a,
                colors = splits.filter(d => d.from <= from && to <= d.to).map(({ color }) => color).filter(c=>Boolean(c));
            return { from: from>=0?from:0, to, colors };
        });
            
        return resultSplits.map((s)=>({text:text.slice(s.from,s.to), color:s.colors[0]})).filter(t=>t.text)
        .map(({text,color},i)=>{
            const style=color? {backgroundColor:color} : undefined;
            let converted_text=showdownConverter.makeHtml(text);
            return <Text size="small" key={i} style={style} wordBreak="break-word">{<div dangerouslySetInnerHTML={{__html: converted_text}}/>}</Text>
        })

        
    }
    const [subTexts,setSubTexts]=useState()

    const showdownConverter=useMemo(()=>new Showdown.Converter({tables: true}),[])
    useEffect(()=>{
        
        if (showdownConverter&& text){
            let _text=text.replaceAll("\n","^^br^^")
            if (highlightText){                   let converted_text=_text;
                [...new Set(highlightText.trim().split("\b"))].forEach(word =>{

                    converted_text=showdownConverter.makeHtml(converted_text.replaceAll(word,`〉〉〉${word}〈〈〈`));
                })
                converted_text =converted_text.replaceAll("〉〉〉","<span style=\"background-color:yellow\">").replaceAll("〈〈〈","<\/span>").replaceAll("^^br^^","<br/>")
                
                
                //  setSubTexts(highlight(text,highlightText))
                 setSubTexts(<div dangerouslySetInnerHTML={{__html: converted_text}}/>)
            }
            else{
                let converted_text=showdownConverter.makeHtml(_text).replaceAll("^^br^^","<br/>")
                setSubTexts(<div dangerouslySetInnerHTML={{__html: converted_text}}/>)
            }
        }
    },[text, highlightText,showdownConverter ])


    if (true ){

        return( 
            <Box flex="shrink"  className="highlightableText" >
                <Text size={size||"small"} wordBreak="break-word" textAlign="start">
            {
               subTexts
            }
            </Text>
            </Box>
         )
        
    }
    else 
        return (text&&text.split(/(\r?\n)|(\xa0)/).map((t,i)=>( <Text size="small" key={i} wordBreak="break-word">{t}<br/> </Text>)) )

}

export {HighlightableText}