


import { Box,   Drop,    Text} from "grommet"

import { useMemo, useRef, useState } from "react"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"



export  default function FunctionCallArguments({argumentsString}:{
    argumentsString:string
    
}){

    const args=useMemo<any>(()=>{
        try{

            return JSON.parse(argumentsString)
        }
        catch(e){
            console.error("Error parsing func.call arguments",e)
            return {__invalid_json__:argumentsString}
        }
    },[argumentsString])
    return (
        <Box >
         {args && <KeyValuesComponent object={args} />}
        </Box>    
    )

    
}

