


import { Box, Button,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useState } from "react"
import { GrRobot } from "react-icons/gr"
import FeedbackComponent from "../FeedbackComponent/feedbackComponent"
import Rating from "../RatingComponent/rating"
import { getApi } from "../../apiService"
import FeedbackComponentFull from "../FeedbackComponent/feedbackComponentFull"



export  default function AnswerComponent({activity, allActivities, onClick, onChange}:{
    activity:Answer
    allActivities:ActivityBase[]
    onClick?:()=>any,
    onChange?:(activity:ActivityBase)=>any
}){
  
    


    return (
        <Box direction="row" align="start" justify="start" margin="0px 50px 0px 0px">
            <Box align="center"  pad="0px 10px" flex={false}>
                <Box pad="2px" flex={false} background="rgba(9, 255, 0, 0.55)" round="full" >
                    <GrRobot size="25px"  />
                </Box>
            </Box>
            <Box align="start">

                <Box className="answer-box" focusIndicator={false}   background="white" justify="between" onClick={onClick ?()=> onClick():undefined}>
                <HighlightableText text={activity.text}/>
                </Box>
                <FeedbackComponentFull activity={activity} onActivityChange={(a)=>onChange(a)}/>
            </Box>
        </Box>
    )

    
}

