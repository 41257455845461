


import { Box,   Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer, LlmPrompt as LlmPrompt } from "../../models/dataModel"


import { useEffect, useState, memo } from "react"
import { getApi } from "../../apiService"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import "./promptRuns.css"
import  LlmPromptComponent from "../Activities/LlmPrompt"
import ActivityComponent from "../Activities/ActivityComponent"
import Badge from "../Badge/badge"
import Moment from "react-moment"
import { GoLinkExternal } from "react-icons/go"
import { useNavigate } from "react-router-dom"

export  default  function PromptRuns({prompt_template_version_id, data, onLoad, rest}:{
    prompt_template_version_id?:string
    [key:string]:any
}){
  
    const navigate=useNavigate()
    const [loading, setLoading]=useState<boolean>()
    const [promptRuns, setPromptRuns] = useState<LlmPrompt[]>()
    useEffect(()=>{
        if (prompt_template_version_id && !promptRuns){
            
            setLoading(true)
            getApi().getPromptRuns(prompt_template_version_id)
            .then(prs=>setPromptRuns(prs))
            .finally(()=>setLoading(false))
        }
    },[prompt_template_version_id])

    const onUpdateActivity = (promptRun:LlmPrompt)=>{
        let i = promptRuns.indexOf(promptRun)
        if (i>=0){
            promptRuns[i]=promptRun
            setPromptRuns([...promptRuns])
        }
     }

    return (
        <Box {...rest}  flex={false} >
            {loading&&(
                <Box align="center" justify="center" pad="20px">
                    <Spinner />
                </Box>
            )}
           {promptRuns?.map((pr,i)=>(
                <Box key={i} className="promptRunBox" >
                    <Box align="center" margin="-10px 0px -5px">
                        <a href={"/sessions?session_id="+pr.session_id} target="_blank">

                    <Badge ><Text size="small" color="brand">Session <Moment fromNow>{pr.start_time}</Moment> </Text> <Text color="brand"><GoLinkExternal /></Text> </Badge>
                        </a>
                    </Box>
                    <ActivityComponent activity={pr}  showInputs onChange={onUpdateActivity}/>
                    {/* <KeyValuesComponent object={pr.prompt_input_params}/> */}
                    
                    
                </Box>
           ))}
           {promptRuns && promptRuns.length==0&&(
            <Box pad="20px" background="light-4" round="small" align="center">

            <Text size="large">No prompt template runs found 🥺</Text>
            </Box>
           )}
           
        </Box>    
    )

    
}

