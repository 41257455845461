


import { Box, Button, Layer, Tip, Text } from "grommet"
import { useMemo } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAppContext } from "../../appContext"
import { Logo, SessionsIcon, StatisticsIcon, SettingsIcon, PromptTemplateIcon, UnitTestIcon } from "../CommonIcons/commonIcons"
import UserAvatar from "../UserAvatar/userAvatar"
  

import "./appSidebar.css"
import { MdDashboard, MdOutlineDashboard, MdOutlineSpaceDashboard } from "react-icons/md"
import { GrTest } from "react-icons/gr"


export default function AppSidebar({ }: {
    [key: string]: any
}) {

    const {currentUser} = useAppContext()
    const [searchParams,setSearchParams]=useSearchParams()
    const tempApiKey = useMemo(()=>searchParams?.get("temp-api-key"),[searchParams])
    


    
    const navigate = useNavigate()


    return (
        <Box background="dark-1" width="80px" className="appSidebar" justify="between" pad="10px 0px 0px" height="100vh" flex={false}>
            <Box  gap="5px">

                <Text color="brand">
                    <Box onClick={()=>navigate("/")} focusIndicator={false} align="center">

                    <Logo className="logo-icon" size="xlarge" />
                    </Box>
                </Text>
                <Box  margin="10px 0px">
                {(currentUser || tempApiKey)&&(
                    <>
                        <AppSidebarButton icon={<MdDashboard size="30px" className="sidebar-icon" />} name="Dashboard" path={"/home"}/>  
                        <AppSidebarButton icon={<SessionsIcon size="large" className="sidebar-icon" />} name="Sessions" path={("/sessions")}/>
                        <AppSidebarButton icon={<PromptTemplateIcon size="large" className="sidebar-icon" />} name="Prompts" path={("/prompt-templates")} />
                        <AppSidebarButton icon={<UnitTestIcon size="large"  color="" className="sidebar-icon" />} name="Tests" path={("/unit-tests")} />
                        {/* <AppSidebarButton icon={<StatisticsIcon size="large" className="sidebar-icon" />} name="Statistics" path="/statistics" /> */}
                    </>
                )}
                </Box>
            </Box>
            <Box margin="0px 0px 10px">
                <Box >
                {currentUser&&(
                    <AppSidebarButton icon={<UserAvatar />}  tooltip="Account settings" path="/settings" />
                )}
                </Box>
                {/* <AppSidebarButton icon={<SettingsIcon size="large" className="sidebar-icon" />} name="Settings" path="/settings" /> */}
            </Box>
        </Box>
    )

}

function AppSidebarButton({ icon, onClick, path, name, tooltip }: {
    icon: any
    onClick?: () => any
    path:string
    name?: string
    tooltip?: string
}) {


const location = useLocation()
const active = useMemo(()=>location && location.pathname==path,[location])
const navigate = useNavigate()
const [searchParams,setSearchParams]=useSearchParams()
const tempApiKey = useMemo(()=>searchParams?.get("temp-api-key"),[searchParams])
function withDemoRedirect(path:string){
    if (tempApiKey ){
        if (!path.startsWith("/sessions")){
            return "/demo"+path+"?temp-api-key="+tempApiKey
        }else{
            return path+"?temp-api-key="+tempApiKey
        }
    }
    return path
}

    return (
        // <Tip plain dropProps={{align:{left:"right"}}} content={
        //     <Box 
            
        //     className="sidebar-button-tip"  pad="10px" round="0px 10px 10px 0px" focusIndicator={false} >
        //         <Text>

        //         {(tooltip || name)}
        //         </Text>
        //     </Box>
        //     }>

            <Box align="center" className={"sidebar-button" +(active? " active":"")} gap="5px" onClick={()=>path?navigate(withDemoRedirect(path)):onClick()} focusIndicator={false}>
                {icon}
                {name && <Text color={active?"white": "light-4"} weight={900} size="small">{name}</Text>}
            </Box>
        // </Tip>
    )

}
