
import { Avatar, Box,    DropButton,    Heading,    Spinner,    Text, TextInput } from "grommet";
import { useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";
import Button from "../components/Button/button";
import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";
import UserAvatar from "../components/UserAvatar/userAvatar";
import { MdLogout } from "react-icons/md";
import { HiSparkles } from "react-icons/hi2";
import CodeBlock from "../components/CodeBlock/codeBlock";
import { FiCopy } from "react-icons/fi";
import { AutosizeTextInput } from "../components/AutosizeTextInput/autosizeTextInput";
import { Logo } from "../components/CommonIcons/commonIcons";
import { BiMessageSquareDots } from "react-icons/bi";
import AppSidebar from "../components/AppSidebar/appSidebar";



export  default function GetApiKeyPage(){
    
    const {currentUser, logOut} = useAppContext()

    const navigate = useNavigate()
    
    const [apiKeyObj, setApiKeyObj] = useState<any>()
    const [info, setInfo] = useState<string>()

    
    const [waitGenerating, setWaitGenerating] =useState(false)

    return (
        <Box direction='row' align='stretch' justify='stretch' fill>

        {currentUser&&(<AppSidebar/>)}
        <Box  
            background="dark-1" 
             align="stretch"
              justify="stretch" 
              height="100vh" 
              overflow="auto" 
              flex="grow"
              
            
            >
        {currentUser?(
        <Box alignSelf="start" margin="23px 0px 0px"  flex={false} >
            <Text size="30px" color="accent-1" weight={900}>
                PromptWatch.io
            </Text>
        </Box>):(
            <Box alignSelf="start" margin="10px 10px 0px"  flex={false} onClick={()=>navigate("/")} focusIndicator={false}>
            <Logo size="xlarge" withText/>
            </Box>
        )}
     
        <Box pad="medium" flex={false} gap="small"  alignSelf="center"  style={{maxWidth:"85vw"}}>

            {currentUser?(
                <Box width="300px" alignSelf="center">
                    <Heading level={2}>Welcome</Heading>
                <Box direction="row" align="start" gap="medium" >
                        <UserAvatar showName/>  
                        <Button margin="25px 0px 0px"   icon={<MdLogout/>} text="Logout" onClick={()=>logOut(true,"/get-api-key")}/>
                </Box>   
                </Box>
            ):(
                <Box width="300px" alignSelf="center" >
                <Heading level={2}>Hello stranger</Heading>
                <Box gap="small" >
                <Text   size="large"><Text color="accent-1" size="large" weight={900}>PromptWatch.io</Text>  will help you track all <b>LangChain</b> prompts and executions <br/> <b> for free</b>.</Text>
  
                <Box  align="center" justify="center" gap="small" direction="row">
                    
                    <Button primary size="large" text="Sign up" onClick={()=>navigate("/signup?followup="+encodeURIComponent("/get-api-key"))}/>
                    <Button secondary size="large" text="Login"  onClick={()=>navigate("/login?followup="+encodeURIComponent("/get-api-key"))}/>
                    
                </Box>
                
              
                </Box>
               
            </Box>
                )
            }
            <Box alignSelf="center" margin="40px 0px 0px" height="130px" flex={false}>
            
            {!apiKeyObj?(
                        <Box align="center">
                           {!currentUser&&(
                            <Text size="large">
                                If you wan't to <b>try it first</b>, you can generate a one time use <b>temporary API key</b>.
                            </Text>
                            )}
                            {waitGenerating?(
                                 <Button margin="10px 0px 2px" primary size="large"  icon={<Spinner color="white"/>} text="Generating"/>
                            ):(
                                <Button margin="10px 0px 2px" primary size="large"  icon={<HiSparkles size="30px"/>} text={currentUser?"Get new API key":"Get temporary API key"} onClick={()=>{
                                    setWaitGenerating(true)
                                    if (!currentUser){
                                        getApi().createTempApiKey().then(theKey=>{
                                            setApiKeyObj(theKey)                                    
                                        })
                                    }
                                    else {
                                        getApi().createApiKey().then(theKey=>{
                                            setApiKeyObj(theKey)                                    
                                        })
                                    }
                                }
                                }/>
                                )}
                                 {!currentUser&&(
                                <Text>one click - no registration needed</Text>
                                 )}
                        </Box>
                        ):(
                            <Box align="center" flex={false}>
                                 <Text size="large" weight={900} color="brand">Your API key</Text>
                                <Box direction="row">
                                    
                                    <AutosizeTextInput  value={apiKeyObj.value} size="small"/> 
                                        
                                        
                                    <Button   flex={false} round="2px" margin="0px 2px" onClick={()=>{
                                        setInfo("Copied!")
                                        navigator.clipboard.writeText(apiKeyObj.value)
                                        setTimeout(()=>setInfo(""),1000)
                                    }
                                    }>
                                        <FiCopy size="15px" />
                                    </Button>
                                </Box>
                                <Text weight={900}>{info}</Text>
                                <Box margin="30px">

                                <Text>Here you can check your trace</Text>
                                <a style={{color:"white"}} href={`https://www.promptwatch.io/sessions?temp-api-key=${apiKeyObj?.value}`}>{`https://app.promptwatch.io/sessions?temp-api-key=${apiKeyObj?.value}`}</a>
                                </Box>
                            </Box>
                        )}
                        
                        

                        
                        
                        
            </Box>
            <Box  gap="small" margin="20px 0px" alignSelf="center">
            

            <CodeBlock  caption="Example integration"  highlightLines={[2,7,8]}>
{`from langchain import OpenAI, LLMChain, PromptTemplate
from promptwatch import PromptWatch

prompt_template = PromptTemplate.from_template("Finish this sentence {input}")
my_chain = LLMChain(llm=OpenAI(), prompt=prompt_template)

with PromptWatch(api_key="${apiKeyObj?.value||"<your-api-key>"}") as pw:
    my_chain("The quick brown fox jumped over")`}

            </CodeBlock>
            <Text>and don't forget to</Text>

            <CodeBlock  caption="Install client" language="bash" >
                pip install promptwatch

            </CodeBlock>
                
            </Box>
            
        
        </Box>
        </Box>
        </Box>
    )
}