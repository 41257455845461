
import { Avatar, Box,    Heading,    Spinner,    Text } from "grommet";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Moment from 'react-moment';
import { FaUser } from "react-icons/fa";
import { useAppContext } from "../../appContext";



export  default function UserAvatar({showName, size=35}:{size?:number, showName?:boolean}){
    
    
    const {currentUser} = useAppContext()
    const [isErr, setErr] = useState<boolean>()

    const navigate = useNavigate()
    if (!currentUser){
        return  <></>
    }

    return (
        <Box direction="row" gap="20px" align="center" flex={false}>
        {!isErr && currentUser?.photoURL?(
            <Avatar size={size+"px"} >
                <img  
                    style={{width: size+'px', height: size+'px'}} 
                    src={currentUser.photoURL} 
                    referrerPolicy="no-referrer"
                    onError={()=>{
                        setErr(true)  
                    }}
                    />
            </Avatar>
        ):(
            <Avatar border size={size+"px"} background="light-1"><FaUser size={size-15}/></Avatar>
        )}
        {showName&&(
            <Box align="start">
                <Text size={Math.round(9+(size/10))+"px"} weight={900}>{currentUser.name}</Text>
                {currentUser.name!==currentUser.email&&(
                     <Text size="small">{currentUser.email}</Text>
                    )}
            </Box>
            )}
    </Box>
    )
}