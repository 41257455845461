
import { Box,  DataTable,  Heading,  Spinner,  Text, TextInput } from "grommet";
import { useContext, useEffect, useState } from "react";

import Popup from "../components/Popup/popup";


import { getApi } from "../apiService";
import { useNavigate, useParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCopy, FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import SessionDetail from "../components/SessionDetail/sessionDetail";
import PromptTemplatesList from "../components/PromptTemplatesList/promptTemplatesList";
import MaterDetailPageWrapper from "./materDetailPageWrapper";
import PromptRuns from "../components/PromptRuns/promptRuns";
import Button from "../components/Button/button";
import { KeyValuesComponent } from "../components/KeyValuesComponent/keyValues";
import { MdCancel, MdClose, MdCopyAll, MdDelete, MdDeleteOutline, MdLock, MdLogout, MdPassword } from "react-icons/md";
import UserAvatar from "../components/UserAvatar/userAvatar";
import { firebaseApp } from '../firebase/firebaseApp';
import { getAuth, updatePassword, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { useAppContext } from "../appContext";
import { AutosizeTextInput } from "../components/AutosizeTextInput/autosizeTextInput";
import CodeBlock from "../components/CodeBlock/codeBlock";
import { ApiKeyDetail } from "../components/ApiKeyDetail/apiKeyDetail";

const ChangePasswordDialog = ()=>{
    const [changePasswordState, setChangePasswordState] = useState<{old?, new1?, new2?, changed?}>({old:"",new1:"",new2:"",changed:false})
    const [error, setError] =useState<string>()

    useEffect(()=>{
        if (changePasswordState.changed){

            setTimeout(()=>{
                window.location.href="/login"
            },5000)
        }
    },[changePasswordState])
    
    if (changePasswordState.changed){
        return <Box align="center" pad="50px">
            <Text size="xlarge" weight={900}>Password has been changed 👍</Text>
            <Text size="large" weight={900}>You need to login again.... </Text>
            <Spinner/>
            <Button primary margin="10px 0px" text="OK" onClick={()=>{
                window.location.href="/login"
            }}/>
        </Box>
    }
   return ( <Box pad="large" align="start" >
    <Text size="large" weight={900} color="brand">Old password</Text>
    <TextInput type="password" value={changePasswordState?.old||""} onChange={e=> setChangePasswordState({...changePasswordState, old:e.target.value})}/>
    <Text size="large" weight={900} color="brand">New password</Text>
    <TextInput type="password" value={changePasswordState?.new1||""} onChange={e=> setChangePasswordState({...changePasswordState, new1:e.target.value})}/>
    <Text size="large" weight={900} color="brand">Re enter new password</Text>
    <TextInput type="password"  value={changePasswordState?.new2||""} onChange={e=> setChangePasswordState({...changePasswordState, new2:e.target.value})}/>
    
    <Button primary margin="10px 0px" text="Change" onClick={()=>{
        if (changePasswordState.new1 &&  changePasswordState.new1 == changePasswordState.new2){
            const auth = getAuth(firebaseApp)
            signInWithEmailAndPassword(auth,auth.currentUser.email, changePasswordState.old).then(userCredential=>{

                return updatePassword(userCredential.user,changePasswordState.new1).then(()=>{
                    signOut(auth)
                    setChangePasswordState({changed:true})

                }).catch(err=>{
                    setError(err.message.replace("Firebase: ",""))        
                })
            }).catch(err=>{
                setError("Old password: " + err.message.replace("Firebase: ",""))        
            })

             
        }
        else{
            setError("Passwords do not match")
        }
    }}/>
    {error&&<Text color="red">{error}</Text>}
</Box>)
}





export  default function SettingsPage(){
    
    const [popup, setPopup]=useState<any>()
    
    const [currentSubPage, setCurrentSubPage]=useState<any>("My account")

    const [accountInfo, setAccountInfo]=useState<AccountInfoResponse>()
    const [apiKeys, setApiKeys]=useState<ApiKey[]>()

    const {logOut} = useAppContext()

    useEffect(()=>{
        getApi().getAccountInfo().then(accountInfoData=>{
            setAccountInfo(accountInfoData)
        })
    },[])

    useEffect(()=>{
        getApi().getApiKeys().then(apiKeysData=>{
            setApiKeys(apiKeysData)
        })
    },[])
    
    
    function changePassword(){
        
        setPopup(<Popup caption="Change your password" onClose={()=>setPopup(null)}>
       <ChangePasswordDialog/>
</Popup>)
       
    }

    const subPages={
        "My account":(
            <Box pad="20px" align="start" gap="small">
                <Text color="brand" weight={900} size="xlarge">
                My account
                </Text>
                <UserAvatar showName/>
                <Box margin="0px 0px 10px 45px" gap="2px" >

                    <Button secondary icon={<MdLogout/>} text="Logout" onClick={()=>{
                        logOut()
                    }}/> 
                    <Button primary icon={<MdLock/>} text="Change password" onClick={()=>changePassword()} /> 
                    <Box align="start" margin="10px 0px">
                </Box>
                </Box>
                {/* <Text weight={900} size="large" color="brand">My organization</Text>
                <Box margin="0px 0px 10px 45px" gap="2px" >
                    <KeyValuesComponent size="large" object={accountInfo?.organization}/>
                </Box> */}
            </Box>
        ),
        "API keys":(
            <Box pad="20px" align="start" flex={false}>
                <Text color="brand" weight={900} size="xlarge">

                Api keys
                </Text>

                <Box  round="2px" pad="10px" margin="10px"   align="start" fill="horizontal" >

                <DataTable
                    style={{tableLayout:"fixed"}}
                    columns={[
                    {
                        property: 'value',
                        header: <Text>Api key</Text>,
                        primary: true,
                        size:"90%",
                        render: row=>(
                            <Box direction="row-responsive" justify="between"  wrap>
                            <Text style={{fontFamily:"monospace"}} truncate>{row.value}</Text>
                            <Button text="Show API key" onClick={()=>{
                                getApi().getApiKeyValue(row.id).then(theKey=>{

                                    
                                    setPopup(<Popup caption="API key" onClose={()=>setPopup(null)}>
                                           <ApiKeyDetail apiKey={theKey}/>
                                    </Popup>)
                                    }
                                    )
                                    
                            }}/>
                            </Box>
                        ),
                        
                    },
                   
                   
                    {
                        property: 'id',
                        header: '',
                        align:"end",
                        size:"80px",
                        render: row=>(
                            row.active?(

                                <Button icon={<MdClose size="15px"/>} text="Disable"  onClick={()=>{
                                    getApi().disableApiKeyValue(row.id).then((newData)=>{
                                            setApiKeys(newData)
                                        })
                                    }}/>
                                    ):(
                                        <Box round="5px" pad="3px 10px" flex={false} background="pink"><Text color="dark-2" weight={600}>Disabled</Text></Box>
                                    )
                        ),
                    },
                    ]}
                    data={apiKeys}
                    />
                    
                        {!apiKeys?.length && (
                            <Box  pad="15px" margin="10px" align="center" justify="center" >
                                <Text weight={900} color="dark-2">No API keys yet</Text>
                            </Box>
                            )}

                    <Box align="start" flex={false}>
                            <Button background="brand" text="Create new API key" size="large"
                            onClick={()=>getApi().createApiKey().then((key)=>{
                                setApiKeys([...(apiKeys||[]), key])
                                setPopup(<Popup caption="New API key" onClose={()=>setPopup(null)}>
                                <ApiKeyDetail apiKey={key}/>
                            </Popup>)
                            })}
                            />
                    </Box>
                </Box>
            </Box>
        )
    }


    // useEffect(()=>{
    //     if (promptTemplate){

    //         getApi().getPromptRuns(promptTemplate.id).then(runs=>{

    //         })
    //     }
    // },[promptTemplate])

    return (
        <Box fill >
       {popup}
       <MaterDetailPageWrapper pageHeading="Settings"
       supportedModes={["normal", "minimized"]}
       master={
           <Box background="dark-2" pad="10px 0px">
                {Object.keys(subPages).map((pageCaption)=>(
                    <Box >
                         <Button border={false} pad="8px 0px"
                         round={false}
                         onClick={()=>setCurrentSubPage(pageCaption)}
                         background={pageCaption==currentSubPage?"dark-3":undefined}
                         >
                            <Text 
                                size="large" 
                                weight={pageCaption==currentSubPage?900:undefined}
                                color={pageCaption==currentSubPage?"accent-1":undefined}
                            >
                                {pageCaption}
                            </Text>

                         </Button>
                    </Box>)
                )}
           </Box>
       }
       detailOpen
       
        >
            <Box  height={"100vh"} overflow="auto" >
            {subPages[currentSubPage]}
            </Box>

       </MaterDetailPageWrapper>


        
        </Box>
    )
}