


import { Box, Button,  Layer,  Spinner, Text } from "grommet"
import { useState } from "react"
import { MdClose, MdUndo } from "react-icons/md"


export  default function Popup({children, caption, promptBeforeClose, closeOnClickOutside=true, onClose}:{
    children:any
    caption?:string
    promptBeforeClose?:boolean
    closeOnClickOutside?:boolean|undefined|(()=>any)
    onClose:()=>any
}){
  
    const [closing, setClosing] = useState(false)

    const outsideClick = ()=>typeof(closeOnClickOutside)==="function"?closeOnClickOutside(): (closeOnClickOutside && (promptBeforeClose?setClosing(true):onClose()))
    return (
                
    <Layer position="bottom" style={{zIndex:99}} background={promptBeforeClose?"blue":"rgba(0,0,0,0.2)"} onClickOutside={outsideClick} >

        <Box justify="start" flex="grow"   onClick={outsideClick} focusIndicator={false}>
        </Box>
        <Box 
        style={{minHeight:"60vh", minWidth:"80vw", maxHeight:"90vh"}} 
         background="white" round="10px 10px 0px 0px"  >
            <Box direction="row" justify="between" align="center" pad="0px 0px 0px 10px" flex={false}>
                <Text weight={900}>{caption}</Text>
                <Box flex={false} alignSelf="end" pad="10px" focusIndicator={false} onClick={()=>promptBeforeClose?setClosing(true):onClose()}><MdClose/></Box>
            </Box>
            <Box overflow="auto">
                {!closing?(
                children
                ):(
                    <Box alignSelf="center" justify="center" gap="20px">
                        <Text  size="15px" weight={900}>Are you sure you want to close {caption} ?</Text>
                        <Box direction="row" alignSelf="center" justify="end" gap="10px">
                            <Button primary icon={<MdClose/>} label="Yes, close"  onClick={()=>onClose()}/>
                            <Button secondary icon={<MdUndo/>} label="No, cancel" onClick={()=>setClosing(false)} />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    </Layer>

)
    
}