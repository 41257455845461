


import { Box, Button,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill} from "react-icons/bs"
import {FaUserAlt} from "react-icons/fa"
import { useState } from "react"



export  default function QuestionComponent({activity, allActivities, onClick}:{
    activity:Question
    allActivities:ActivityBase[]
    onClick?:()=>any
}){
  
    


    return (
        <Box direction="row" justify="end" margin="0px 0px 0px 50px">

        <Box className="question-box"  round="5px" background="white" justify="between" onClick={()=>onClick()} border>
           <HighlightableText text={activity.text}/>
           
           
        </Box>    
        <Box pad="0px 10px" flex={false}>
            <Text color="brand">

            <FaUserAlt  size="20px"/>
            </Text>
        </Box>
        </Box>
    )

    
}

