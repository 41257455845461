import { Box, Heading, Text } from "grommet";
import bluredPrompts from './bluredPrompts.png'; 
import "./demo.css";
import Button from "../components/Button/button";
import { useNavigate } from "react-router-dom";
export default function DemoPrompts() {

    const navigate = useNavigate()
    return <Box  style={{display:"relative"}} background="dark-1" fill>
    <div className="demo-overlay ">
      <Box className="infoBox" align="start" justify="start" >
      <Heading level={2}>Hello stranger 👋</Heading>
      <Text size="large" textAlign="start">
        You would normally be able to see here your registered prompt templates, which allows you to</Text>
      
        <Text size="large"  textAlign="start">
            <ul>
                <li>track all template versions</li>
                <li>discover all executions of that prompts with its parameters</li>
                <li>... and much more coming</li>
            </ul>
        </Text>
        <Text size="large" textAlign="start">
        Consider signing up for a free account to get started.
      </Text>
      <Box margin="10px 0px 0px" alignSelf="end">

        <Button size="large"  primary label="Sign up"  onClick={()=>navigate("/signup")} text="Sign up"/>
      </Box>
      </Box>
    </div>
<Box fill
 style={{
  backgroundImage: `url(${bluredPrompts})`, // Set the background image using the imported image file
  backgroundSize: 'cover', // Optional: Set the background size to cover if needed
  backgroundRepeat: 'no-repeat', // Optional: Set the background repeat property if needed
  // Add any other styles you want for your div
}}
>

    
  
        
</Box>
        
    </Box>
}