


import { Box,   Layer,  Spinner, Text } from "grommet"
import { useEffect, useMemo, useState } from "react"
import { MdClose, MdIosShare, MdRefresh } from "react-icons/md"
import { getApi } from "../../apiService"
import { ActivityBase, Session } from "../../models/dataModel"
import ActivityComponent from "../Activities/ActivityComponent"
import { LoadingCover } from "../LoadingCover/loadingCover"
import Button from "../Button/button"
import { BsChevronBarExpand, BsChevronContract } from "react-icons/bs"
import { BiHide, BiShow } from "react-icons/bi"
import Badge from "../Badge/badge"
import { IoMdShare } from "react-icons/io"

export  default function SessionDetail({sessionId}:{
    sessionId:string
}){
  
    const [activities,setActivities]=useState<any[]>()
    const [loading, setLoading]=useState<boolean>()
    const [session, setSession]=useState<Session>()

    useEffect(()=>{
        if (sessionId && !(session?.id==sessionId)){
            getApi().getSession(sessionId).then(data=>{
                setSession(data)
            })
        }
    },[sessionId])
    
    function loadData(){
        setLoading(true)
        getApi().getSessionActivities(sessionId).then(data=>{
            if (Array.isArray(data))
                setActivities(data)
        }).finally(()=>setLoading(false))
    }
    useEffect(()=>{
        loadData()
    },[sessionId])

    const onUpdateActivity = (activity:ActivityBase)=>{
        let i = activities.indexOf(activity)
        if (i<0){
            i = activities.findIndex(a=>a.id==activity.id)
        }
        if (i>=0){
            activities[i]={...activity}
            setActivities([...activities])
        }
    }

    const [showMode, setShowMode]=useState<"normal"|"only-conversation">()
    const [hasConversation,setHasConversation] = useState<boolean>()
    const [expandRange, setExpandRange] = useState<number[]>()

    useEffect(()=>{
        if (activities?.filter(a=>a.activity_type=="question")?.length>1){
            setShowMode("only-conversation")
            setHasConversation(true)
        }
        setExpandRange(undefined)
    },[activities])
    
    function findLastIndex(array, predicate, maxI) {
        for (let i = (maxI || (array.length - 1)); i >= 0; i--) {
          if (predicate(array[i])) {
            return i;
          }
        }
        return -1; // Return -1 if no matching element is found
      }

    const visibleActivities = useMemo(()=>{
        if (!activities?.filter){return []}
        
        if (/*showMode=="normal"*/showMode!="only-conversation"){
            return activities
            .filter(a=>!a.parent_activity_id && a.action_type!="log")
            .map(a=>(
                <Box key={a.id} pad="5px" flex={false} width="100%" >
                <ActivityComponent session={session} activity={a} allActivities={activities} onChange={onUpdateActivity}/>
            </Box>
            ))
        }
        else if (showMode=="only-conversation"){
            return activities
            .map((a,i)=>{
                let previousConversationBubbleIndex=-1;
                let lastConversationBubbleIndex=-1
                let responseTime=null;
                if (a.activity_type=="question" || a.activity_type=="answer"){
                    previousConversationBubbleIndex= findLastIndex(activities, (ap,ip)=>( ap.activity_type=="question" || ap.activity_type=="answer" ), i-1)                
                    lastConversationBubbleIndex= findLastIndex(activities, (ap,ip)=>( ap.activity_type=="question" || ap.activity_type=="answer" ),null)                
                    responseTime = a.start_time&&activities[previousConversationBubbleIndex] && activities[previousConversationBubbleIndex].end_time ? (+(new Date(a.start_time )) -   +(new Date(activities[previousConversationBubbleIndex].end_time))):undefined
                }
                return !a.parent_activity_id &&  <>
                {(a.activity_type=="question" || a.activity_type=="answer" || (expandRange && expandRange[0]<=i && expandRange[1]>=i ) || (expandRange && expandRange[0]==i ) || (expandRange && expandRange[1]==i ) ) &&
                (<>
                    {(i>0 && (a.activity_type=="question" || a.activity_type=="answer" || (expandRange && expandRange[0]==i) ) && a.order>1 )&& (
                        <Box align="center" justify="center" flex={false}>

                            <Box align="center" justify="center" border={{side:"bottom"}} margin="0px 10px -10px 10px" alignSelf="stretch"></Box>
                            <Button text={(!(expandRange && (expandRange[0]==i || expandRange[1]==i) )) ?"Expand details":"Collapse details"}  background="white" icon={(!(expandRange && (expandRange[0]==i || expandRange[1]==i) )) ? <BsChevronBarExpand/> : <BsChevronContract/>}
                            onClick={()=>{
                                !(expandRange && (expandRange[0]==i || expandRange[1]==i))  ? setExpandRange([previousConversationBubbleIndex+1, i]):setExpandRange(undefined)
                            }}
                            />
                        </Box>
                    )
                    }
                    <Box key={a.id} pad="5px" flex={false} width="100%" >
                    {responseTime && <Badge margin="0px 0px -5px 0px" text={<Text size="7pt">{`${Math.round(responseTime)/1000}s`}</Text>} alignSelf="center"/>}
                        
                        <ActivityComponent session={session} activity={a} allActivities={activities.filter(a=> a.activity_type!="log")} onChange={onUpdateActivity}/>
                    </Box>

                    {(i==lastConversationBubbleIndex && !(expandRange && expandRange[0]==i+1) && activities.length>lastConversationBubbleIndex && (a.activity_type=="question" || a.activity_type=="answer" ) && a.order>1 )&& (
                        <Box align="center" justify="center" flex={false}>

                            <Box align="center" justify="center" border={{side:"bottom"}} margin="0px 10px -10px 10px" alignSelf="stretch"></Box>
                            <Button text={"Expand details"}  background="white" icon={(!(expandRange && (expandRange[0]==i || expandRange[1]==i) )) ? <BsChevronBarExpand/> : <BsChevronContract/>}
                            onClick={()=>{
                                !(expandRange && (expandRange[0]==i || expandRange[1]==i)) ? setExpandRange([lastConversationBubbleIndex+1, activities.length]):setExpandRange(undefined)
                            }}
                            />
                        </Box>
                    )
                    }
                  
                </>)}
                </>
        })
        }
        
    },[showMode, activities, expandRange])

    const [headerInfo, setHeaderInfo] = useState<string>()

    function share(){
        getApi().getSessionShareApiKey(sessionId).then(data=>{
            if (!data["temp-api-key"]){
                setHeaderInfo("Error: Unauthorized")
                setTimeout(()=>setHeaderInfo(undefined), 2000)
                return
            }
            let url = `${window.location.origin}/sessions?session_id=${sessionId}&temp-api-key=${data["temp-api-key"]}`
            navigator.clipboard.writeText(url).then(()=>{
                setHeaderInfo("Link copied to clipboard")
                setTimeout(()=>setHeaderInfo(undefined), 2000)
            })
        })
    }

    return (
        <Box   overflow="auto" height="100%" fill >
            <Box direction="row" justify="stretch" align="center" pad="5px" flex={false} >
                <Box  width="100%" align="center">
                <Text >{headerInfo}</Text>
                </Box>
                <Button
                       
                    icon={<IoMdShare size="15px"/>}
                    text="Share"
                    onClick={()=>share()}
                />
            </Box>
            
            {/* {hasConversation && <Box align="end" justify="center" pad="4px" flex={false} style={{position:"sticky",top:0, zIndex:500}} >  
                <Button 
                    primary
                    text={showMode=="normal" ? "Show only conversation":"Show all"} 
                    icon={showMode!="normal"?<BiHide/>:<BiShow/>} 
                    onClick={()=>setShowMode(showMode=="normal"?"only-conversation":"normal")}/>
            </Box>
            } */}
            {loading&&<LoadingCover/>}  
            <>
            {visibleActivities}
            </>

            <Box align="center" justify="center" pad="10px" flex={false}>
                <Button icon={<MdRefresh/>} color="lightgray" onClick={()=>loadData()} text="Reload" />
            </Box>
            
        </Box>
    

)
    
}