


import { Box,   Collapsible,  Spinner, Stack, Text, TextArea, TextInput } from "grommet"
import { ActivityBase, RetrievalActivity, DocumentSnippet } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill, BsInfo} from "react-icons/bs"
import { useState } from "react"

import { HiDocumentText } from "react-icons/hi"

import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import Popup from "../Popup/popup"
import Button from "../Button/button"


const DocumentSnippetBox=({doc}:{doc:DocumentSnippet})=>{
    const [popup, setPopup] = useState<any>()
    return (
        <Box border round="5px"  pad="2px" flex={false}>
            <Box direction="row" gap="xsmall" justify="between">
                <Box direction="row" gap="3px" >
                        <HiDocumentText/><Text size="xsmall" color="gray">
                            <Text size="12px" >Document</Text>
                        </Text>
                        {popup}
                </Box>
                {Object.keys(doc).length>1 && <Button
                    border={false}
                    
                    icon={<BsInfo size="20px"/>} size="10px" pad="0px"
                    onClick={()=>{
                        let docExceptText = {...doc}
                        delete docExceptText.text
                        setPopup(<Popup caption="Document info" onClose={()=>setPopup(null)}>
                            <Box pad="small">
                                
                            <KeyValuesComponent object={docExceptText} size="small"/>
                            </Box>
                        </Popup>)
                    }}
                />}
            </Box>
            <Box pad="2px 10px">
             <HighlightableText size="10pt" text={doc.text}/>
            </Box>
        </Box>
    )
}

export  default function RetrievedDocs({activity, allActivities, onClick}:{
    activity:RetrievalActivity
    allActivities:ActivityBase[]
    onClick?:()=>any
}){
  
    
    const [popup, setPopup] = useState<any>()
    

    return (
        <Box flex="grow"   pad="2px" round="5px" background="white" justify="between" onClick={()=>onClick()} focusIndicator={false}>
            
            
            <Box direction="row" gap="small" align="center">

            </Box>
           <Box>
                
                

                <Box flex={false}  className="fadeout-parent" overflow="hidden">
                    <div className="fadeout">
                        <Box align="center" pad="5px">
                            <Button background="white" border text="Show more" onClick={()=>{
                                 setPopup(<Popup caption="Retrieved documents" onClose={()=>setPopup(null)}>
                                 <Box pad="small" flex={false} gap="5px">
                                 {activity.documents.map((doc,i)=>(
                                    <DocumentSnippetBox key={i} doc={doc}/>
                                    ))}
                                 
                                 </Box>
                             </Popup>)
                            }}/>
                        </Box>
                    </div>
                    {activity.documents.map((doc,i)=>(
                        <DocumentSnippetBox key={i} doc={doc}/>
                        ))}
                </Box>
                {popup}
                <Box flex="grow" border width="100%">

                </Box>
                
            
            </Box>
         
           
           
        </Box>    
    )

    
}

