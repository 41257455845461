import { FocusEventHandler, useEffect, useRef, useCallback, useState } from "react";
import sanitizeHtml from "sanitize-html"
import { renderToString } from 'react-dom/server'

const sanitizeConf = {
    allowedTags: ["span"],
    
};

const EditableBlock=({children,  onBlur, onBeginEditing, onFinishEditing,...rest}:
    {
        children:any
        
        onBeginEditing?:()=>any
        onFinishEditing?:(text, html?)=>any
        [key:string]:any
    })=>{
        function handlePaste(e) {
            e.preventDefault();
            const text = e.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
        }

    const [content, setContent] = useState<string>()


    useEffect(()=>{
        let _html=renderToString(children)
        setContent(_html)
    },[children])

    const onContentChange = useCallback(evt => {
         
    
            setContent(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf))
        }, [])
    
    
    return <div  contentEditable={true} suppressContentEditableWarning={true} className="editor" style={{ whiteSpace: "pre-wrap", flexGrow: "1" }} 

    onPaste={handlePaste} 
    onFocus={e=> {onBeginEditing&&onBeginEditing()}}
    onKeyDown={e=>{
        if (e.key === 'Enter') {
            e.preventDefault();
            const range = window.getSelection().getRangeAt(0);
            const br = document.createElement('br');
            range.insertNode(br);
            range.setStartAfter(br);
            range.collapse(true);
          }
    }}
    onBlur={e=> {
        onFinishEditing && onFinishEditing(e.target.innerText, e.target.innerHTML)
        
    } } {...rest} dangerouslySetInnerHTML={{__html: content}}/>


}

export default EditableBlock;