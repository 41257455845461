


import { Box,   Drop,   Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useEffect, useRef, useState } from "react"

import { IoMdClose } from "react-icons/io"
import { getApi } from "../../apiService"
import ActivityComponent from "../Activities/ActivityComponent"


export  default function ActivityDetail({activity, activityId}:{
    activityId?:string
    activity?:ActivityBase,
}){
  
   const [innerActivity, setActivity] = useState(activity)
   const [childActivities, setChildActivities] = useState<ActivityBase[]>()

   useEffect(()=>{
    if(activity&&activity!==innerActivity){
        setActivity(activity)
    }else{
        if(activityId){
            getApi().getActivityWithChildren(activityId).then((activities)=>{
                setChildActivities(activities.filter((a:ActivityBase)=>a.id!==activityId))
                setActivity(activities.find((a:ActivityBase)=>a.id===activityId))

            })
        }
    }
   },[activityId,activity])
   
    return (
        <>
          {innerActivity&&<ActivityComponent activity={innerActivity} allActivities={childActivities} onChange={setActivity}/>}
        </>    
    )

    
}

