import { useState, useEffect, useRef, useMemo } from "react";
import { Box, Text, Tip } from "grommet";
import Showdown from "showdown";
import "./formatedPrompt.css"
import Button from "../Button/button";
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues";
import { LlmPlayground } from "../LlmPlayground/LlmPlayground";
import Popup from "../Popup/popup";
import {ChatMessage, ChatMessageTemplate} from "../../models/dataModel"
import { BsInfo } from "react-icons/bs";
import { MdInfo } from "react-icons/md";
import {replaceMatches, wrapParamsInSpan} from "../../utils/helpers"
import { ChatMessageBox } from "../ChatMessageBox/chatMessageBox";
import ChatPromptTemplate from "../PromptTemplatesList/chatPromptTemplate";
import InfoToast from "../InfoToast.tsx/infoToast";
import { TbMathFunction } from "react-icons/tb";
import { SiAzurefunctions } from "react-icons/si";
import FunctionCallArguments from "../FunctionsCalls/functionCallArguments";
import FunctionCallBox from "../FunctionsCalls/functionCallBox";
import FormatFunctionsNative from "../FunctionsCalls/formatFunctionsNative";
import FunctionsBox from "../FunctionsCalls/functionsBox";

const FormattedPrompt = ({ text, template, params, generated, metadata }: {
    text: string|ChatMessage[],
    template: {prompt_template:string|(ChatMessage|ChatMessageTemplate|string)[], prompt_input_params:string[]},
    params: { [key: string]: string },
    generated: string,
    metadata:any
}
) => {



    // const res = wrapParamsInSpan(
    //     "this is a long long  might not seem as they are ...",
    //     "this is a long {param}  might not seem as they are {no}", {param:"long", template1:"TEMPLATE", template2:"TEMPLATE"} )
    const [subTexts, setSubTexts] = useState<any>()
    const isChat = useMemo(()=>metadata.name=="ChatOpenAI" || Array.isArray(template?.prompt_template) || Array.isArray(text),  [metadata, template])
    const [messages, setMessages] = useState<any>()
    const encodeStr = (rawStr) => rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
    });

    const [visualizationType, setVisualizationType] = useState("interpolated")
    useEffect(() => {

        
        let spans: any = typeof(text)==="string" ?[text] : text.map((message, i)=>(<ChatMessageBox key={i} chatMessage={message}/> ))
        try {
            if (!isChat && template && template.prompt_template){

                if (visualizationType == "interpolated" ) {
                    spans = wrapParamsInSpan(text, template.prompt_template, params)
                }
                else if (params && template.prompt_template) {
                    
                    let regexPattern = Object.keys(params).map(param => {
                        return `(?<!\\{)\\{\\s*${param}\\s*\\}(?!\\})`
                    }).join("|")
                    let regex = new RegExp(regexPattern, "g")
                    spans =replaceMatches(template.prompt_template, regex, (match)=><span className="prompt-parameter">{match[0]}</span>)
                }
            }
        }
        catch (error) {
            console.log(error)
        }

        if (generated) {
            spans.push(<span className="prompt-generated"><mark>{generated}</mark></span>)
        }

        setSubTexts(spans)


    }, [text, visualizationType])

    const [popup, setPopup] = useState<any>()

    function openPlayground() {
        

            setPopup(
                <Popup caption="LLM playground" onClose={() => setPopup(null)} >
                <LlmPlayground sourcePrompt={text}
                 promptTemplate={template?.prompt_template} 
                 params={params} 
                 llm_params={metadata?.llm} 
                functions={metadata?.invocation_params?.functions}
                function_call={metadata?.invocation_params?.function_call}
                generated={generated} generated_function_call={metadata?.function_call}/>
            </Popup>
        )
        
    }
    return (
        <Box flex="shrink"  >
            
            {popup}
            <Box direction="row" flex={false} justify="between" style={{position:"sticky", top:0}} background="white" pad="5px">
                <Box direction="row" flex={false} justify="end" align="center" gap="3px">
                    <Text size="small">Show as: </Text>
                    <Button background={visualizationType == "interpolated" ? "brand" : undefined} text="Interpolated" onClick={() => setVisualizationType("interpolated")} />
                     <Button disabled={!template } background={visualizationType == "decomposed" ? "brand" : undefined} text="Template and parameters" onClick={() => setVisualizationType("decomposed")} />
                </Box>
                 <Button  background="brand" text="Open on playground" onClick={() => openPlayground()} />
                   
                
            </Box>

            {!template && <InfoToast type="info" >
                <p>

                It seams that we not able to extract all information. Please check if you're using the latest PromptWatch client version. 
                </p>
                <p>
                If you are using async handlers in LangChain please see the <a href="https://docs.promptwatch.io/docs/async_and_streaming" target="_blank">documentation</a> for more information.
                </p>
            </InfoToast>}
            <Box>
                {metadata &&  Object.keys(metadata).length>0 && <Button
                alignSelf="end" 
                        border={false}
                        
                        icon={<BsInfo size="20px"/>} size="10px" pad="0px"
                        onClick={()=>{
                            setPopup(<Popup caption="Additional info" onClose={()=>setPopup(null)}>
                                <Box pad="small">
                                    <Text>Metadata</Text>
                                <KeyValuesComponent object={metadata} size="small"/>
                                </Box>
                            </Popup>)
                        }}
                    />}

                    {metadata?.invocation_params?.functions?.length &&(
                        <FunctionsBox functions={metadata?.invocation_params?.functions} function_call={metadata?.invocation_params?.function_call?.name||metadata?.invocation_params?.function_call}/>
                       
                    )}
                
                <Box border background="light-1" round="5px" pad="5px"  flex={false}>
                    
                    <Text size="small" wordBreak="break-word" textAlign="start" style={{ whiteSpace: 'pre-wrap' }} className="prompt">

                    {visualizationType == "interpolated"?(
                        Array.isArray(text)?(
                            <Box>
                                {
                                text.map((message, index)=>{
                                return (
                                        <div key={index} className="prompt-message">
                                            
                                            <ChatMessageBox 
                                                chatMessage={message} 
                                                chatMessageTemplate={Array.isArray(template?.prompt_template)?template.prompt_template[index] as any:undefined}
                                                /> 
                                        </div>
                                        )
                                })
                                }
                             <ChatMessageBox isGenerated chatMessage={{role:"assistant", text:generated}}/> 
                            </Box>
                        ):(
                            <div className="prompt-content">
                                    {/* {subTexts} */}
                                sss
                                </div>
                        )
                    ):(
                        Array.isArray(template?.prompt_template)?(
                            <Box>

                             <div className="prompt-message">
                                     
                                     <ChatPromptTemplate items={template?.prompt_template as any}/>

                                </div>
                                <ChatMessageBox isGenerated chatMessage={{role:"assistant", text:generated}}/> 
                            </Box>
                            
                        ):(
                            <div className="prompt-content">
                                    {template.prompt_template}
                                
                                </div>
                        )
                    )}

                        {/* {!(Array.isArray(text) && Array.isArray(template.prompt_template))?(

                        
                            visualizationType == "interpolated" ? (subTexts) : (
                                <div className="prompt-content">
                                    {subTexts}
                                
                                </div>
                            )
                        ):(
                            <>
                           { visualizationType == "interpolated" ?(
                            text.map((message, index)=>{
                                return <div key={index} className="prompt-message">
                                     
                                    <ChatMessageBox chatMessage={message}/> 
                                </div>
                            })
                            ):(
                                <ChatPromptTemplate items={template?.prompt_template as any}/>
                            )}
                            <ChatMessageBox isGenerated chatMessage={{role:"assistant", text:generated}}/> 
                            </>
                        )
                        } */}

                    </Text>
                    {metadata?.function_call&&(
                                        <FunctionCallBox function_call={metadata?.function_call} />
                                    )}
                </Box>
                <Box margin="20px 0px">
                    {
                        visualizationType != "interpolated" && (
                            <Box>
                                <Text size="large" weight={900}>Prompt params:</Text>
                                <KeyValuesComponent object={params} size="12px" />
                            </Box>
                        )
                    }
                </Box>   
            </Box>
        </Box>
    )

}

export { FormattedPrompt  }