
import { Box,   Layer,  Tip, Text } from "grommet"
import { useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Button from "../Button/button";
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { docco  as style  } from 'react-syntax-highlighter/dist/esm/styles/hljs'


export  default function CodeBlock({caption, children, language="python", highlightLines, ...rest}:{
    caption?:string
    children:any
   language?:string,
   highlightLines?:number[]
   [key:string]:any
}){
  
    const highlighter=(lineNumber, e)=>{
    
        if (highlightLines.includes(lineNumber)) {
            let style = { display: 'block', backgroundColor : '#00491f' };
            return { style };
        } 
        return { };
    }

    const [info, setInfo] = useState<string>()

    const copyButton = (
        <Box direction="row" align="center" gap="5px" alignSelf="end">
                    <Text>{info}</Text>
                    <Button  pad="4px" margin="4px 0px" border icon={<FiCopy/>} onClick={()=>{
                        setInfo("Copied!")
                        navigator.clipboard.writeText(children)
                        setTimeout(()=>setInfo(""),1000)
                    }
                }/>
        </Box>
    )

    return (
        <Box background="#2b2b2be5" round="small" pad="0px 4px" {...rest}>
            {caption && <Box align="center" direction="row" justify="between">
                <Box margin="2px 8px 0px">
                    <Text size="15px" weight={900}>{caption}</Text>
                </Box>
                {copyButton}
            </Box>}
            <Box >
            {!caption && (
                  <Box direction="row" align="center" gap="5px" alignSelf="end" margin="0px 0px -25px" style={{zIndex:5}}>
                  <Text>{info}</Text>
                  <Button  pad="4px" margin="4px 0px" border icon={<FiCopy/>} onClick={()=>{
                      setInfo("Copied!")
                      navigator.clipboard.writeText(children)
                      setTimeout(()=>setInfo(""),1000)
                  }
              }/>
      </Box>
            )}
            <SyntaxHighlighter language={language} style={style} customStyle={{padding:"0px", margin:"0px", backgroundColor:"transparent"}}
            
            wrapLines={true}
            showLineNumbers={true}
            lineNumberStyle={(props) => {
                return {
                    display: 'none',
                };
            }}
            lineProps={highlightLines? highlighter:undefined}
               >
            {children}

            </SyntaxHighlighter>
            </Box>
        </Box>
    )
    
}