import { Box, TextInput, Text,  DropButton, Tip } from 'grommet'

import { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import {HiSearch} from "react-icons/hi"
import {MdClose, MdSearch} from "react-icons/md"
import debounce from 'lodash.debounce';

import "./searchBox.css"
import { GrRefresh } from 'react-icons/gr';
import Button from '../Button/button';
import { TbRefresh } from 'react-icons/tb';

const SearchTextInput =({value, onTextChange, placeholder, onEnter, ...rest}:any)=>{
    
    const [lines,setLines] = useState(1)
    function handleChange(event) {
        onTextChange(event.target.value);
        setLines(event.target.value.split("\n").length)
    }
  
    function handleKeyDown(event) {
      if (event.key === "Enter"){
        if(event.shiftKey) {
            // onTextChange(value + "\n");
            // setLines(lines+1)
            // event.preventDefault();
        }
        else{
            event.preventDefault();
            onEnter()
        }
        }
    }
  
    return (
      <input type="text"
        value={value}
        
        autoComplete='false'
        style={{width:"100%", height:(lines*1)+"em",padding:"15px 0px"}}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        
        {...rest}
      />
    );
  }

export default function SearchBox({ value,placeholder=undefined, onChange,onApplySearch, onFocus, withButton }: {
   value?:string,
   placeholder?:string
   onChange?:(val:string)=>any
   onApplySearch?:(val:string, force?:boolean)=>any
   onFocus?:(hasFocus:boolean)=>any
   withButton?:boolean

}) {

  const [valueInternal, setValueInternal] = useState<string>();
  useEffect(()=>{
    if (value!=valueInternal){
      setValueInternal(value)
    }
  },[value])
  
  const [searchQueryUsed,setSearchQueryUsed]=useState<string>()
  const _onApplySearch = (val:string, force?:boolean)=>{
    setSearchQueryUsed(val)  
    onApplySearch&&onApplySearch(val)
  }

  useEffect(()=>{
    debouncedChangeCallback(valueInternal)
  },[valueInternal])

  const onChangeInternal = (val:string)=>{
    onChange&&onChange(val)
  }
  

  const debouncedChangeCallback = useCallback(
    debounce(onChangeInternal, 300)
  , []);

//   useEffect(()=>{
//     if (value!=valueInternal){
//         setValueInternal(value)
//         if (textInputRef.current){
//             (textInputRef.current as any).focus()
//             setTimeout(()=>{

//                 if (value){
//                     (textInputRef.current as any).setSelectionRange(0,value.length-1)
//                 }
//             },500)
//         }
//     }
// },[value])

//   const textInputRef = useRef()
  



    return (
        <Box  flex={false} direction='row' gap="5px">
        <Box
            round="8px"
            flex="grow"
            wrap
            border
            background="white"
            // elevation='small'
            // background={{ color: "light-1", opacity: "strong" }}
            direction="row"
            align="center"
            //pad={{ horizontal: "medium", vertical: "xxsmall" }}
            pad="0px 1px 0px 8px"
            //height="35px"
            gap="2px"
            
        >
            <Box >
             
                <HiSearch/>
                
            </Box>
                
            <Box direction="row" align='center'  flex="grow">
                 
                    <SearchTextInput id='searchInput'  placeholder={placeholder||"Search"} type="search" height="auto" size="small"
                        className='searchInput' 
                        
                        onFocus={()=>{
                            onFocus&&onFocus(true)
                        }} 
                       // onBlur={(e)=>e.relatedTarget?.tagName!=="BUTTON" && setHasFocus(false)} 
                        //focusIndicator={false}
                        
                        onEnter={()=> {
                                _onApplySearch&& _onApplySearch(valueInternal)
                        }}
                        value={valueInternal||""}
                        onTextChange={value => {
                            setValueInternal(value as string||"") 
                            
                            }
                        } 
                        //onBlur={onLeave}
                        //onSuggestionSelect={e => setInputText(e.suggestion.value + ":")}
                        //suggestions={suggestions?.map((s:any)=>({label:(<Box  direction='row' pad="2px 5px"><Badge icon={<Filter size="small"/>} size="xsmall" value={s+": ..."}/></Box>),value:s}))} 
                    />

                    {valueInternal?( 
                    <Button border={false} pad="5px" onClick={()=>{
                        setValueInternal("")
                        onChange&&onChange("")
                        _onApplySearch("")
                    }}>
                        <MdClose/>
                    </Button>
                    ):(
                        <></>
                    )}
                    
            </Box>
               
            
        </Box>
                 {withButton &&   <Button pad="5px 8px"   icon={valueInternal==searchQueryUsed?<TbRefresh color="white"  size="18px"/>:<MdSearch color="white"  size="18px"/>} onClick={()=>_onApplySearch(searchQueryUsed,true)}/>}
       
        </Box>



    );
}


export {SearchBox};