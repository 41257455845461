


import { Box, Button,  Collapsible,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Log, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill} from "react-icons/bs"
import { useState } from "react"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"



export  default function LogComponent({activity, allActivities, onClick}:{
    activity:Log
    allActivities?:ActivityBase[]
    onClick?:()=>any
}){
  
    const firstNewLineIndex=activity?.text.replace(" ","--")?.trim().indexOf("\n")||(activity?.text?.length>500?500:null)
    const [open, setOpen]=useState(false)

    return (
        <Box flex="grow"  round="5px" background="white" width="100%" pad="0px 8px" justify="between" onClick={onClick ?()=> onClick():undefined} focusIndicator={false}>
           { firstNewLineIndex>0?(
            <>
            {!open && <Box align="center"  direction="row"  justify="start" focusIndicator={false} onClick={()=>setOpen(true)} pad="0px 5px"><MdKeyboardArrowDown size="25px"/> <Text size="12px">{activity.text.trim().substring(0,firstNewLineIndex)}</Text></Box>}

                <Collapsible open={open}>
                    <Box direction="row">
                        <Box direction="row" alignSelf="start" focusIndicator={false}  onClick={()=>setOpen(false)}><MdKeyboardArrowUp size="25px"/></Box>
                        <Box  align="center" pad="5px">

                            <Text color="gray" size="7px">
                            
                                {<HighlightableText text={activity.text}/> }
                            </Text>
                            <Box direction="row" align="center" focusIndicator={false} round border onClick={()=>setOpen(false)} pad="2px 8px"><MdKeyboardArrowUp/> <Text size="10px">Collapse</Text></Box>
                        </Box>
                    </Box>  
                </Collapsible>
            </>
            ):(
                    <Text color="gray" size="7px">
                        <Text size="10px">{activity.text.trim()}</Text>
                    </Text>
            )}
           
           
        </Box>    
    )

    
}

