


import { Box,  Layer,  Tip, Text, Tab, Tabs, Spinner } from "grommet"
import { PromptTemplateVersion, TestCaseResult } from "../../models/dataModel"

import PromptTestRunCard from "./promptTestRunCard"
import { useMemo, useState } from "react"
import PromptRuns from "../PromptRuns/promptRuns"
import {replaceMatches, wrapParamsInSpan} from "../../utils/helpers"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import Badge from "../Badge/badge"
import { MdError } from "react-icons/md"
import FeedbackComponent from "../FeedbackComponent/feedbackComponent"
import Button from "../Button/button"
import ActivityHeader from "../Activities/ActivityHeader"
import PromptTemplateBadge from "../PromptTemplateBadge/promptTemplateBadge"
import { getApi } from "../../apiService"
import { compareTexts,formatDiffs } from "../../utils/helpers"
import {TbArrowsLeftRight   } from "react-icons/tb"
import { FaLightbulb, FaUser } from "react-icons/fa"
import { HiOutlineLightBulb } from "react-icons/hi"
import Popup from "../Popup/popup"
import ActivityDetail from "../ActivityPopup/activityDetail"
import TestCaseDetail from "./testCaseDetail"
import { useNavigate } from "react-router-dom"
import SessionDetail from "../SessionDetail/sessionDetail"
import { BsChatLeftQuote, BsChatSquareFill, BsChatSquareQuote, BsFillChatLeftQuoteFill } from "react-icons/bs"
import { BiTargetLock } from "react-icons/bi"

export  default function TestResultDetail({testCaseResult, test_name, onUpdateFeedback}:{
    testCaseResult:TestCaseResult
    test_name:string
    onUpdateFeedback:(val:number|null)=>any
}){
  
 const [popup, setPopup] = useState<any>()
 const [openInputs, setOpenInputs] = useState<boolean>()
 const navigate = useNavigate()
   
   
    return (
        <Box className="prompt-test-box run" flex={false} >
            {popup}
        <Box align="start" margin="10px">
        
            {testCaseResult.test_case.inputs && testCaseResult.test_case.inputs.persona && testCaseResult.test_case.inputs.topic && testCaseResult.conversation_session_id && testCaseResult.evaluation_method=="simulation-autoeval" ? (
                <>
                <Text weight={900} color="brand">Simulated conversation test</Text>
                <Box align="start" gap="5px" margin="10px 0px">
                    <Box align="start" gap="5px">
                        <Text weight={900}><FaUser/> Persona</Text>
                        <Box margin="0px 15px" align="start" gap="5px">
                            <Text textAlign="start">{testCaseResult.test_case.inputs.persona.name} ({testCaseResult.test_case.inputs.persona.role})</Text>
                            
                            <Text size="xs"  textAlign="start">{testCaseResult.test_case.inputs.persona.description}</Text>
                        </Box>

                    </Box>
                    <Box>
                    <Box align="start" gap="5px" margin="10px 0px">
                        <Text weight={900}><BsChatSquareFill/> Topic</Text>
                        
                        <Box margin="0px 15px" align="start" gap="5px">
                            <Text textAlign="start">{testCaseResult.test_case.inputs.topic.name} </Text>
                            <Text weight={900}><BiTargetLock/> Goal of the conversation</Text>
                            <Text textAlign="start">{testCaseResult.test_case.inputs.topic.conversation_goal} </Text>
                            <Text weight={900}><BsChatLeftQuote/> Opening line</Text>
                            <Text size="xs"  textAlign="start">{testCaseResult.test_case.inputs.topic.opening_line}</Text>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                </>
            ):(
                <Box align="start" margin="10px">
                <Text weight={900} color="brand">Test inputs</Text>
                <Box  className={((openInputs)?" open":" collapsed fadeout-parent ")} onClick={openInputs?undefined:()=>setOpenInputs(!openInputs)} flex={false}>
                <KeyValuesComponent object={testCaseResult.test_case.inputs} />
                {<div className={openInputs?"":"fadeout"}>
                <Button text={openInputs?"Collapse":"Show more"} background="white" border onClick={()=>setOpenInputs(!openInputs)}/>
                </div>}
                </Box>
                </Box>
                )}
                
            
            </Box>

        <Box margin="5px" border round="5px" pad="5px"  >
            {testCaseResult.llm_prompt &&
                <ActivityHeader activity={testCaseResult.llm_prompt} />
            }
            {testCaseResult.llm_prompt && <Box direction="row" gap="5px" align="center">
                <Text weight={900} color="brand">Prompt template</Text>
                <PromptTemplateBadge promptTemplate={(testCaseResult.llm_prompt?.prompt_template)} />
            </Box>}
            <Box align="start" alignSelf="center" width="100%">
                <Box direction="row" justify="between" width="100%">
                   
                    <Text weight={900} color="brand">Generated result</Text>
                    { 
                        ((!testCaseResult.llm_prompt && testCaseResult.activity_id ) || (testCaseResult.conversation_session_id))&&  
                        <Box direction="row" gap="5px" align="center" alignContent="end">
                            <Button  text="Explore details" onClick={()=>setPopup(<Popup onClose={()=>setPopup(null)}>
                           { testCaseResult.activity_id ?
                                <ActivityDetail activityId={testCaseResult.activity_id}/>
                                :
                                <SessionDetail sessionId={testCaseResult.conversation_session_id}/>}
                            </Popup>)}/>     
                        </Box>
                    }
                </Box>
                <ResultBox result={testCaseResult.generated_result} />
                

                {testCaseResult.activity_id ? <FeedbackComponent value={testCaseResult.feedback_label} onClick={onUpdateFeedback}/>:(
                   <></>
                )}
                
                

            </Box>
        </Box>
        
        <Box justify="between" margin="10px 10px 5px" direction="row" alignSelf="stretch" >
            <Text weight={900} color="brand">Expected results</Text>
            {testCaseResult?.test_case?.id && <Button  primary pad="0px 5px" text="Edit test case" onClick={()=>{
                  setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt">
                    <Box>
                        

                            <Button margin="0px 20px" primary alignSelf="end" text="Open test definition" onClick={()=>navigate("/unit-tests?test_name="+encodeURIComponent(test_name))}/>
                        
                        <TestCaseDetail testCaseId={testCaseResult.test_case.id}/>
                    </Box>
              </Popup>)
            }}/>}
        </Box>
        <Box align="stretch" margin="0px 10px" gap="5px">
            {testCaseResult.test_case.outputs.map((labeled_output, i) => (
                <Box  border round="5px" >
                <Box direction="row" flex="grow"justify="between">
                    <Box  align="start" pad="5px">
                        <ResultBox result={labeled_output.value} />
                    </Box>
                    <Box flex={false} >

                        <FeedbackComponent value={labeled_output.label} />
                    </Box>
                </Box>
                {typeof(testCaseResult.generated_result)===typeof(labeled_output.value) && testCaseResult.generated_result &&  labeled_output.value  && (
                <Badge alignSelf="start" round="5px"
                background={typeof(testCaseResult.generated_result)===typeof(labeled_output.value)?undefined:"rgb(255,230,200)"}
                tip={()=>(
                    <div>
                        {typeof(testCaseResult.generated_result)==="string" &&  typeof(labeled_output.value)==="string" ?(
                            formatDiffs(compareTexts( labeled_output.value,testCaseResult.generated_result))
                        ):(
                            (testCaseResult.generated_result &&  labeled_output.value ) && (
                                formatDiffs(compareTexts(JSON.stringify(labeled_output.value,null,2), JSON.stringify(testCaseResult.generated_result, null,2)))
                            )
                        )
                        }
                    </div>
                )
                } >
                    <TbArrowsLeftRight/>
                    <Text>Compare </Text>
                    
                </Badge>)}
                </Box>
            ))
            }
        </Box>


        {testCaseResult.error_description && (
            <Box direction="row" align="start" justify="start" width="100%" margin="0px 10px" gap="5px" flex={false}>
                <Box flex={false} >
                    <MdError color="red" size="15px" />
                </Box>
                <Box flex={false} width="90%">
                
                    <Text weight={900} color="red" textAlign="start">{testCaseResult.error_description}
                    </Text>
                </Box>
            </Box>
        )}
        <Box >
            <Box direction="row"  justify="between">
                {testCaseResult?.reasoning?(
                    <Box direction="row" align="center" margin="5px 10px"  flex="shrink" >
                        {testCaseResult?.reasoning && <Box><Text weight={900} textAlign="start" color="dark-1" ><FaLightbulb color="orange"/> Evaluation reasoning:</Text>
                       
                        </Box>}
                    </Box>
                ):(<div></div>)}
                 
                <Box direction="row" wrap justify="end" flex={false} align="start">
                <Badge textProps={{ weight: 900 }} text={"Score: " + Math.round(100 * testCaseResult.score) + " %"}
                    tip={
                        <Box>
                            <KeyValuesComponent object={{
                                "Evaluation method": testCaseResult.evaluation_method,
                                "Evaluation score": testCaseResult.score,
                                ...(testCaseResult.evaluation_metadata ? { "Evaluation metadata": testCaseResult.evaluation_metadata } : {})
                            }} />
                        </Box>
                    } />
                <Badge textProps={{ weight: 900 }} text={testCaseResult.passed ? "Passed" : "Failed"} border={false} background={!testCaseResult.passed ? "status-critical" : "accent-1"} />
                </Box>
            </Box>
            {testCaseResult?.reasoning &&
                 <Box pad="0px 25px">
                 <Text textAlign="start">{testCaseResult?.reasoning}</Text>
             </Box>
            }
        </Box>

      

        {/* ...
        <KeyValuesComponent object={testCaseResult} /> */}
    </Box>
        )
    
}

const ResultBox = ({ result }: { result: string | object }) => (
    typeof (result) === "string" ? (
        <Text textAlign="start" weight={900}>{result}</Text>
    ) : (
        <KeyValuesComponent object={result} />
    )
)