
import { Box, ResponsiveContext, Text } from "grommet";
import { useContext, useEffect, useMemo, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session } from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import { FiCornerRightDown } from "react-icons/fi"

import Moment from 'react-moment';
import SessionsFilter from "../components/StatisticsWindow/sessionsFilter";
import ShrinkingHeader from "../components/ShrinkingHeader/shrinkingHeader";
import ShrinkingHeaderContainer from "../components/ShrinkingHeader/shrinkingHeader";
import Sessions from "../components/Sessions/sessions";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai"
import SessionDetail from "../components/SessionDetail/sessionDetail";
import { SessionsIcon } from "../components/CommonIcons/commonIcons";
import { FaExpandAlt } from "react-icons/fa";
import Button from "../components/Button/button";


export default function MaterDetailPageWrapper({ pageHeading, master, children, supportedModes, detailOpen, onDetailClose, detailPlaceholder }: {
  pageHeading: string
  master: any
  children: any
  supportedModes?:undefined|("minimized" | "normal" | "fill")[]
  detailOpen: boolean
  detailPlaceholder?: any
  onDetailClose?:()=>any
}) {

  const allowedMinimized =  supportedModes == undefined ||(supportedModes?.includes("minimized")) 
  const allowedFill =  supportedModes == undefined ||(supportedModes?.includes("fill")) 
  const size=useContext(ResponsiveContext)
  const [masterSidebarSize, setMasterSidebarSize] = useState<"minimized" | "normal" | "fill">()
  useEffect(() => {
    if (detailOpen||detailPlaceholder ) {
      if (size!="small" )
        setMasterSidebarSize("normal")
      else
        setMasterSidebarSize("minimized")

    }
    else {
      setMasterSidebarSize("fill")
    }
  }, [detailOpen])

  useEffect(() => {
    if (size=="small" && masterSidebarSize==="normal") {
      setMasterSidebarSize("minimized")
    
    }
    
  }, [size, masterSidebarSize])

  
  return (

    <>

      <Box direction="row" justify="between" fill >

        {masterSidebarSize == "minimized" && (
          <Box flex={false} pad="5px 0px" align="center" gap="10px" className="second-level-sidebar" background="dark-2">
            <Button
              border={false}
              icon={<AiOutlineDoubleRight size="15px" />}
              onClick={() =>{
                  if( size=="small"){
                    setMasterSidebarSize("fill")
                    onDetailClose&& onDetailClose()
                   }
                   else{
                    setMasterSidebarSize("normal")
                   }
                  }}
            />

          </Box>
        ) }




          
          <Box background="dark-2" className="second-level-sidebar" 

             flex={{grow:1,shrink:1}} basis="1/3"
             style={{minWidth:"350px", maxWidth:(masterSidebarSize!="fill"?"500px":"100%"), display:(masterSidebarSize=="minimized")?"none":undefined}}
          >
            <Box direction="row" justify="between" align="center" pad="5px 0px 0px 5px" height="40px">
              <Box direction="row" pad="5px">
             
                <Text size="xlarge" weight={900} color="accent-1"> {pageHeading} </Text>
              </Box>
              {masterSidebarSize != "fill" && allowedMinimized &&  <Box direction="row" >
                {masterSidebarSize == "normal"   && <Button
                  border={false}
                  alignSelf="end"
                  pad="2px"
                  icon={<AiOutlineDoubleLeft size="15px" />}
                  onClick={() => setMasterSidebarSize("minimized")}
                />}
                {masterSidebarSize == "normal"&& allowedFill && <Button
                  border={false}
                  alignSelf="end"
                  icon={<FaExpandAlt size="15px" />}
                  onClick={() => {
                    setMasterSidebarSize("fill")
                    onDetailClose && onDetailClose()
                  }}
                />}
              </Box>}
            </Box>
            <Box
              background="dark-1" 
              
              fill={masterSidebarSize == "fill"}
              
              >
              {master}
            </Box>
          </Box>
        




        { masterSidebarSize != "fill" && (

          <Box className="page-detail"  
          flex={{grow:1,shrink:1}} basis="2/3"
          >
           

            {(!!children &&detailOpen ) ?children:
            
              detailPlaceholder

            
            }
            
            
            
          </Box>
        )}
      </Box>

    </>
  )
}