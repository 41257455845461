


import { Box, Button,  Layer,  Tip, Text, Tab, Tabs, Spinner } from "grommet"
import { ChatMessage, ChatMessageTemplate, PromptTemplateVersion } from "../../models/dataModel"
import { ChatMessageBox } from "../ChatMessageBox/chatMessageBox"
import ChatPromptTemplate from "./chatPromptTemplate"
import { useMemo, useState } from "react"
import PromptRuns from "../PromptRuns/promptRuns"
import {replaceMatches, wrapParamsInSpan} from "../../utils/helpers"


export  default function PromptTemplateDetail({templateVersion}:{
    templateVersion:PromptTemplateVersion
}){
  
    const [activeTab, setActiveTab] = useState(0)
    const [lastRuns, setLastRuns] = useState<any>();
    
    const setTab = (index: number) =>{
        if (index==1 && !lastRuns){
            setLastRuns(<PromptRuns prompt_template_version_id={templateVersion.id}/>)
        }
         setActiveTab(index)
    }
    const formattedPromptText= useMemo(()=>{
        try{
            if (typeof(templateVersion.prompt_template)=== "string"){

                let regexPattern =templateVersion.prompt_input_params.map(param => {
                    return `(?<!\\{)\\{\\s*${param}\\s*\\}(?!\\})`
                }).join("|")
                let regex = new RegExp(regexPattern, "g")
                return replaceMatches(templateVersion.prompt_template, regex, (match)=><span className="prompt-parameter">{match[0]}</span>)
            }
        }catch(error){
            console.warn(error)
            return null
        }
    },[templateVersion])

    return (
        <Box flex={false}>
            <Tabs activeIndex={activeTab} onActive={setTab} alignControls="start">
            <Tab title="Template">

           {typeof(templateVersion.prompt_template)==="string"?(
            <Box border background="light-1" round="5px" pad="5px"  flex={false}>
                    
                <Text size="small" wordBreak="break-word" textAlign="start" style={{ whiteSpace: 'pre-wrap' }} className="prompt">
                {formattedPromptText||templateVersion.prompt_template}
                </Text>
            </Box>

               ):(
                   Array.isArray(templateVersion.prompt_template)?(
                       <ChatPromptTemplate items={templateVersion.prompt_template}/>
                       ):(
                           "unsupported prompt type"
                           )
                           )}
            </Tab>
            <Tab title="Last executions">
                {lastRuns}
            </Tab>
            </Tabs>
        </Box>
        )
    
}