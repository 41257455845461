
import { Box, Grommet } from 'grommet';
import './App.css';
import PageRouter from './PageRouter';
import AppSidebar from './components/AppSidebar/appSidebar';
import { AppContextProvider } from './appContext';

const theme={
  global:{
    font:{size:"12px"},
    input:{
      padding:"5px",
      font:{size:"14px"}
    },
    
    colors:{
      focus:"#ffffff",
      brand:"#cb17d6",
      "accent-1":"#6FFFB0",
      "dark-2":"#50555e",
      "dark-3":"#676c77",
      "dark-4":"#90949b",
      

    }
  },
  button:{
    border:{radius:"5px"},
    padding:{horizontal:"6px"},
    gap:"xxsmall",
    
  },
  tab: {
    color: 'text-strong',
    active: {
      color: 'brand',
    },
    border: {
      side: 'bottom',
      color: 'light-4',
      active: {
        color: 'brand',
      },
      disabled: {
        color: 'black',
      },
      hover: {
        color: 'border',
      },
    },
    disabled: {
      color: 'text-weak',
    },
    
  },
  tip:{content:{background:"white"}},
  text:{
    xsmall: {
      size: '8px',
      height: '10px',
    },
    small: {
      size: '10px',
      height: '12px',
    },
    medium: {
      size: '12px',
      height: '14px',
    },
    large: {
      size: '14px',
      height: '16px',
    },
    xlarge: {
      size: '18px',
      height: '20px',
    },
    xxlarge: {
      size: '26px',
      height: '30px',
    },
  },
 
  accordion:{heading:{level:4}},

  checkBox: {
    border: {
      color: {
        light: 'dark-4',
      },
    },
    // color: {
    //   light: 'toggle-knob',
    // },
    check: {
      radius: '0px',
    },
    // hover: {
    //   border: {
    //     color: undefined,
    //   },
    // },
    toggle: {
      // background: { light: 'pink' },
      color: {
        light: 'dark-4',
      },
      size: '28px',
      knob: {
        extend: `
          top: -2px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      // extend: ({ checked }) => `
      //   height: 14px;
      //   ${checked && checkboxCheckStyle}
      // `,
    },
    gap: '2px',
    size: '15px',
  },
}

function App() {
  return (
    <Box className="App"  height="100vh" width="100vw" overflow="hidden">
       <Grommet theme={theme}>
        <AppContextProvider>
        
        

        <Box fill>

          <PageRouter/>
        </Box>
        
        </AppContextProvider>
       </Grommet>
    </Box>
  );
}

export default App;
