import { TextInput } from 'grommet';
import  { useState, useRef, useEffect } from 'react';

export const AutosizeTextInput = ({value, ...rest}) => {
  const [content, setContent] = useState('');
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    if (span?.current){
      let _width=(span.current as any).offsetWidth
      if(_width){
        setWidth(_width*0.9);
      }
        (span?.current as any).style.display = "none";
    }
  }, [content]);

  const changeHandler = evt => {
    setContent(evt.target.value);
  };

  return (
    <div>
      <span  ref={span}>{value}</span>
      <TextInput {...rest} value={value}   type="text" style={{ width, maxWidth:"80vw" }} autoFocus onChange={changeHandler} />
    </div>
  );
};