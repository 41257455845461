


import { Box,   Spinner, Text, TextArea, TextInput, Tip } from "grommet"
import { Action, ActivityBase, LlmPrompt, Log, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillChatLeftDotsFill, BsFillQuestionSquareFill, BsInfo, BsPatchQuestion} from "react-icons/bs"
import { useEffect, useMemo, useState } from "react"
import Button from "../Button/button"
import Popup from "../Popup/popup"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"

import { ActionCostsBadge } from "../ActionCostsBadge/actionCostsBadge"
import PromptTemplateBadge from "../PromptTemplateBadge/promptTemplateBadge"
import Badge from "../Badge/badge"
import FunctionCallBox from "../FunctionsCalls/functionCallBox"



export  default function LlmPromptComponent({ activity, allActivities, onClick, showInputs=false}:{
    activity:LlmPrompt
    allActivities?:ActivityBase[]
    onClick?:()=>any
    showInputs?:boolean
}){
  
    
    const [popup, setPopup] = useState<any>()


    return (
        <Box flex="grow"  pad="5px" round="5px" background="white" justify="between" onClick={onClick ?()=> onClick():undefined}>
            {/* <Box direction="row" justify="between">
                <Box direction="row" gap="xsmall" >
                    <BsPatchQuestion/><Text size="xsmall" color="gray">internal thought</Text>
                </Box>
                <Box direction="row">
                <ActionCostsBadge activity={activity}/>
                <Buttton 
                icon={<BsFillChatLeftDotsFill size="15px"/>} size="10px" text="Show prompt"
                onClick={()=>{
                    setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt">
                        <Box pad="small">
                            <FormatedPrompt text={activity.prompt} template={activity.prompt_template} params={activity.prompt_input_params} generated={activity.though}/>
                        </Box>
                    </Popup>)
                }}
                />
                <Buttton 
                icon={<BsInfo size="20px"/>} size="10px" pad="0px"
                onClick={()=>{
                    setPopup(<Popup caption="Additional info" onClose={()=>setPopup(null)}>
                        <Box pad="small">
                            <Text>Metadata</Text>
                        <KeyValuesComponent object={activity.metadata} size="small"/>
                        </Box>
                    </Popup>)
                }}
                />
                </Box>
            </Box> */}
            {popup}
            {showInputs&&(
                <>
                <Box>
                    <KeyValuesComponent object={activity.prompt_input_values} multiline={false}/>
                </Box>
                <Box align="start" margin="10px 0px 0px">

                <Text weight={900}>{activity.caption}</Text>
                </Box>
                </>
            )}
              <Box direction="row" gap="5px" align="center">
                <Text weight={900} color="brand">Prompt template</Text>
                
                <PromptTemplateBadge promptTemplate={(activity?.prompt_template as any)} />
                

            </Box>
            <Box align="start">
                <Text weight={900} color="brand">Generated:</Text>
            </Box>
            

            <Text textAlign="start" alignSelf="start" className="generated"   ><mark >{activity.generated}</mark></Text>
            {activity.metadata?.function_call&&(
                                        <FunctionCallBox function_call={activity.metadata.function_call} />
                                    )}
           
   
        </Box>    
    )

    
}

