
import { Box, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../appContext";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import "./widgets.css"



export  default function ChartWidget({seriesName, type, data , isCategorical, height, width, color}:
    {
            seriesName:string, 
            type:string, 
            data:{[key:string]:number},
            isCategorical?:boolean
            height?:string,
            width?:string
            color?:string
            
        }
    ){
    
    const _data = useMemo(()=>{
      // return object with first 4 keys and sum of the rest
      if (data){

        const keys = Object.keys(data);
        if (keys.length <= 4 || !isCategorical) return data;
        const result = {  };
        keys.slice(0, 4).forEach((key) => {
          result[key] = data[key];
        });
        const sum = keys.slice(4).reduce((acc, key) => {
          return typeof data[key] === 'number' ? acc + data[key] : acc;
        }, 0);
        result['{ Others }'] = sum;
        
        
        
        return result;
      }
      
    },[data])
    
    const series = useMemo(()=>{
      const style = {
        //opacity: 0.9,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.3,
            color: (color || 'rgb(100, 255, 135)')
          },
          {
            offset: 1,
            
            color: color?.replace(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d*(?:\.\d+)?)?\)/i, `rgba($1, $2, $3, ${'0.2'})`) || 'rgb(128, 255, 165,0.2)'

          }
        ])
      }
      if (!isCategorical)
            return [
              {
                  data: _data?Object.keys(_data).map(k=>_data[k]) :[],
                  name: seriesName,
                  type: getEchartsChartType(type),
                  //stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: type=="line"?3:2,
                    color:(color || 'rgb(100, 255, 135,0.9)')
                  },
                  label:{show:isCategorical, formatter: '{a}', fontWeight:900},
                  showSymbol: false,
                  
                  areaStyle: type!="area"?undefined:style,
                  itemStyle: type=="area"?undefined:style,

                  emphasis: {
                    focus: 'series'
                  },
              }
            ]

      else 
            return Object.keys(_data).map(category=>(
              {
                data:[_data[category]],
                name: category,
                type: getEchartsChartType(type),
                //stack: 'Total',
                smooth: true,
                lineStyle: {
                  width: type=="line"?3:0
                },
                label:{show:isCategorical, formatter: '{a}', fontWeight:900},
                showSymbol: false,
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {
                  borderRadius: 5,
                }
            }
            ))

      
    },[_data, type])

    function getEchartsChartType(type){
        if (type=="area") return "line"
        if (type=="bar-horizontal") return "bar"
        return type
    }

    const valueAxisOptions={
      type: 'value',
      show:false
    }

    const keyAxisOptions={
      type: 'category',
      data: !isCategorical && _data ? Object.keys(_data):[] ,
      show:false,
    }
    
    
    const options = {
        height:height,
        //width:height, //"auto",

        
        
        backgroundColor:"transparent",
        grid: {
            show:false,
            top: 0,
            bottom: 0,
            left: "-1%",
            right: 0,
        },
        tooltip: {
            trigger: isCategorical?'item':"axis", 
            formatter: isCategorical?'{a}: {c}':'{b}<br/>{a}: {c}'
            ,appendToBody:true
          },
        xAxis: !type.endsWith("horizontal") ? keyAxisOptions: valueAxisOptions,
        yAxis:  !type.endsWith("horizontal") ? valueAxisOptions: keyAxisOptions,
        series: series
      };



    

    return (
        <div className="chartWidget" style={{height:height, width:width}}>
          
   <ReactECharts  
            option={options} 
            
         />
        </div>
    )
}

