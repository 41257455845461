


import { Box,  Layer,  Tip, Text, Tab, Tabs, Spinner, CheckBox } from "grommet"
import { PromptTemplateVersion, TestCase, TestCaseResult } from "../../models/dataModel"


import { useEffect, useMemo, useState } from "react"

import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"

import FeedbackComponent from "../FeedbackComponent/feedbackComponent"

import { getApi } from "../../apiService"


import DeleteWithConfirmation from "../DeleteWithConfirmation/deleteWithConfirmation"

export  default function TestCaseDetail({testCaseId,testCase, applyChange}:{
    testCaseId?:string
    testCase?:TestCase
    applyChange?:(value:TestCase)=>any
}){
  
 const [popup, setPopup] = useState<any>()

const [testCaseObj, setTestCaseObj] = useState<TestCase>()
 useEffect(()=>{

    if (!testCase && testCaseId){
        getApi().getUnitTestCase(testCaseId).then(d=>{

            setTestCaseObj(d)
        })
    }
    else if (testCase){
        setTestCaseObj(testCase)
    }
},[testCase,testCaseId])
   
 function saveChanges(testCase:TestCase){
    getApi().postUnitTestCase(testCase).then(d=>{
        applyChange ? applyChange(d):setTestCaseObj(d)
    })

 }
 
 function updateFeedback(index,val:number|null){
    let newTextCase = {...testCaseObj}
    newTextCase.outputs[index].label = val
    saveChanges(newTextCase)
 }

 function updateOutputActive(index,val:boolean|null){
    let newTextCase = {...testCaseObj}
    newTextCase.outputs[index].active = val
    saveChanges(newTextCase)
 }

 function updateActive(val:boolean|null){
    let newTextCase = {...testCaseObj}
    newTextCase.active = val
    saveChanges(newTextCase)
 }

 function removeOutput(index:number|null){
    let newTextCase = {...testCaseObj}
    newTextCase.outputs.splice(index, 1)
    saveChanges(newTextCase)
 }
 if (!testCaseObj){
    return <Box align="center" justify="center" pad="20px"><Spinner size="large"/></Box>
 }

    return (
    <Box className="prompt-test-box run" flex={false} >
        {popup}
            
        <Box align="start" margin="10px">
            <Box direction="row" justify="between" width="100%">
                <Text weight={900} color="brand">Test inputs</Text>
                
                         <ActiveCheckBox
                        active={testCaseObj.active}
                        onChange={(val) => updateActive(val)}
                        />
            </Box>
            
            <KeyValuesComponent object={testCaseObj.inputs} />
        </Box>

      
        
        <Box justify="between" margin="10px 10px 5px" direction="row" alignSelf="stretch" >
            <Text weight={900} color="brand">Expected results</Text>
            
        </Box>
        <Box align="stretch" margin="0px 10px" gap="5px">
            {testCaseObj.outputs.map((labeled_output, i) => (
                <Box  border round="5px" >
                <Box direction="row" flex="grow"justify="between">
                    <Box  align="start" pad="5px">
                        <ResultBox result={labeled_output.value} />
                    </Box>
                    <Box flex={false}>

                        <FeedbackComponent value={labeled_output.label} onClick={(val)=>updateFeedback(i,val) }/>
                        <Box direction="row" justify="center" align="start" gap="5px">
                            <ActiveCheckBox
                            active={labeled_output.active}
                            onChange={(val) => updateOutputActive(i,val)}
                            />
                            
                            <DeleteWithConfirmation text="" onDelete={()=>removeOutput(i)} pad="2px"/>
                                
                        </Box>
                    </Box>
                </Box>
            
                </Box>
            ))
            }
        </Box>


       
       
    </Box>
        )
    
}

const ResultBox = ({ result }: { result: string | object }) => (
    typeof (result) === "string" ? (
        <Text textAlign="start" weight={900}>{result}</Text>
    ) : (
        <KeyValuesComponent object={result} />
    )
)

function ActiveCheckBox({active, onChange}:{active:boolean, onChange:(val:boolean)=>any}){
    return (
        <Box align="center">
        <CheckBox
        checked={active}
        toggle
        
        
        onChange={(event) => onChange(event.target.checked)}
        />
        <Text size="8px" color="dark-1">{active?"Include":"Skip"}</Text>
        </Box>
    )
}