


import { Box,   Drop,    Text} from "grommet"

import { useMemo, useRef, useState } from "react"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import { SiAzurefunctions } from "react-icons/si"
import FunctionCallArguments from "./functionCallArguments"



export  default function FunctionCallBox({function_call}:{
    function_call:any
    
}){

   
    return (
        <Box flex={{shrink:0}}>
         {function_call&&(
                                        <Box flex={{shrink:0}} border round="8px" background="white" pad="5px" margin="0px 3px">
                                            <Box direction="row" justify="between" align="center" flex="grow" >
                                                <Box direction="row" gap="5px"   align="center" background="rgba(101, 255, 132, 0.2)">
                                                    <Text ><SiAzurefunctions size="15px"/></Text>
                                                    <Text weight={900} >{function_call.name}</Text>
                                                </Box>
                                                <Box background="rgba(101, 255, 132, 0.2)">

                                                <Text weight={900}  >Function call</Text>
                                                </Box>
                                            </Box>
                                                <Box>
                                                    {function_call.arguments&&(
                                                        <FunctionCallArguments argumentsString={function_call.arguments}/>
                                                    )}
                                                </Box>

                                        </Box>
                                    )}
        </Box>    
    )

    
}

