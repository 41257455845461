


import { Box,   DropButton,   Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useEffect, useMemo, useState } from "react"
import { MdClose } from "react-icons/md"



export  default function DropDownButton({text, icon, placeholder, currentOptionKey, options, onOptionSelected,allowEmpty=true,...rest}:{
    text?:string
    placeholder?:string
    currentOptionKey?:string
    options?:{[key:string]:{label:any, onClick?:()=>any}}|(()=>{[key:string]:{label:any, onClick?:()=>any}})
    icon?:any
    [key:string]:any
    allowEmpty?:boolean
    onOptionSelected?:(key:string)=>any
}){
  
    const [dropOpen,setDropOpen] = useState<boolean>()
    const [selectedOption,setSelectedOption] = useState<{label:string, onClick?:()=>any}>()
    useEffect(()=>{
        if (currentOptionKey ){
            if (selectedOption!==options[currentOptionKey])
                setSelectedOption(options[currentOptionKey])
        }
    },[currentOptionKey])
    
    const currentText=useMemo(()=>{
        return text||selectedOption?.label
    },[selectedOption, text])

    // const currentOptions=useMemo(()=>{
    //     if (typeof(options)=="function"){
    //         return options()
    //     }
    //     else{
    //         return options
    //     }
    // },[currentOptionKey,options])

    return (

            <DropButton
                {...rest} 
                dropAlign={{ top: "bottom" }}
                open={dropOpen}
                onOpen={() => setDropOpen(true)}
                onClose={() => setDropOpen(false)}
                label={
                    <Box direction="row" align="center" justify="center" gap="small">
                        {icon}
                        {currentText ? (

                        <Text size="small" weight={900}>
                            {currentText}
                        </Text>
                        ):(
                            <Text  size="small"  color="light-4"> {placeholder}</Text>
                        ) }
                    </Box>
                }
                dropContent={
                    <Box pad="2px" round="2px" gap="2px">
                        {options && 
                        [  ...(allowEmpty?[(<Box pad="8px 4px"  direction="row" gap="5px" onClick={()=>{
                                setSelectedOption(null)
                                setDropOpen(false)
                                onOptionSelected &&onOptionSelected(null)
                            
                            }}>
                                <Text key="-1" weight={900}>[empty]</Text> <MdClose/>
                            </Box>)]:[])
                            ,...Object.keys(options).map((optionKey,i) => (
                            <Box pad="5px" key={i} onClick={() => {
                                setSelectedOption(options[optionKey])
                                setDropOpen(false)
                                onOptionSelected &&onOptionSelected(optionKey)
                                options[optionKey].onClick && options[optionKey].onClick()
                                
                            }
                            }>
                                <div key={i}>

                                {options[optionKey].label}
                                </div>
                            </Box>
                        ))]
                        
                        }
                    </Box>}
                />
                        
           
        
    )

    
}









