import { FaCode } from "react-icons/fa"
import Badge from "../Badge/badge"
import { Text } from "grommet"
import { PromptTemplateIcon } from "../CommonIcons/commonIcons"
import { GrDocumentTest } from "react-icons/gr"


export default function SourceDataBadge({conditions}:{
    conditions:null|{
        for_template_name?:string,
        for_tracking_project?:string,
        for_file_name?:string,
    }
}){
    if (!conditions) return <></>
    return <Badge tip={"Source of data used to run the test"}>
    {
        conditions.for_tracking_project?(
            <>
            <FaCode/>
            <Text> 
                Tracking project : <b>{conditions?.for_tracking_project}</b>
            </Text>
            </>

        ):(
            conditions.for_template_name?(
                <>
                <PromptTemplateIcon/>
                <Text> 
                    Prompt template : <b>{conditions?.for_template_name}</b>
                </Text>
                </>
            ):(
                conditions?.for_file_name?(
                    <>
                    <GrDocumentTest/>
                    <Text> 
                        File name: <b>conditions?.for_file_name</b>
                    </Text>
                    </>
                    ):(
                    <></>
                )
            )
        )
    }
</Badge>
}