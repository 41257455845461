

function stringToColor(str) {
    let hash = 0;
    const min =180;
    const max=255;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate random color components within the specified min and max range
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    // Scale the value to fit within the specified min and max range
    value = min + ((value / 255) * (max - min));
    // Convert the value to hexadecimal and append to the color string
    color += ('00' + Math.round(value).toString(16)).substr(-2);
  }

  return color;
  }


const replaceMatches = (text, regex, replaceFunc) => {
  // const parts = text.split(regex); // Split the text into an array based on the regex
  // return parts.map((part, index) => {
  //   if (part.match(regex)) {
  //     // If the part matches the regex, return a <span> element with the given props
  //     return (
  //       <span key={index} {...spanProps}>
  //         {part}
  //       </span>
  //     );
  //   } else {
  //     // Otherwise, return the original part as-is
  //     return <React.Fragment key={index}>{part}</React.Fragment>;
  //   }
  // });

  const matches = [...text.matchAll(regex)]; // Use matchAll to get an array of match objects
  const splitArray = []; // Array to store the split string with matches and surrounding text
  let currentIndex = 0; // Current index in the original string

  for (const match of matches) {
    const matchIndex = match.index; // Index of the current match in the original string
    const matchLength = match[0].length; // Length of the current match

    // Add the text before the current match to the split array
    if (matchIndex > currentIndex) {
      splitArray.push(text.slice(currentIndex, matchIndex));
    }

    // Add the current match to the split array
    splitArray.push(replaceFunc(match));

    currentIndex = matchIndex + matchLength; // Update the current index to the end of the current match
  }

  // Add the remaining text after the last match to the split array
  if (currentIndex < text.length) {
    splitArray.push(text.slice(currentIndex));
  }

  return splitArray;
};


function wrapParamsInSpan(text, templateText, params, wrapFunc=undefined) {

  let sequences = []
  let sequence_is_param = []
  let current_sequence = ""
  let current_temp_sequence = ""
  let ii = 0
  
  for (let i = 0; i < text?.length; i++) {


      if (text[i] == templateText[ii]) {
          current_sequence += text[i]
      }
      else {
          if (current_sequence) {
              sequences.push(current_sequence)
              sequence_is_param.push(null)
          }
          current_sequence = ""
          if (templateText[ii] == "{") {
              let start_ii = ii
              for (ii = ii; ii < templateText.length; ii++) {
                  if (templateText[ii] == "}") {
                      ii++
                      break
                  }
              }
              let param_name = templateText.substr(start_ii + 1, ii - start_ii - 2).trim()
              if (params && params[param_name]) {
                  let paramVal = params[param_name]
                  let p_i = 0
                  let original_start_i = i;
                  for (i = i; i < text.length; i++) {
                      if (paramVal[p_i] == text[i]) {
                          current_sequence += text[i]
                      }
                      else {
                          break
                      }
                      p_i++
                  }
                  if (p_i != paramVal.length) {
                      i = original_start_i
                  }
                  else {
                      sequences.push(current_sequence)
                      sequence_is_param.push(param_name)
                  }
                  current_sequence = ""
              }


              for (i = i; i < text.length; i++) {

                  if (text[i] == templateText[ii]) {
                      current_temp_sequence += text[i]
                      if (current_temp_sequence.length > 4) {
                          if (current_sequence.length > 0) {
                              //this is in case that the aligned sequence was shorter than 4 chars and new parameter begins
                              sequences.push(current_sequence)
                              sequence_is_param.push("?")
                          }
                          current_sequence = current_temp_sequence
                          current_temp_sequence = ""
                          break
                      }
                  }
                  else {
                      if (current_temp_sequence) {
                          if (templateText[ii] == "{" && current_sequence.length == 0) {
                              //this is in case that the aligned sequence was shorter than 4 chars and new parameter begins
                              sequences.push(current_temp_sequence)
                              sequence_is_param.push(null)
                              current_temp_sequence = ""
                              current_sequence = ""
                              ii = ii - 1
                              i = i - 1
                              break
                          }
                          else {
                              current_sequence += current_temp_sequence
                              current_temp_sequence = ""
                          }
                      }
                      current_sequence += text[i]

                  }
                  ii++
              }

          }
          else {
              current_sequence += text[i]
          }
      }
      ii++
  }
  if (current_sequence) {
      sequences.push(current_sequence)
      sequence_is_param.push(null)
  }


  let results = []
  if(!wrapFunc){
    wrapFunc = (param, key) => <span key={key} className="prompt-parameter">{param.replace(/\{\{/g,"{").replace(/\}\}/g,"}")}</span>
  }
  for (let i = 0; i < sequences.length; i++) {
      if (sequence_is_param[i]) {
          results.push(wrapFunc(sequences[i].replace(/\{\{/g,"{").replace(/\}\}/g,"}"), i))
      }
      else {
          results.push(<span key={i}>{sequences[i].replace(/\{\{/g,"{").replace(/\}\}/g,"}")}</span>)
      }
  }
  return results
}




function compareTexts(text1:string, text2:string) {
    const words1 = text1.split(/([^A-Za-z0-9])/g).filter(Boolean);
    const words2 = text2.split(/([^A-Za-z0-9])/g).filter(Boolean);
    const result = [];
    
  
    let i = 0;
    let j = 0;
    let beforeWords = [];
    let afterWords = [];
    const nonWordCharsRegex = /[^A-Za-z0-9]*/
    const similarMatch = (word1:string,word2:string)=>{
        return word1?.replace(nonWordCharsRegex,"")?.toLowerCase() === word2?.replace(nonWordCharsRegex,"")?.toLowerCase()
    }
  
    while (i < words1.length && j < words2.length) {
       

        if (similarMatch(words1[i],words2[j]) && words1[i].replace(nonWordCharsRegex,"")) { // if the words are similar but must not be empty
            let matchedWords = [];
            let exact_match=true
            
            
            while (i < words1.length && j < words2.length && similarMatch(words1[i],words2[j]) ) {
                if(words1[i]==words2[j]){
                    if (!exact_match&&matchedWords.length){
                        // if we have some matched words and previously was not exact match  and now we have exact match, we need to push the previous matched words
                        result.push({ similar: matchedWords.join("") });
                        matchedWords=[]
                    }
                    exact_match=true
                    matchedWords.push(words2[j]);
                }else{
                    if (exact_match&&matchedWords.length){
                        // if we have some matched words and previously was exact match  and now we have not exact match, we need to push the previous matched words
                        result.push({ similar: matchedWords.join("") });
                        matchedWords=[]
                    }
                    exact_match=false
                    matchedWords.push(words2[j]);
                }
              i++;
              j++;
            }

            //Push the remaining words
            if (exact_match&&matchedWords){
                result.push({ match: matchedWords.join("") });
            }
            else if (matchedWords){
                result.push({ similar: matchedWords.join("") });
            }

          } else {
            
            
            while (words1[i] && !words1[i].match(/[A-Za-z0-9]/)) {
                beforeWords.push(words1[i]);
                i++;
                
            }
            while (words2[j] && !words2[j].match(/[A-Za-z0-9]/)) {
                afterWords.push(words2[j]);
                j++;
            }

            if (words1[i] && words2[j]&& similarMatch(words1[i],words2[j])) { 
                result.push({ before: beforeWords.join(""), after: afterWords.join("") });
                beforeWords = [];
                afterWords = [];
                continue
                // we skipped some empty tokens... if the next word is similar, we will go straight into matching mode
            }

            let ii = i;
            let jj = j;
            let matched_i=-1;
            let matched_j=-1;
            

            const nextWordI = words1.find((word, index) => index > i && word.match(/[A-Za-z0-9]/));
            const nextWordJ = words2.find((word, index) => index > j && word.match(/[A-Za-z0-9]/));
            // we are not looking far ahead for the word in after, if the next words are matching, we treat this as simple word swap
            if (nextWordI?.toLowerCase() !== nextWordJ?.toLowerCase() ) {
            
                if (words2[j] && words2[j].match(/[A-Za-z0-9]/)){
                    //Look up 5 words ahead to find match... if not found, do not match as inplace addition
                    while (ii < Math.min(i+15,words1.length) ) {
                        if (words1[ii].toLowerCase() !== words2[j].toLowerCase() ){
                            ii++;
                        }
                        else{
                            // wait for 2 consecutive matches
                            if (words1[ii+1]?.toLowerCase() === words2[j+1]?.toLowerCase()){
                                matched_i = ii;
                                break
                            }
                            else{
                                ii++
                            }
                        }
                    }
                }
                

                
                
                if (words1[i] && words1[i].match(/[A-Za-z0-9]/)){
                    //Look up 5 words ahead to find match... if not found, do not match
                    while (jj <Math.min(j+15,words2.length) ) {
                        if (words1[i].toLowerCase() !== words2[jj].toLowerCase()){
                            jj++;
                        }
                        else{
                            // wait for 2 consecutive matches
                            if (words1[i+1]?.toLowerCase() === words2[jj+1]?.toLowerCase()){
                                matched_j = jj;
                                break
                            }
                            else{
                                jj++
                            }
                        }
                    }
                }
            }
            
            if(matched_i!=-1 || matched_j!=-1){
                if (matched_i>=0 && (matched_j==-1 || matched_i<matched_j)){
                    // matched_i is the first match
                    for(let k=i;k<matched_i;k++){
                        beforeWords.push(words1[k]);
                    }
                    i=matched_i
                }
                else{
                    // matched_j is the first match
                    for(let k=j;k<matched_j;k++){   
                        afterWords.push(words2[k]);
                    }
                    j=matched_j
                }
                result.push({ before: beforeWords.join(""), after: afterWords.join("") });
                beforeWords = [];
                afterWords = [];
            }
            else{
                //No match
                beforeWords.push(words1[i]);
                afterWords.push(words2[j]);
                i++;
                j++;
            }
            
            
          }
    }
    if (i < words1.length) {
      beforeWords=[]
      for (let k = i; k < words1.length; k++) {
        beforeWords.push(words1[k]);
      }
    }
    if (j < words2.length) {
        afterWords=[]
        for (let k = j; k < words2.length; k++) {
            afterWords.push(words2[k]);
        }
    }
    if (beforeWords.length || afterWords.length){
        result.push({ before: beforeWords.join(""), after: afterWords.join("") });
    }
  
    return result;
  }

  function formatDiffs(diffs:({before:string, after:string}|{match:string}|{similar:string})[]){
    let result = []
    for(let i=0;i<diffs.length;i++){
      if("match" in diffs[i]){
        result.push(<span key={i}>{(diffs[i] as any).match}</span>)
      }
      else if("similar" in diffs[i]){
        result.push(<span key={i} style={{backgroundColor: "yellow"}}>{(diffs[i] as any).similar}</span>)
      }
      else{
        result.push(<span key={i} style={{backgroundColor: "pink"}}><s>{(diffs[i] as any).before}</s></span>)
        result.push(<span key={i} style={{backgroundColor: "lightgreen"}}>{(diffs[i] as any).after}</span>)
      }
    }
    return result
  }

  export {stringToColor, replaceMatches, wrapParamsInSpan, compareTexts, formatDiffs}


  