


import { Box,   DropButton,  Spinner, Text, TextArea, TextInput } from "grommet"
import ReactECharts from 'echarts-for-react';
import { useEffect, useState, useRef, useMemo } from "react"
import { getApi } from "../../apiService";
import { GrPrevious,GrNext, GrRefresh } from "react-icons/gr";
import { DateRangePicker } from "../DateRangePicker/DateRangePicker";
import { FaRulerHorizontal } from "react-icons/fa";
import SearchBox from "../SearchBox/searchBox";
import { FiCornerLeftUp } from "react-icons/fi";

import Button from "../Button/button";
import { useResizeDetector } from 'react-resize-detector';
import "./sessionsFilter.css"
import { MdFilter, MdOutlineFilterAlt, MdOutlineNavigateBefore, MdOutlineNavigateNext, MdOutlineRefresh, MdRefresh } from "react-icons/md";
import DropDownButton from "../DropDownButon/dropDownButton";


export  default function SessionsFilter({  darkMode, filter, onFilterChange, onTextFilterChange, onRefresh}:{
    darkMode?:boolean
    filter:{since:Date, until:Date, tracking_project?:string|null, tracking_tenant?:string|null},
    onTextFilterChange?:(query:string)=>any
    onFilterChange:(filter:{since:Date, until:Date, tracking_project?:string|null, tracking_tenant?:string|null})=>any
    onRefresh:()=>any
}){
    const [data, setData]=useState<{[key:string]:number}>()
    const { width, height, ref } = useResizeDetector();

    

    let diffInHours=1;
    if (data){
        const dates=Object.keys(data).map(d=>new Date(d))
        if (dates.length>1){
            diffInHours = Math.floor((new Date(dates[1]).getTime()-dates[0].getTime()) / (1000 * 60 * 60))
        }
    }
    const [outerRange,setOuterRange]=useState<{since,until}>()
    //const [dateRange,_setDateDate]=useState<{since,until}>()
    const [selectedTrackingProject, _setSelectedTrackingProject] = useState<string>()
    const [selectedTrackingTenant, _setSelectedTrackingTenant] = useState<string>()
    const setSelectedTrackingProject = (val:string)=>{
        _setSelectedTrackingProject(val)
        filter && setFilter({...filter, tracking_project:val})
    }
    const setSelectedTrackingTenant = (val:string)=>{
        _setSelectedTrackingTenant(val)
        filter && setFilter({...filter,tracking_tenant:val})
    }
    const [trackingProjects, setTrackingProjects] = useState<string[]>()
    const [trackingTenants, setTrackingTenants] =  useState<string[]>()
    

    
    useEffect(()=>{
        if (trackingProjects===undefined){
            getApi().getTrackingProjects().then(_data=>{
                setTrackingProjects(_data?.map(t=>t.name))
            })
        }
    },[])
    useEffect(()=>{
        if (trackingTenants===undefined){
        getApi().getTrackingTenants().then(_data=>setTrackingTenants(_data?.map(t=>t.name)))
        }
    },[])


    
    const setFilter = (newFilter:{since?:Date, until?:Date, tracking_project?:string, tracking_tenant?:string})=>{
        let _filter ={...(filter||{}), ...newFilter}
        if ((filter && data===undefined) || !(filter  
                && (newFilter.since===undefined || newFilter.since?.getTime() == filter?.since?.getTime() )
                && (newFilter.until===undefined || newFilter.until?.getTime() == filter?.until?.getTime() )
                && (newFilter.tracking_project===undefined || newFilter.tracking_project == filter?.tracking_project) 
                && (newFilter.tracking_tenant===undefined || newFilter.tracking_tenant == filter?.tracking_tenant) 
            )){
            
            //_setDateDate(range)


              
            //we want stats to be always with the 12h time window
            if (_filter.until && _filter.since &&  (_filter.until?.getTime() - _filter.since?.getTime()) < 1000 * 60 * 60 * 24) {
                const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
                _filter.since = new Date(_filter.since.getTime() - twelveHoursInMilliseconds);
                const updatedUntil = new Date(_filter.until.getTime() + twelveHoursInMilliseconds);
                _filter.until = (updatedUntil.getTime() > Date.now()) ? new Date() : updatedUntil;
            }
            getApi().getSessionStats(_filter).then(data=>{
                setData(data)
            })
            //I want onFilterChange to be executed with the original time values
            onFilterChange&&onFilterChange(({...(filter||{}), ...newFilter} as any))
        }
    }
    const timerRef = useRef(null);
    useEffect(()=>{
        if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
        // Set a new timer
        if (filter){

            timerRef.current = setTimeout(() => {
                // Your code logic here
                filter && setFilter({since:filter.since, until:filter.until})
            }, 1000);
        }

       
    },[filter])

    useEffect(() => {
        return () => {
          // Cleanup function to cancel the timer when the component unmounts
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
        };
      }, []);

    // useEffect(()=>{
    //     if (filter && (filter.tracking_project!=selectedTrackingProject || filter.tracking_tenant!=selectedTrackingTenant)){

    //         setFilter({tracking_project:selectedTrackingProject, tracking_tenant:selectedTrackingTenant})
    //     }
    // },[selectedTrackingProject, selectedTrackingTenant])


    const onDateClick=(date)=>{
        if ((filter?.until.getTime() - filter?.since.getTime())/1000/60/60 > 24){

            setOuterRange({since:filter.since, until:filter.until})
            // Set the time to midnight (start of the day)
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            
            // Set the time to 11:59:59 PM (end of the day)
            const endOfDay = new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000);
            
            // Return an object containing the start and end of the day
            setFilter( { since:startOfDay, until:endOfDay });
        }
        else{
            const startOfTheHour = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
            const endOfTheHour = new Date(startOfTheHour.getTime() + 60 * 60 * 1000);
            setFilter( { since:startOfTheHour, until:endOfTheHour });
        }
        
    }

    function patchDate(val:Date|null, relativeDays, round=false){
        let res;
        if (!val){
            res =  new Date((new Date()).getTime() + (relativeDays * 24 * 60 * 60 * 1000))
            
        }
        else{
            res= val
        }
        if (round){
            res.setHours(0, 0, 0, 0);
        }
        return res
    }

    useEffect(()=>{
        if (filter.tracking_project && selectedTrackingProject!=filter.tracking_project){
            setSelectedTrackingProject(filter.tracking_project)
        }
        if (filter.tracking_tenant && selectedTrackingTenant!=filter.tracking_tenant){
            setSelectedTrackingTenant(filter.tracking_tenant)
        }

    },[filter])

    // useEffect(()=>{
    //     if (filter && (filter.until && filter.since)){
    //         if (!(filter &&filter?.since.getTime() == filter.since.getTime() && dateRange?.until.getTime() == filter.until.getTime() )){
    //             let newState ;
    //             newState = {since:patchDate(filter.since,-1),until:patchDate(filter.until,0)}
                
                
    //             setFilter(newState)
                
    //         }
    //     }
    // },[filter])

        function updateDateRange(dateRange, amount, unit) {
        // Convert the amount to milliseconds
        const amountInMillis = unit === 'hour' ? amount * 60 * 60 * 1000 : amount * 24 * 60 * 60 * 1000;
        
        // Create new Date objects for the updated range
        const since =dateRange.since? new Date(dateRange.since?.getTime() + amountInMillis):dateRange.since;
        const until = dateRange.until?new Date(dateRange.until.getTime() + amountInMillis):dateRange.until;

        
        
        // Return the updated date range object
        return { since, until };
      }

    function previous(){
        setFilter(updateDateRange(filter,-diffInHours,"hour"))
    }
    function next(){
        setFilter(updateDateRange(filter,diffInHours,"hour"))
    }
    
    // const chartWidth = useMemo(()=>{
    //     if(ref.current){
    //         console.log(ref.current)
    //     }

    // },[ref])
    
    const formatter = (value, i)=>{

            const date=new Date(value)
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            let result=""
            let total_labels = Object.keys(data).length
            if ((hours=="00" && minutes=="00")||diffInHours>1){
                
                
                return date.toLocaleDateString('en-US',  { day: 'numeric', month: 'short' }).replace('.', '') ;
            }
            else{
                return  data &&total_labels<48? `${hours}:${minutes}`:"";
            }
            
            
            
        }
    
    const labelInterval=useMemo(()=>{
        if (data){

            let total_labels = Object.keys(data).length
            let acceptable_num_of_labels= width/30 //15px?
            if (acceptable_num_of_labels>total_labels)
            return 1
            else {
                //let num_of_labels_we_must_omit = Math.round(1+total_labels-acceptable_num_of_labels)
                let modulo = Math.round((total_labels+1)/acceptable_num_of_labels)
                return modulo
            }
        }
    },[data])
    const timeZoneOffset = useMemo(()=>new Date().getTimezoneOffset()/60,[])
    const options = {
        // height:"100px",
        backgroundColor:darkMode?"#50555e":undefined,
        grid: {
            top: 0,
            bottom: 20,
            left: 0,
            right: 0
        },
        tooltip: {
            trigger: 'axis', // set the tooltip trigger type
            // axisPointer: {
            //   type: 'cross' // set the tooltip line style
            // }
            formatter: (timeZoneOffset?`{b} (${(-1)* timeZoneOffset < 0 ? '-' : '+'}${Math.abs(timeZoneOffset)}h to local time)<br/>{a}: {c}`:'{b}<br/>{a}: {c}')
            
          },
        xAxis: {
          type: 'category',
          data: data ? Object.keys(data):[],
          
          axisLabel: {
            interval:labelInterval,
            formatter: formatter,
            // formatter: (value)=>{
            //     const date=new Date(value)
            //     return date.toLocaleDateString('en-US',  { day: 'numeric', month: 'short' }).replace('.', '');
            //     const hours = date.getHours().toString().padStart(2, '0');
            //     const minutes = date.getMinutes().toString().padStart(2, '0');
            //     if (hours=="00" && minutes=="00"){
                    

            //     }
            //     else{
            //         return `${hours}:${minutes}`;
            //     }
            // },
            align: 'center'
            // ...
          }
        },
        yAxis: {
          type: 'value',
          show:false
        },
        series: [
          {
            name:"Total sessions",
            data: data?Object.keys(data).map(k=>data[k]) :[],
            type: 'bar',
            color:"#6FFFB0",
            selectedMode: 'single',
            select: { // Style of selected bar
                itemStyle: {
                    color: 'orange'
                }
              },
            emphasis: {
                itemStyle: {
                  color: 'orange'
                }
              }
          }
        ]
      };

    return (
        <Box  flex={false} >
        <Box direction="row" alignSelf="stretch"   pad="2px 5px" justify="end" align="start" gap="5px">

                        
            <DateRangePicker  range={{since:filter?.since, until:filter?.until}} onRangeChanged={setFilter} />
            {<DropDownButton 
                disabled={!trackingProjects?.length}
                icon={<MdOutlineFilterAlt/>}
                enabled={!!trackingProjects?.length}
                placeholder="Project"
                text={selectedTrackingProject}
                onOptionSelected={project=>setSelectedTrackingProject(project)}
                options={
                    trackingProjects && Object.fromEntries(trackingProjects.map(p=>[p,{label:p}]))
                }  />
            }
            
            { <DropDownButton 
                disabled={!trackingTenants?.length}
                icon={<MdOutlineFilterAlt/>}
                placeholder="Tenant"
                text={selectedTrackingTenant}
                onOptionSelected={project=>setSelectedTrackingTenant(project)}
                options={
                    trackingTenants && Object.fromEntries(trackingTenants.map(p=>[p,{label:p}]))
                }  />
            }
            {(filter && !filter.until) && <Button 
            
                pad="2px 2px" background="accent-1"  icon={<GrRefresh  size="18px"/>} onClick={()=>onRefresh && onRefresh()}/>}
        
            
            
          
                
                </Box>
        
        <Box direction="row" justify="stretch" align="start"  >
            <Box  width="30px"  alignSelf="stretch" justify="end"  >
            {
                outerRange &&(
                    <Button
                        hoverIndicator
                        aria-label="start"
                        round={false}
                        
                        border={false}
                        pad="5px 0px"
                        //margin="-15px"
                        style={{display:"fixed",top:0}}
                        icon={<FiCornerLeftUp color={darkMode?"white":undefined} size="15px"/>}
                        onClick={() =>{
                            setFilter(outerRange)
                            setOuterRange(undefined)

                        }}
                        />
                )
            }
            

                <Button
                round={false}
                border={false}
                height="70%"
                hoverIndicator
                pad="0px"
                icon={<MdOutlineNavigateBefore size="35px" />}
                onClick={() => previous()}
                />
            </Box>
            
        <Box ref={ref} flex="grow" height="100px" margin="5px 0px">

            <ReactECharts  
            theme={darkMode? "dark":undefined}
            option={options} 
            onEvents={{"click":(e)=>{
                onDateClick(new Date(e.name))
            
            }}} />
            
        </Box>
       
             
        <Box flex={false} width="30px"   alignSelf="stretch" justify="end"   >
                    <Button
                    className="chartMoveBtn"
                    round={false}
                    border={false}
                    height="70%"
                    pad="0px"
                hoverIndicator
                icon={<MdOutlineNavigateNext  size="35px" />}
                onClick={() => next()}
                />
                </Box>
        
        
        </Box>
        {onTextFilterChange && 
            <Box flex={{grow:3}} pad="0px 10px" direction="row" margin="-3px 0px 0px"   justify="end" wrap>
                <Box  width={{width:"400px"}} >

                    <SearchBox onApplySearch={onTextFilterChange}/>
            </Box>
        </Box>}
        </Box>
    )

    
}

