


import { Box,  Spinner, Text, TextArea, TextInput, Tip } from "grommet"
import { Action, ActivityBase, Answer } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"

import { useEffect, useState } from "react"
import { GrRobot } from "react-icons/gr"
import FeedbackComponent from "../FeedbackComponent/feedbackComponent"
import Rating from "../RatingComponent/rating"
import { getApi } from "../../apiService"
import { FaLightbulb, FaRegStickyNote,  } from "react-icons/fa"
import Popup from "../Popup/popup"
import Button from "../Button/button"


const NotesEdit=({feedback, onSave, onCancel}:{
    feedback:string,
    onSave:(feedback)=>any,
    onCancel:()=>any
})=>{
    const [feedbackEdited, setFeedbackEdited]=useState<string>(feedback)
    return (<Box pad="20px" gap="10px">
                            
                            <TextArea value={feedbackEdited||""} size="xs" style={{height:"30vh", fontWeight:500}} onChange={(e)=>{
                                setFeedbackEdited(e.target.value)
                                }}/>
                            <Box direction="row" alignSelf="end"  gap="10px">
                                <Button secondary text="Cancel" onClick={()=>onCancel()}/>
                                <Button primary text="Save" onClick={()=>{
                                    onCancel()
                                    onSave(feedbackEdited)
                                }}/>

                            </Box>
                            
                        </Box>)
}

export  default function FeedbackComponentFull({activity, onActivityChange: onChangeChange}:{
    activity:Answer,
    onActivityChange?:(activity:ActivityBase)=>any
}){
  
    const [popup, setPopup]=useState<any>()
    
    const setActivityFeedback=(feedback:{feedback_label?:number|undefined, feedback_rating?:number|undefined, feedback_notes?:string|undefined})=>{
   
            if (feedback.feedback_rating && feedback.feedback_rating<2){
                feedback.feedback_label=0
            }
            else if(feedback.feedback_rating && feedback.feedback_rating>4){
                feedback.feedback_label=1
            }
            getApi().patchActivity(activity.id,{
                feedback_label:activity.feedback_label, 
                feedback_rating:activity.feedback_rating,
                feedback_notes:activity.feedback_notes,
                ...feedback
            }).then(()=>{    
                onChangeChange({...activity, ...feedback})
            })
        
    }



    return (
                <Box direction="row" justify="center" align="center" gap="5px" flex={{shrink:0}}>
                    {popup}
                    <Box focusIndicator={false} pad="0px 5px" direction="row" align="center" onClick={()=>setPopup(<Popup caption="Feedback notes" onClose={()=>setPopup(undefined)} >
                        <NotesEdit feedback={activity.feedback_notes} 
                        onSave={(val)=>setActivityFeedback({feedback_notes:val})} 
                        onCancel={()=>setPopup(undefined)}/>

                    </Popup>)}>
                        {activity.feedback_notes && <Box flex={{grow:1, shrink:1}} style={{maxWidth:"450px"}} direction="row" >
                            <Box flex={false}><FaLightbulb size="10px" color="orange"/></Box>
                            <Tip content={
                                <Box>
                                    <Text weight={900}>Feedback notes</Text>
                                    {activity.feedback_notes}
                                </Box>
                            }>

                            <Text size="10px" truncate>{activity.feedback_notes}</Text>
                            </Tip>
                        </Box>}
                        <FaRegStickyNote size="15px" color="gray" /> 
                    </Box>
                    <Rating value={activity.feedback_rating} onChange={v=>setActivityFeedback({feedback_rating:v})}/>
                    <FeedbackComponent value={activity.feedback_label} onClick={v=>setActivityFeedback({feedback_label:v})} />    
                </Box>
         )

    
}

