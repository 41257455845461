// import { } from "react";
import {Box} from "grommet";
import { Routes, Route, Navigate } from "react-router-dom";

import SessionsPage from "./pages/sessionsPage";
import SessionDetailPage from "./pages/sessionDetailPage";
import   PromptTemplatesPage from "./pages/promptTemplatesPage";
import   SettingsPage from "./pages/settingsPage";
import   LoginPage from "./pages/loginPage";
import   DashboardPage from "./pages/dashboardPage";

import GetApiKeyPage from  "./pages/getApiKeyPage";
import AppSidebar from "./components/AppSidebar/appSidebar";
import HomePage from  "./pages/homePage";

import {DemoDashboard, DemoPrompts} from "./demo_pages";
import PromptUnitTestsPage from "./pages/unitTestsPage";
import { memo } from "react";
import { useAppContext } from "./appContext";

const WithSidebar =memo(({page, children}:any)=>{
  return (

    <Box direction='row' align='stretch' justify='stretch' fill>

      <AppSidebar/>
      {children || page}
      
    </Box>
    )
    
}, (prevProps, nextProps)=>{

  return prevProps.page===nextProps.page

})

const PageRouter = memo(() => {

  

  
  return (
    
    

        <Box height="100%" >
      <Routes>
        <Route path="/"                     element={<HomePage/>}/>
        <Route path="/get-api-key"          element={<GetApiKeyPage/>}/>
        <Route path="/login"                element={<LoginPage mode="login"/>}/>
        <Route path="/home"                 element={<WithSidebar page={<DashboardPage/>}/>}/>
        <Route path="/signup"               element={<LoginPage mode="signup"/>}/>
        <Route path="/settings"             element={<WithSidebar page={<SettingsPage/>}/>}/>
        <Route path="/unit-tests"                 element={<WithSidebar page={<PromptUnitTestsPage/>}/>}/>
        
        <Route path="/prompt-templates"     element={<WithSidebar page={<PromptTemplatesPage/>}/>}/>
        <Route path="/sessions/"  element={<WithSidebar page={<SessionsPage/>}/>}/>
        <Route path="/sessions/:sessionId"  element={<WithSidebar page={<SessionDetailPage/>}/>}/>

        <Route path="demo/home"                 element={<WithSidebar page={<DemoDashboard/>}/>}/>
        <Route path="demo/prompt-templates"                 element={<WithSidebar page={<DemoPrompts/>}/>}/>


        <Route path="*"                element={<HomePage/>}/>
 
          

       
        
          

      </Routes>
         </Box>
    
    
  );
});

export default PageRouter;
