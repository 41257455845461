


import { Box, Button,  Layer,  Tip, Text } from "grommet"
import { type } from "os"
import { MdCheckCircle, MdError, MdInfo, MdWarning } from "react-icons/md"
import "./infoToast.css"


export  default function InfoToast({type,text,children,tip, icon, color, onClick, ...rest}:{
    children?:any
    text?:any
    type:"info"|"warning"|"error"|"success",
    icon?:any
    tip?:any
    onClick?:()=>any
    [key:string]:any
}){
  
    let _icon = undefined;
    let _color = undefined;
    let _background = undefined;
    if(type=="info"){   
        _icon = icon||<MdInfo size="15px"/>
        _color=color||"#21618C"
        _background = "#D6EAF8"
    }
    else if(type=="warning"){
        _icon = icon||<MdWarning size="15px"/>
        _color=color||"#B9770E"
        _background="#FCF3CF"
    }
    else if(type=="error"){
        _icon = icon||<MdError size="15px"/>
        _color=color||"#922B21"
        _background="#F2D7D5"
    }
    else if(type=="success"){
        _icon = icon||<MdCheckCircle size="15px"/>
        _color=color||"#229954"
        _background="#EAFAF1"
    }
    
    const res = (
        <Box direction="row" align="center"  onClick={onClick} focusIndicator={false} 
        flex={false}
        className="info-toast"
        gap="5px"
        background={_background}
        border round="5px"  pad="5px" margin="2px"
        {...rest}>
            <Box alignSelf="start">
                <Text color={_color}>
                    {_icon}
                </Text>
            </Box>
            <Text  color={_color} weight={600}>

            {children||(
                <Box direction="row" align="center" gap={(icon&&text)?"2px":undefined} pad="0px">{icon} <Text >{text}</Text></Box>
                )}
            </Text>
            
        </Box>
    )

    if (tip){
        return (<Tip plain content={
            <Box background="white" pad="5px" elevation="medium" round="5px" margin="5px" border>

            {typeof(tip)=="string"?<Text>{tip}</Text>:tip}
            </Box>
        }>
            {res}
        </Tip>)
    }
    else{
        return res
    }
    
}