import  { useMemo } from 'react'
import { useContext, createContext,useState, useEffect } from "react";
import { useNavigate } from 'react-router';

import { firebaseApp } from './firebase/firebaseApp';
import { getAuth, updatePassword, signOut } from "firebase/auth";

interface UserInfo{
    id:string
    name:string
    email:string
    photoURL:string
    accessToken:string
}



interface AppContext{
    error:string
    setError:(err:string)=>any
    info:string,
    setInfo:(info:string)=>any
    currentUser?:UserInfo
    tempApiKey:string
    setTempApiKey:(key:string)=>any
    setCurrentUser:(val:UserInfo|any)=>any
    logOut:(redirectToLogin?:boolean, followUpUrl?:string)=>any
}

 
const AppContext = createContext(undefined);


export function useAppContext(){
    return useContext(AppContext) as AppContext;
}

export function AppContextProvider({children}:any){

   
    //const [projectInfo,setProjectInfo] = useState();
    const [error,setError] = useState<string>();
    const [info,setInfo] = useState<string>();
    const [tempApiKey,setTempApiKey] = useState<string>();
    const [currentUser,_setCurrentUser] = useState<UserInfo>();
    
    const setCurrentUser=(user:any)=>{
        if (!user) {
            _setCurrentUser(null)
            return
        }
        if (user.id && user.name){
            _setCurrentUser(user)
        }
        else{
            _setCurrentUser({
                id:user.uid,
                name:user.displayName||user.email,
                email:user.email,
                photoURL:user.photoURL,
                accessToken:(user as any).accessToken
            })
        }
        
    }

    useEffect(()=>{
        const auth = getAuth(firebaseApp);
        auth.onAuthStateChanged((user)=>{
            setCurrentUser(user)
        })
    },[])

    const navigate = useNavigate()
    function logOut(redirectToLogin:boolean=true, followUpUrl?:string){
        const auth = getAuth(firebaseApp);
        signOut(auth)
        setCurrentUser(null)
        if (redirectToLogin){
            if (followUpUrl)
                navigate("/login?force=true&followup="+encodeURIComponent(followUpUrl))
            else
                navigate("/login?force=true")
        }   
    }
    

    const value = {
     
        error,
        setError,
        info,
        setInfo,
        currentUser,
        tempApiKey,
        setTempApiKey,
        setCurrentUser,
        logOut
    };
    

    return (
        <AppContext.Provider value={value}>
        {children}
        </AppContext.Provider>
        
        );
}