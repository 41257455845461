import { Box, Heading, Text } from "grommet";
import bluredDashboard from './bluredDashboard.png'; 
import "./demo.css";
import Button from "../components/Button/button";
import { useNavigate } from "react-router-dom";
export default function DemoDashboard() {

    const navigate = useNavigate()
    return <Box  style={{display:"relative"}}>
    <div className="demo-overlay ">
      <Box className="infoBox" align="start" justify="start" >
      <Heading level={2}>Hello stranger 👋</Heading>
      <Text size="large" textAlign="start">
        You would normally be able to see here your dashboard with:</Text>
        <Text size="large"  textAlign="start">
            <ul>
                <li>your monthly session statistics</li>
                <li>breakdown of your costs and total number of session by your tenant</li>
                <li>your most recent sessions</li>
            </ul>
        </Text>
        <Text size="large" textAlign="start">
        Consider signing up for a free account to get started.
      </Text>
      <Box margin="10px 0px 0px" alignSelf="end">

        <Button size="large"  primary label="Sign up"  onClick={()=>navigate("/signup")} text="Sign up"/>
      </Box>
      </Box>
    </div>
<Box pad="20px" align="start">

    <Text size="40px" color="dark-2" weight={900}>Welcome</Text>
  
        <img src={bluredDashboard} style={{height:"90vh",  width:"auto"}}/>
</Box>
        
    </Box>
}