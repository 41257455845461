


import { Box, Button,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill} from "react-icons/bs"
import { useState } from "react"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import ActivityComponent from "./ActivityComponent"



export  default function ActionComponent({activity, allActivities, onClick}:{
    activity:Action
    allActivities:ActivityBase[]
    onClick?:()=>any
}){
  
    const [open, setOpen]=useState(false)
    const childActivities = allActivities?.filter(a=>a.parent_activity_id==activity.id)

    return (
        <Box flex="grow"  pad="2px" round="5px" background="white" justify="between" onClick={onClick ?onClick():undefined}>
            
            <Box direction="row" gap="small" align="center">

                <Text size="small" weight={900}>Input</Text>
                {/* <HighlightableText text={activity.input}/> */}
                <Text textAlign="start" alignSelf="start" size="small" style={{ whiteSpace: 'pre-line' }}  >{activity.input}</Text>
            </Box>
           <Box>
            { !!childActivities?.length && 
                <Box  direction="row"  justify="start" align="center" focusIndicator={false} onClick={()=>setOpen(!open)} pad="0px 5px">
                        {open ?<MdKeyboardArrowUp size="25px"/> :<MdKeyboardArrowDown size="25px"/> }
                        <Text size="small">Details</Text>
                </Box>
            }
           {open && childActivities && (
              childActivities?.map(a=>(
                <ActivityComponent activity={a} allActivities={allActivities}/>
            ))
           )
           }
            </Box>
            <Box direction="row" gap="small" align="center">

                <Text size="small" weight={900}>Output</Text>
                {/* <HighlightableText text={activity.output}/> */}
                <Text textAlign="start" alignSelf="start" size="small" style={{ whiteSpace: 'pre-line' }}  >{activity.output}</Text>
                

            </Box>
           
           
        </Box>    
    )

    
}

