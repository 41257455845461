
import { Box, ResponsiveContext, Spinner, Text, Tip } from "grommet";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../appContext";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import "./widgets.css"
import { MdInfo, MdInfoOutline } from "react-icons/md";
import ChartWidget from "./chartWidget";


const SIZES={
    "small":{
        text:13,
        note:10,
        value:40,
        unit:26,
        width:92,
        chart: 60
        
    },
    "medium":{
        text:18,
        note:13,
        value:52,
        unit:36,
        width:135,
        chart:90
    },
    "large":{
        text:25,
        note:16,
        value:82,
        unit:46,
        width:245,
        chart:190
    }
}

export  default function CardWidget({caption, size: _size ="medium", note, value, value_unit, tip, chart, backgroundColor, valueColor, loading}:{
    caption:string,
    size?:"small"|"medium"|"large"
    note?:string
    value?:number,
    value_unit?:string
    tip?:string,
    backgroundColor?:string,
    valueColor?:string,
    loading?:boolean,
    chart?: { 
        type:"line"|"bar"|"pie"|"area"|"bar-horizontal", 
        data:{[key:string]:number}, 
        position:"right"|"bottom"
        isCategorical?:boolean,
        color?:string
    }|undefined
}){
    
    const responsiveSize = useContext(ResponsiveContext);
    const size = responsiveSize=="small"?"small":_size
    const humanizedValue = useMemo(()=>humanizeNumber(value),[value])
    return (
        <div className="cardWidget" style={{
                backgroundColor:backgroundColor, 
                height:  (SIZES[size].width  +  (!chart ?0: (chart.position=="bottom" ?( Math.round(SIZES[size].chart/2)):-Math.round(SIZES[size].chart/5)))) + "px",
                width:  (SIZES[size].width + (!chart ? 0: (chart.position=="bottom" ? (  SIZES[size].chart/1.9   )  :  Math.round(SIZES[size].chart*1.5 )))) + "px",
                minHeight:  (SIZES[size].width  +  (!chart ?0: (chart.position=="bottom" ?( Math.round(SIZES[size].chart/2)):-Math.round(SIZES[size].chart/5)))) + "px",
                minWidth:  (SIZES[size].width + (!chart ? 0: (chart.position=="bottom" ? (  SIZES[size].chart/1.9   )  :  Math.round(SIZES[size].chart*1.5 )))) + "px",
            }} >
            {loading?(
                <Box fill>
                <Text  textAlign="start" size={SIZES[size].text + "px"} color={"light-4"}> {caption}</Text>
                <Box align="center" justify="center" fill>
                      
                    <Spinner />
                </Box>
                </Box>
            ):(

            
            <Box direction={chart && chart.position=="right"? "row":"column"} align="between" justify="between" fill flex={false} >
            <Box align="stretch"  flex="shrink" >    
                <Box direction="row" justify="between" background="white">
                    <Box align="start" >

                        <Text  textAlign="start" size={SIZES[size].text + "px"} color={"dark-2"}> {caption}</Text>
                        
                    </Box>
                    {tip&&(
                        <Tip plain content={
                            <Box background="white" round="5px" elevation="small" border pad="3px 6px">
                                    {tip}
                            </Box>
                        }>
                            <Box><MdInfoOutline color="rgb(150,150,150)" size="15px"/></Box>
                        </Tip>
                    )}
                </Box>
                <Box>
                {value !==undefined &&  <Box  direction="row"  justify={chart?.position=="right"?"end":"start"} pad="0px 5px 0px 0px" margin="0% 5px -5% 1%">
                    <Text size={SIZES[size].value+ "px"} color= {valueColor||"dark-2"} >
                        {humanizedValue==value?value:(
                            <Tip content={Math.round(1000*value)/1000}>
                                {humanizedValue}
                                </Tip>
                        )}
                        {value_unit && <Text  color= {valueColor||"dark-2"} size={SIZES[size].unit+ "px"}>{value_unit}</Text>}
                    </Text>
            
                </Box>}
                <Box  >

                {note && <Text weight={600} textAlign={chart?.position=="right"?"end":"start"}  size={SIZES[size].note+ "px"} color="dark-4">{note}</Text>}
                </Box>
                </Box>
            </Box>
            {chart && 
            <Box   justify="end"
                margin={chart.position=="bottom"? ( chart.type!="bar-horizontal"?"-0px  0px 0px":"-15px  0px -15px"):"-0px 0px 0px 0px" }
                flex={false} className="chartBoundary" >
             <ChartWidget seriesName={caption} {...chart} height={(SIZES[size].chart + ((value===undefined && chart.position=="bottom")? SIZES[size].value :0))+"px" } width={(chart.position=="right"?(SIZES[size].chart*1.5+"px"):"auto")} />
            </Box>
             }
             </Box>
            
            )}
        </div>
    )
}




const humanizedNumberThresholds=[
    {limit:0,divide:1,round:1,sufix:""},
    {limit:10**4,divide:10**3,round:0,sufix:"k"},
    {limit:10**6,divide:10**6,round:1,sufix:"M"},
    {limit:10**7,divide:10**6,round:0,sufix:"M"},
    //{limit:10**9,divide:10**9,round:1,sufix:"B"},
    {limit:10**10,divide:10**9,round:0,sufix:"B"},
  ]
  function humanizeNumber(number){
    if (number){
        let matchedThreshold=null;
        for(let threshold of humanizedNumberThresholds){
            if (number/threshold.limit>=1){
                matchedThreshold=threshold
            }
            else{
                break
            }
        }
    
        if (matchedThreshold){
            return (Math.floor((10**matchedThreshold.round)*(number/matchedThreshold.divide))/(10**matchedThreshold.round)).toString() + matchedThreshold.sufix
        }
        else{
            return number.toString()
        }
    }
    else{
        return 0;
    }
    
    
  }