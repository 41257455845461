
import { Accordion, AccordionPanel, Box,    DataTable,  Heading,  Spinner,  Tab,  Tabs,  Text, TextInput } from "grommet";
import { useState } from "react";
import { AutosizeTextInput } from "../AutosizeTextInput/autosizeTextInput";
import Button from "../Button/button";
import CodeBlock from "../CodeBlock/codeBlock";
import { FiCopy } from "react-icons/fi";

export const ApiKeyDetail =  ({ apiKey }:{apiKey:ApiKey}) =>{
    const [info, setInfo] = useState<string>()
    const [accordionIndex, setAcordionIndex] = useState<any>(0)

    if (!apiKey?.value){
        console.log(apiKey)
        return <></>
    }

   return (
    <Box pad="small" align="center">
        <Text size="large" weight={900} color="brand">Your API key</Text>
        <Box direction="row">
                                    
                                         <AutosizeTextInput  value={apiKey.value} size="small"/> 
                                        
                                        
                                    <Button 
                                    flex={false}
                                    round="2px" margin="0px 2px" onClick={()=>{
                                        setInfo("Copied!")
                                        navigator.clipboard.writeText(apiKey.value)
                                        setTimeout(()=>setInfo(""),1000)
                                    }
                                    }>
                                        <FiCopy size="15px" />
                                    </Button>
        </Box>
        <Text weight={900}>{info}</Text>
        <Box pad="0px" alignSelf="stretch">
        <Heading level={3}>Usage</Heading>
        
        <Tabs alignControls="start" alignSelf="stretch">
        <Tab title="As ENV variable (recommended)">
        <Accordion activeIndex={accordionIndex} onActive={setAcordionIndex} margin="5px 20px">
        <AccordionPanel label="shell">
            <CodeBlock   highlightLines={[2]}>
{`export PROMPTWATCH_API_KEY="${apiKey?.value||"<your-api-key>"}"
`}
            </CodeBlock>
            </AccordionPanel>
            <AccordionPanel label="Python">
            <CodeBlock    highlightLines={[3]}>
{`import os

os.environ['PROMPTWATCH_API_KEY'] = '${apiKey?.value||"<your-api-key>"}'
`}
            </CodeBlock>
            </AccordionPanel>
            <AccordionPanel label="VS Code">
            <CodeBlock  caption="(launch.json)"  highlightLines={[5]}>
{`"configurations": [
        {
            ...
            "env": {
                "PROMPTWATCH_API_KEY":"${apiKey?.value||"<your-api-key>"}",
            },
        },
`}
            </CodeBlock>
            </AccordionPanel>
        </Accordion>
            
        </Tab>
        <Tab title="Explicitly">
            <Box pad="5px 20px">
        <CodeBlock  caption="Example integration"  highlightLines={[2,7,8]}>
{`from langchain import OpenAI, LLMChain, PromptTemplate
from promptwatch import PromptWatch

prompt_template = PromptTemplate.from_template("Finish this sentence {input}")
my_chain = LLMChain(llm=OpenAI(), prompt=prompt_template)

with PromptWatch(api_key="${apiKey?.value||"<your-api-key>"}") as pw:
    my_chain("The quick brown fox jumped over")`}

            </CodeBlock>
            </Box>
            </Tab>
        </Tabs>
           
        </Box>
    </Box>
   )
}