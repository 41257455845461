
import { Box, Spinner, Text, Tip } from "grommet";
import { useEffect, useMemo, useState } from "react";

import "./widgets.css"
import { MdInfo, MdInfoOutline } from "react-icons/md";
import ChartWidget from "./chartWidget";



export  default function Loader<T>({getDataFunc, children,}:{
    getDataFunc?:()=>Promise<T>
    children:(data:T|undefined)=>any,

}){
    const [data,setData]=useState<T>()
    

    useEffect(()=>{
        getDataFunc()
            .then(data=>setData(data))
            .catch(ex=>console.log(ex))
    },[])

    
    return (
        <>

            {children(data)}

        </>
    )
}
