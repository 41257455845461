
import { Box, Button, Text } from "grommet";
import { useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import SessionDetail from "../components/SessionDetail/sessionDetail";

export  default function SessionDetailPage(){
    
    const [popup, setPopup]=useState<any>()
    const [sessions, setSessions]=useState<Session[]>()
    const [tenantSettings, setTenantSettings]=useState<any>()
    

    const navigate = useNavigate()
    const {sessionId} = useParams()
   

    useEffect(()=>{
        getApi().getSessions().then(data=>setSessions(data))
    },[])

 

    return (
        <Box fill>
        
     <Text color="white" weight={900}>Session </Text>
        <Box pad="medium" justify="between" flex="grow">


          <SessionDetail sessionId={sessionId} />

        </Box>
        </Box>
    )
}