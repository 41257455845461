
import Badge from "../Badge/badge"
import { VscOrganization, VscRepo } from "react-icons/vsc";
import {stringToColor} from "../../utils/helpers"
import { Text } from "grommet";

const ProjectBadge =({tracking_project})=>{
    return  <Badge                                        
            tip={"Tracking project: "+ tracking_project}
            background={stringToColor(`project: ${tracking_project}`)}
            border={false}
            icon={<VscRepo/>}
            text={
                <Text size="small" color="dark-3" weight={600} truncate>
                {tracking_project}
                </Text>
                }
            />
}

const TenantBadge =({tracking_project})=>{
    return  <Badge
            tip={"Tracking tenant: "+ tracking_project}
            icon={<VscOrganization/>}
            background={stringToColor(`project: ${tracking_project}`)}
            border={false}
            text={
                <Text size="small" color="dark-3" weight={600}  truncate>
                    {tracking_project}
                </Text>
            }/>
}

export {
    ProjectBadge,
    TenantBadge
}