


import { Box } from "grommet"
import { AiOutlineDislike, AiOutlineLike, AiTwotoneDislike, AiTwotoneLike } from "react-icons/ai"
import Button from "../Button/button"
import { useEffect, useState } from "react"
import { ActivityBase } from "../../models/dataModel"



export  default function FeedbackComponent({value,  onClick, ...rest}:{
    value?:number
    activity?:ActivityBase,
    onClick?:(value:number|null)=>any
    [key:string]:any
}){
  
    const size="15px"
    const [pendingChange, setPendingChange] = useState<number|null>()
    function onChange(val:number){
        if (onClick){
            let res = onClick(val)
            if (res===false) return
            setPendingChange(val)
     
        }
    }
    useEffect(()=>{
        if (value == pendingChange) {
            setPendingChange(undefined)
        }},[value])
    return (
        <Box direction="row" align="center" focusIndicator={false} 
        flex={false} gap="5px"
        pad="2px" margin="2px"
        {...rest}>
            
            <Button disabled={!onClick} className={(pendingChange===0 ||( value ===0 && pendingChange===null) )?"wait-background":undefined} pad="1px" border={false} icon={value!==0?<AiOutlineDislike size={size}/>:<AiTwotoneDislike size={size}/>} 
            onClick={!onClick ?undefined: ()=>onChange(value==0?null:0)} />
            <Button disabled={!onClick} className={(pendingChange===1 ||( value ===1 && pendingChange===null))?"wait-background":undefined} pad="1px" border={false} icon={value!==1?<AiOutlineLike size={size}/>:<AiTwotoneLike size={size}/>} 
            onClick={!onClick ?undefined:()=>{ onChange(value==1?null:1)
            }} />
            
            
        </Box>
    )

}