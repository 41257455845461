
import {CgBrackets} from "react-icons/cg"
import { TbHeartRateMonitor } from "react-icons/tb"
import { ImStatsBars, ImPieChart } from "react-icons/im"
import { GiPieChart } from "react-icons/gi"
import { FcSettings } from "react-icons/fc"
import { AiFillSetting, AiOutlinePlaySquare, AiTwotoneExperiment } from "react-icons/ai"
import { IoIosQrScanner } from "react-icons/io"
import { MdOutlineTimeline } from "react-icons/md"
import {VscHistory, VscVmRunning} from "react-icons/vsc"
import { Box, Text } from "grommet"
import { BiMessageSquare, BiMessageSquareDetail, BiMessageSquareDots } from "react-icons/bi"
import { GrTest } from "react-icons/gr"
import { BsPlayFill } from "react-icons/bs"

const sizes=["xsmall","small","medium","large","xlarge"]

function getSizePx(size, ranges:number[]){
    for (let i=0;i<sizes.length;i++){
        if (size==sizes[i]){
            return ranges[i] + "px"
        }
    }
    return size
}

export const Logo =({ size,withText, ...rest}:{
    size?:string,
    withText?:boolean,
    [key:string]:any
})=>{
    if (withText){
        return(<Box direction="row" {...rest} align="center" gap={getSizePx(size,[2,3, 5,8,10]) }>

            <BiMessageSquareDots color="#6FFFB0" size={getSizePx(size,[10,15,20,35,60])}  />
            <Text size={getSizePx(size,[8,12,15,25,30])} color="accent-1" weight={900}>
                PromptWatch.io
            </Text>
            </Box>)
    }
    else
    return <BiMessageSquareDots color="#6FFFB0" size={getSizePx(size,[10,15,20,35,60])}  {...rest}/>
    //<BiStats/>
}

export const SessionsIcon =({size,...rest}:any)=>{
    return  <Box margin="0px 2px 0px -2px"> <VscHistory     size={getSizePx(size,[10,15,20,30,40])}  {...rest}/></Box>
}

export const StatisticsIcon =({size,...rest}:any)=>{
    //    <ImStatsBars {...rest}/>
    //<GiPieChart/>
    return  <ImPieChart  size={getSizePx(size,[9,13,18,26,36])} />
}

export const SettingsIcon =({size,...rest}:any)=>{
    //    <ImStatsBars {...rest}/>
    //<GiPieChart/>
    return  <AiFillSetting  size={getSizePx(size,[10,15,20,30,40])} />
}

export const PromptTemplateIcon =({size,...rest}:any)=>{
    //    <ImStatsBars {...rest}/>
    //<GiPieChart/>
    return  <BiMessageSquareDetail  size={getSizePx(size,[10,15,17,30,40])} />
}

export const UnitTestIcon =({size,...rest}:any)=>{
    //    <ImStatsBars {...rest}/>
    //<GiPieChart/>
    return  <AiTwotoneExperiment  size={getSizePx(size,[10,15,17,30,40])} />
}

export const UnitTestRunIcon =({size,...rest}:any)=>{
    //    <ImStatsBars {...rest}/>
    //<GiPieChart/>
    return  <BsPlayFill  size={getSizePx(size,[10,15,17,30,40])} />
}

