
import { Box, Button, InfiniteScroll, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";

import Popup from "../Popup/popup";

import { Session} from "../../models/dataModel";

import { getApi } from "../../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SliderButton } from '@typeform/embed-react'
import {FiCornerRightDown} from "react-icons/fi"

import Moment from 'react-moment';
import  SessionsFilter  from "../StatisticsWindow/sessionsFilter";
import ShrinkingHeader from "../ShrinkingHeader/shrinkingHeader";
import ShrinkingHeaderContainer from "../ShrinkingHeader/shrinkingHeader";

import "./sessions.css"
import Badge from "../Badge/badge";
import { BsStopwatch } from "react-icons/bs";
import { MdError, MdErrorOutline } from "react-icons/md";
import { BiError } from "react-icons/bi";
import { LoadingCover } from "../LoadingCover/loadingCover";
import { ProjectBadge, TenantBadge } from "../CommonElements/commonBadges";



export  default function Sessions({header, darkMode, onSessionClick}:{
    header?:any
    darkMode?:boolean
    onSessionClick:(session_id:string)=>any,
}){

    
    
    const [queryParams,setQueryParams]  =useSearchParams()
    const tempApiKey = useMemo(()=>queryParams?.get("temp-api-key"),[queryParams])
    const [popup, setPopup]=useState<any>()
    const [loading, setLoading]=useState<boolean>()
    
    const [sessions, setSessions]=useState<Session[]>()
    const [hasMoreData, setHasMoreData]=useState<any>(true)

    
    const [textFilter,setTextFilter] = useState<string>()
    const now = new Date();
    now.setHours(now.getHours()+1, 0, 0, 0)
    const [filter, setFilter]=useState<any>()
    
    function updateQueryParamsIfNeeded(){
        if (!filter) return
        let oldQueryParams:any={}
        queryParams.forEach((value, key) => oldQueryParams[key] = value);
        let _queryParams ={...oldQueryParams}
        
        if (textFilter || _queryParams["query"]) _queryParams["query"]=textFilter
        if (filter?.since||_queryParams["since"]) _queryParams["since"]=filter.since?.toISOString()
        if (filter?.until||_queryParams["until"]) _queryParams["until"]=filter.until?.toISOString()
        if (filter?.tracking_project||_queryParams["tracking_project"]) _queryParams["tracking_project"]=filter.tracking_project
        if (filter?.tracking_tenant||_queryParams["tracking_tenant"]) _queryParams["tracking_tenant"]=filter.tracking_tenant
         
        if (JSON.stringify(oldQueryParams)!=JSON.stringify(_queryParams)){
            setQueryParams(new URLSearchParams(_queryParams as any))
            return _queryParams
        }

    }
    useEffect(()=>{
        if (!filter) {
            //initial load from url
            const parseParam=(paramName:string)=>{
                if (queryParams.get(paramName) && queryParams.get(paramName)!="undefined"){
                    return new Date(queryParams.get(paramName))
                }
                else return undefined
            }
            const defaultDateRange = { since: new Date(new Date().getTime()-(60*60*24*1000)), until: null }
            let newFilter = {
            since:parseParam("since")||defaultDateRange.since,
            until:parseParam("until") ||  defaultDateRange.until,
            query:queryParams.get("query"),
            tracking_project:queryParams.get("tracking_project"),
            tracking_tenant:queryParams.get("tracking_tenant"),
        }
            setFilter(newFilter)
            loadData(false,newFilter)
            return

        }
        let newFilter = updateQueryParamsIfNeeded()
        if (newFilter ){
            setHasMoreData(true)
            loadData(true,filter)
        }
    },[filter])



    function loadData(reload=false, customFilter:any=null){
        let skip = reload?0:sessions?.length
        const pageSize=100
        if (!hasMoreData && !reload) return
        let currentParams = Object.fromEntries(queryParams.entries());
        let query={...(customFilter||filter||{}) , skip:skip,limit:pageSize}
        if (currentParams["temp-api-key"]) {
            query["temp-api-key"]=currentParams["temp-api-key"]
        }
        let _queryParams = query
        if (_queryParams.since?.toISOString) _queryParams.since = _queryParams.since?.toISOString()
        if (_queryParams.until?.toISOString) _queryParams.until = _queryParams.until?.toISOString()
        _queryParams = Object.entries(_queryParams).filter(([_, v]) => v != null && v != undefined).forEach(([k, v]) => queryParams.set(k,v as string));
        
        setQueryParams(queryParams)
        
        
        setLoading(true)
        getApi().getSessions(query).then(data=>{
            if (data?.length){
                
                let newSessions= reload?data:[...(sessions||[]),...data]
                setSessions(newSessions)
                if (data.length<pageSize){
                    setHasMoreData(false)
                }
            }
            else{
                if (reload) setSessions([])
                setHasMoreData(false)
            }
        }).finally(()=>setLoading(false))
    }

    
 

    return (
        
            <>

        <Box justify="start" 
        overflow="auto"
        background="dark-3"
        className="second-level-sidebar sessionsPanel"
        height="calc(100vh - 40px)"
        

        >
            {loading&&<LoadingCover/>}  
            
            {popup&& <Popup
            closeOnClickOutside={true}
            onClose={()=>setPopup(undefined)}
            >
                {popup}
            </Popup>}
            
            <div className="pageHeader">
            {header}
           { filter && <SessionsFilter  darkMode filter={filter}
            onRefresh={()=>{
                loadData(true)
            }}
            onFilterChange={(filter)=>{
                setFilter(filter)
            }}
            onTextFilterChange={val=>{
                setTextFilter(val)
                
                setFilter({...filter, query:val})
            }}
            />}
            </div>
            
               
                <Box alignSelf="center"  pad="5px" justify="center" gap="medium" width="100%"  flex={false} background="dark-4">
                 {sessions?.length?(

                <InfiniteScroll 
                items = {sessions}
                renderMarker={(marker)=>(
                    marker
                )}
                onMore={() => { 
                    loadData()
                 }
                }
                >
                    {(s:Session,i,ref)=>(
                        <Box key={i} background={!s.is_error?"rgba(255,255,255,0.95)":"rgba(255,230,230,0.8)"} border 
                        className={"sessionBox" + (s.is_error?" error":"")}
                        flex={false}
                        
                        round="8px"
                        pad="2px 2px 2px 5px"
                        
    
                        onClick={()=>{
                            onSessionClick(s.id)
                        }}
                        >
                       
                                    <Box justify="between" direction="row">

                                        <Text color="dark-1" size="xsmall" >id: {s.id} </Text>
                                        {s.is_error ? (<BiError color="red" />) : ""}
                                    </Box>
                                    <Box pad="10px 5px" style={{maxHeight:"150px"}} overflow="hidden">

                                        {s.session_name ?
                                        (
                                            <Text  weight={700} >
                                            {s.session_name}
                                            </Text>
                                        ):(
                                            <Box pad="1px 5px">

                                                <Text size="small"  color="gray" weight={200}>/nameless session/</Text>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box direction="row" align="center" justify="start">
                                    {s.tracking_project &&  <ProjectBadge tracking_project={s.tracking_project} />}
                                    {s.tracking_tenant &&  <TenantBadge tracking_project={s.tracking_tenant} />}
                                    
                                    </Box>
                                    <Box direction="row" align="center" justify="between" wrap>

                                        <Text size="small" color="brand">
                                            <Moment format="MMM D YYYY hh:mm:ss">{s.start_time}</Moment>
                                        </Text>
                                        <Badge>

                                            <Text size="small" color="dark-3">
                                                <Moment fromNow>{s.start_time}</Moment>
                                            </Text>
                                        </Badge>

                                        

                                        <Badge
                                            icon={<BsStopwatch />}
                                            text={
                                                <Text size="small" color="dark-3">
                                                    {s.end_time ? <Moment duration={s.start_time} date={s.end_time} /> : "n/a"}
                                                </Text>}
                                        />
                                    </Box>
                                </Box>
                    )}
                </InfiniteScroll>
                    
                
                ):(
                    <Box gap="5px">
                        <Text  color="light-1">No sessions in this time range</Text>
                        {textFilter&&<Text weight={900} color="light-1">To use text filter - include whole words</Text>}
                    </Box>
                )}
                {tempApiKey&&(
                    <Box gap="5px" pad="10px" round="5px" border background="accent-1" margin="15px">
                        <Text size="large" color="dark-3" weight={900} >Using temporary API key</Text>
                        <Text  >Only the last session is being tracked</Text>
                    </Box>
                )}


                </Box>
        </Box>
        
            </>
    )
}