
import { Avatar, Box,    Heading,    Text } from "grommet";
import { useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session} from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";
import Button from "../components/Button/button";
import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";
import UserAvatar from "../components/UserAvatar/userAvatar";
import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";


export  default function LoginPage({mode}:{mode:"signup"|"login"}){
    
    const {currentUser, logOut} = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        if (currentUser && searchParams.get("force")!="true" ){
            navigate("/home")
        }
    },[currentUser])

    


    return (
        <Box  background="dark-1"  align="stretch" justify="stretch" height="100vh" overflow="auto"flex={false}>
        <Box alignSelf="start" margin="10px 10px 0px"  flex={false}  onClick={()=>navigate("/")}>
            

            <Logo size="xlarge" withText/>
           
            
        </Box>
     
        <Box pad="medium" flex={false} gap="small" >

            {currentUser?(
                <Box alignSelf="center" gap="small">
                    <Heading>Welcome</Heading>
                    <UserAvatar showName/>
                    <Box  align="end">

                        <Button secondary  icon={<MdLogout/>} text="Logout" onClick={()=>logOut()}/>
                    </Box>
                    <Text weight={900}> --- or ---</Text>
                    <Button primary size="large"  text="Come in!" onClick={()=>navigate("/home")}/>
                </Box>
            ):(

                <LoginComponent mode={mode}/>
                )
            }
            
        
        </Box>
        </Box>
    )
}