import { Box, Heading, Text } from 'grommet';
import { useState } from 'react';
import { firebaseApp } from '../../firebase/firebaseApp';
import "./loginBox.css"

import { getAuth,
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signInWithPopup, sendPasswordResetEmail,
    GoogleAuthProvider,GithubAuthProvider } from "firebase/auth";
import { useAppContext } from '../../appContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../Button/button';
import { FcGoogle } from 'react-icons/fc';
import { FaGithub } from 'react-icons/fa';

const LoginComponent = ({mode}:{
    mode:"login"|"signup"
}) => {
    const [email, setEmail] = useState('');
    const [password, _setPassword] = useState('');
    const [password2, _setPassword2] = useState('');
    const [error, setError] = useState('');
    const [wrongPassword, setWrongPassword] = useState<boolean|string>(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const {currentUser,setCurrentUser} = useAppContext()
    const navigate = useNavigate()
    const setPassword = (val: string) => {
        _setPassword(val)
        if (password2 && val && val != password2) {
            setError("Password do not match")
        }
        else {
            setError("")
        }
    }

    const setPassword2 = (val: string) => {
        _setPassword2(val)
        if (password && val && val != password) {
            setError("Password do not match")
        } else {
            setError("")
        }
    }


    const handleRegisterWithProvider = (provider) => {
        const auth = getAuth(firebaseApp);
        console.log("login")
        setError("")
        signInWithPopup(auth, new provider())
          .then((result) => {
            console.log(result)
            // This gives you a provider Access Token. You can use it to access the provider API.
            
            
            // The signed-in user info.
            const user = result.user;
            setCurrentUser(user)
            
            
            // IdP data available using getAdditionalUserInfo(result)
            // ...
          }).catch((error) => {
            console.error(error)
            const errorMessage = error.message;
            setError(errorMessage.replace("Firebase: ",""))
            // ...
          });
      };
      

    const login = (auth=undefined)=>{
        if (!email){
            setError("Please fill up your email")
            return
        }
        if (!password){
            setError("Please fill up your password")
            return
        }
          const _auth = auth || getAuth(firebaseApp);
          setError("")
          return signInWithEmailAndPassword(_auth, email,password).then(userCredential=>{
              setCurrentUser(userCredential.user)
              const followup = searchParams.get("followup")

              navigate(followup||"/home")
            })
        }

        
        
    const handleEmailRegister = async (event: React.FormEvent<HTMLFormElement>) => {

        if (!email){
            setError("Please fill up your email")
            return
        }
        if (!password){
            setError("Please fill up your password")
            return
        }
        
            event.preventDefault();
            
            const auth = getAuth(firebaseApp);
            if (mode=="login"){
                login().catch(error=>{
                    setError(error.message.replace("Firebase: ",""))
                    if (error.code="auth/wrong-password"){
                        setWrongPassword(true)
                    }
                } )   
            }
            else{
                if (!password2){
                    setError("Please re enter your password")
                    return
                }
                if (password!==password2){
                    setError("Passwords do not match")
                    return
                }
                createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    setCurrentUser(userCredential.user)
                    const followup = searchParams.get("followup")
                    navigate(followup||"/home")
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    if ("auth/email-already-in-use"){
                        login(auth)
                        .catch(error=>{
                            if (mode=="signup"){
                                //Print the original message
                                setError(errorMessage.replace("Firebase: ",""))            
                            }
                            else{
                                setError(error.message.replace("Firebase: ",""))            
                            }
                        })
                    } 
                });
            }
        }

   

    return (
        <Box className='login-form' background="dark-2"  round="8px" >

            <Heading level={2} color="accent-1">{mode==="signup"?"Sign up":"Login"}</Heading>
            {currentUser && <Text>Welcome {currentUser?.name}</Text>}
            <form onSubmit={handleEmailRegister}>
                <Box gap='15px' >
                    <Box>
                        <Box align='start'>
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" value={email} onChange={event => setEmail(event.target.value)} />
                        </Box>
                        <Box align='start'>
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </Box>
                        {mode=="signup"&&(

                            <Box align='start'>
                                <label htmlFor="password2">Re Enter Password</label>
                                <input type="password" id="password" value={password2} onChange={e => setPassword2(e.target.value)} />
                            </Box>
                            )}
                    </Box>

                    {error && (
                        <Box round="4px" pad="5px" background="rgb(255,225,225)" margin="0px 20px">
                            <Text weight={900} color="red" >{error}</Text>
                        </Box>
                    )}
                        {wrongPassword && email &&(
                            wrongPassword!="sent"?(

                                <Box direction='row' gap="3px" justify='center' align='center'>
                                <Text>Forgot your password?</Text>
                                <Box focusIndicator={false} onClick={()=>{
                                    const auth=getAuth(firebaseApp)
                                    sendPasswordResetEmail(auth, email)
                                    setWrongPassword("sent")
                                }}><Text color="accent-1">Click here to restore it!</Text>
                                </Box>
                            </Box>
                                ):(
                                    <Text color="accent-1">An email with restore link has been sent to your email address! Please check your inbox (and your spam folder;)</Text>
                                )
                        )}
                    
                    <Box gap="small" margin="0px 0px 10px">


                        <button type="submit" >{mode==="signup"?"Signup":"Login"}</button>
                        <Box pad="5px 0px 15px"  width="100%" flex="grow" >
                            <Box style={{borderBottom:"1px solid white"}}  width="100%" flex="grow" align='center'>
                                <Box margin="0px 0px -12px 0px" background="dark-2" pad="0px 10px">or</Box>
                            </Box>
                        </Box>
                        <Button onClick={()=>handleRegisterWithProvider(GoogleAuthProvider)} background="light-1" round="3px"> <FcGoogle size="20px"/> <Text weight={900}>Continue with Google</Text></Button>
                        <Button onClick={()=>handleRegisterWithProvider(GithubAuthProvider)} background="light-1" round="3px">< FaGithub size="20px"/> <Text weight={900}>Continue with GitHub</Text></Button>
                    </Box>
                    {mode=="signup"?
                    (
                        <Button secondary onClick={()=>navigate("/login")}><Text weight={900}>Got an account? Login</Text></Button>
                    ):
                    (
                        <>
                        <Text>Not have an account yet?</Text>
                        <Button secondary pad="5px" onClick={()=>navigate("/signup")} round="3px"><Text  weight={900}>Sign up</Text></Button>
                        </>
                    )
                    }
                </Box>
            </form>
        </Box>
    );
};

export default LoginComponent;