


import { Box, Meter, Spinner, Stack, Text, TextArea, TextInput, Tip } from "grommet"
import { PromptTemplateDefinition, PromptTemplateVersion, PromptUnitTest, PromptUnitTestRun, TestCaseResult } from "../../models/dataModel"


import { useDebugValue, useEffect, useMemo, useState } from "react"
import { getApi } from "../../apiService"
import "./styles.css"
import Badge from "../Badge/badge"
import Moment from "react-moment"
import { BsStopwatch } from "react-icons/bs"
import TestResultDetail from "./testCaseResultDetail"
import { FaCode, FaQuestion } from "react-icons/fa"

import { AiOutlineLike } from "react-icons/ai"

import { PromptTemplateIcon, UnitTestRunIcon } from "../CommonIcons/commonIcons"
import { GrDocumentTest } from "react-icons/gr"
import SourceDataBadge from "./sourceDataBadge"
import { useNavigate } from "react-router-dom"
import { HiExternalLink } from "react-icons/hi"
import { FiSettings } from "react-icons/fi"
import Button from "../Button/button"
import { MdRefresh } from "react-icons/md"

function nonNan(val: number) {
    return isNaN(val) ? 0 : val
}
export default function PromptUnitTestRunResults({ unitTestRun,applyTestRunUpdate }: {
    unitTestRun?: PromptUnitTestRun,
    applyTestRunUpdate?: (val: PromptUnitTestRun) => any

}) {
    const [popup, setPopup] = useState<any>()

    const [loading, setLoading] = useState<any>()
    const [pageSize, setPageSize] = useState<number>(100)
    const [hasMore, setHasMore] = useState<boolean>(undefined)

    const [data, setData] = useState<TestCaseResult[]>()

    const load=()=>{
        if (unitTestRun) {
            setLoading(true)
            getApi().getUnitTestRunDetails(unitTestRun.test_name, unitTestRun.run_id, 0, pageSize).then(d => {
                setData(d)
                setHasMore(d.length == pageSize)
            }).finally(() => setLoading(false))
        }
    }
    const navigate = useNavigate()
    useEffect(() => {
        load()
    }, [unitTestRun, pageSize])

    if (!unitTestRun) {
        return <></>
    }
    // const isLikedResult = useMemo(() =>{
    //         let generated_str = JSON.stringify(testCaseResult.generated_result) 
    //         JSON.stringify()}
    //      , [unitTestRun]


    function update_feedback_label(run_id:string, testCaseResultId:string ,feedback_label:number){
        getApi().patchUnitTestRunDetails(run_id, testCaseResultId,{llm_prompt:{feedback_label:feedback_label}}).then((updated)=>{
            let index = data.findIndex(d=>d.id==testCaseResultId)
            if (index>=0){
                let newData = [...data]
                newData[index]=updated
                setData([...newData])
                getApi().getUnitTestRun(unitTestRun.run_id).then((updatedRun)=>{
                    applyTestRunUpdate(updatedRun)
                })
            }
        })
    }

    if (!unitTestRun){
        return <Box align="center" justify="center" pad="20px"><Spinner size="large"/></Box>
     }

    return (
        <Box direction="row" flex={false}>

            <Box fill >
                {popup}
                <Box className="run-details-header">
                    <Box direction="row" wrap justify="between" align="center" flex={false} pad="10px 10px 5px">
                        <Box direction="row" gap="5px" wrap>

                            <Text color="brand" size="xlarge" >Unit test </Text>

                            <Box className="prompt-test-run-badge" onClick={()=>navigate("/unit-tests?test_name="+unitTestRun.test_name)} direction="row" gap="5px">
                                <Text color="brand" size="xlarge" weight={900}>{unitTestRun.test_name} </Text>
                                <Text color="brand" size="16px" weight={900}> <FiSettings /></Text>
                            </Box>
                            <Text color="brand" size="xlarge" ><UnitTestRunIcon/></Text><Text color="brand" size="xlarge" > run results</Text>
                        </Box>
                    
                        <Box direction="row">
                            <Badge tip={"Started at "+unitTestRun.start_time}>
                            <Text size="small" color="dark-3">                            
                                <Moment fromNow>{unitTestRun.start_time}</Moment>
                            </Text>
                            </Badge>
                            <Badge
                                icon={<BsStopwatch />}
                                text={
                                    <Text size="small" color="dark-3">
                                        {unitTestRun.end_time ? <Moment duration={unitTestRun.start_time} date={unitTestRun.end_time} /> : "n/a"}
                                    </Text>}
                            />
                        </Box>
                    </Box>
                    
                    <Box  direction="row" wrap justify="between" align="end">
                        <Box pad="5px">
                            
                        <Box direction="row" wrap align="center" gap="5px">
                            <Text color="brand" weight={900}>Source of data</Text>
                            <SourceDataBadge conditions={unitTestRun.conditions}/>
                            
                        </Box>
                        <Box style={{maxWidth:"300px"}}>
                            <Box direction="row" justify="between" width="100%" align="center"  >
                                <Text color="brand" weight={900}>Passed test cases</Text>
                                <Badge >
                                    <Text color="dark-1" weight={900}>{unitTestRun.results.passed}/{unitTestRun.results.total_processed} </Text>
                                </Badge>
                            </Box>
                            <Meter

                                round thickness="small"
                                max={unitTestRun.results.total_processed}
                                background={"light-4"}
                                values={[{
                                    value: unitTestRun.results.passed,
                                    

                                }]}
                                aria-label="meter"
                            />
                            </Box>
                        </Box>
                        <Box width="180px"  align="between" direction="row" justify="between" margin="0px 10px">
                            <Text weight={900} color="brand" size="large">Average score:</Text>
                            
                            <Stack anchor="bottom" margin="10px 0px 0px -50px">
                                <Meter
                                type="semicircle"
                                background="light-2"
                                max={100}
                                
                                values={[{
                                    value: (Math.round(100*unitTestRun.results.overall_score)),
                                    

                                }]}
                                size="xsmall"
                                thickness="small"
                                />
                                <Box direction="row" align="end" pad={{ bottom: 'xsmall' }}>
                                <Text size="xlarge" weight="bold">
                                    {nonNan(Math.round(100*unitTestRun.results.overall_score)) }
                                </Text>
                                <Text size="small">%</Text>
                                </Box>
                            </Stack>
                            
                        </Box>
                    </Box>
                </Box>
                <Box

                    flex={false}

                    overflow="auto"
                >
                    {data?.map(testCaseResult => (
                        <TestResultDetail test_name={unitTestRun.test_name} testCaseResult={testCaseResult} onUpdateFeedback={val=>update_feedback_label(unitTestRun.run_id,testCaseResult.id,val )}/>
                    ))} 
                    
                    {loading && <Box align="center" justify="center" pad="20px"><Spinner size="large"/></Box>}

                    {data && data.length==0 && hasMore==false && <Box background="light-4" margin="20px" pad="20px" round="5px" align="center">
                        <Text size="xxlarge">👀 🤷‍♂️</Text>
                        <Text weight={900}>It seams that the test was run without any test cases</Text>
                        <Badge icon={<FaQuestion/>} text="Why this happened" tip={()=>{
                            
                             if (!unitTestRun.conditions){
                                 return <Text>It seams that you used <code>for_test_cases([*items])</code> method, but the list was empty. Check your code</Text>
                             }
                             else if (unitTestRun.conditions?.for_template_name){
                                return <Box>
                                    <Text>This test was run for the template {unitTestRun.conditions?.for_template_name}`</Text>
                                    <Text>but the template does not have any test cases (or it didn't at the time of this test run).</Text>
                                    <Text>You can create test cases by annotating some of the previous runs of this template using <AiOutlineLike/></Text>
                                </Box>
                            }
                            else if (unitTestRun.conditions?.for_tracking_project){
                                return <Box>
                                    <Text>This test was run for tracking project {unitTestRun.conditions?.for_tracking_project}`</Text>
                                    <Text>The UnitTest was either interrupted, or there were no annotated sessions for it.</Text>
                                    <Text>You need to annotate some root chains using <AiOutlineLike/> for this tracking_project</Text>
                                    
                                    

                                </Box>
                            }
                            else
                             return <Text>Ou.. I don't know actually</Text>

                        }}/>
                    </Box>}

                    {!unitTestRun.end_time && (
                        <Button text="Refresh" onClick={()=>load()} alignSelf="center" icon={<MdRefresh/>}/>
                    )}

                </Box>

            </Box>


        </Box>
    )


}



