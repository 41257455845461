import { Box, Text } from "grommet"
import CodeBlock from "../CodeBlock/codeBlock"
import { MdInfo } from "react-icons/md"

const HowToDefineTrackingProject = () => {
    return (
        <Box flex={false} pad="20px" gap="15px">
            <Text size="large">You can either setup your tracking_project as a PromptWatch parameter</Text>
            <CodeBlock highlightLines={[5]}>
{`from promptwatch import PromptWatch

my_chain = build_chain()

with PromptWatch(tracking_project="define_your_project_id") as pw:
    my_chain("The quick brown fox jumped over")
`}
            </CodeBlock>

<Box margin="10px 0px">

            <Text size="large">Or you can simply define it as an <b>ENV variable</b>  <code className="inline">PROMPTWATCH_TRACKING_PROJECT</code></Text>


            <Text >It has to be max 256 char long and without white-spaces</Text>
</Box>
        </Box>
    )
}

export default HowToDefineTrackingProject