


import { Box, Button,  Spinner, Text, TextArea, TextInput } from "grommet"
import { Action, ActivityBase, LlmPrompt, Log, ParallelPrompt, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillQuestionSquareFill} from "react-icons/bs"
import { useState } from "react"
import LlmPromptComponent from "./LlmPrompt"



export  default function ParallelThoughtsComponent({ activity, allActivities, onClick}:{
    activity:ParallelPrompt
    allActivities?:ActivityBase[]
    onClick?:()=>any
}){
  
    


    return (
        <Box flex="grow"  pad="2px" round="5px" background="white" justify="between" onClick={()=>onClick && onClick()}>
            {activity.prompts.map(t=>(
                <LlmPromptComponent activity={t}/>
            ))}
           
           
           
        </Box>    
    )

    
}

