import { FiExternalLink } from "react-icons/fi";
import Button from "../Button/button";
import { Text } from "grommet";
import Badge from "../Badge/badge";
import "./promptTemplateBadge.css"

export default function PromptTemplateBadge({ promptTemplate }: {promptTemplate:any}) {

    if (!promptTemplate?.template_name) return (

        <Badge text="unregistered" border={false} textProps={{size:"small"}} background="light-3" 
        tip="Used template is not registered in PromptWatch. Please follow instructions in documentation if you want to track the template version"/>
        )

    return <Button  pad="2px 5px"  className="prompt-template-badge"
        onClick={()=>{window.open("/prompt-templates?template_name="+encodeURIComponent(promptTemplate?.template_name)+(promptTemplate?.template_version_final?"&v="+encodeURIComponent(promptTemplate?.template_version_final):""), "_blank")}}
            secondary>
                                    <Text size="11px">

                                    {promptTemplate?.template_name+" "}
                                    {promptTemplate?.template_version_final && 
                                    <mark>v{promptTemplate?.template_version_final}</mark>}
                                    {/* {promptTemplate?.template_version_final && 
                                        <Badge text={"v"+promptTemplate?.template_version_final} background="pink" round="2px" border={false} textProps={{size:"small"}}/>
                                    } */}
                                    </Text>
                                    <FiExternalLink size="10px" />
                                </Button>
}