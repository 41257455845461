


import { Box,   Spinner, Text, TextArea, TextInput, Tip } from "grommet"
import { Action, ActivityBase, LlmPrompt, Log, Question } from "../../models/dataModel"
import { HighlightableText } from "../HiglightableText/HighlithableText"
import {BsFillChatLeftDotsFill, BsFillQuestionSquareFill, BsInfo, BsPatchQuestion, BsStopwatch} from "react-icons/bs"
import { useEffect, useMemo, useState } from "react"
import Button from "../Button/button"
import Popup from "../Popup/popup"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import { FormattedPrompt } from "../FormatedPrompt/formatedPrompt"
import { ActionCostsBadge } from "../ActionCostsBadge/actionCostsBadge"
import { FiTool } from "react-icons/fi"
import { GoNote } from "react-icons/go"
import {  TbListSearch } from "react-icons/tb"
import Badge from "../Badge/badge"
import Moment from "react-moment"
import { PromptTemplateIcon } from "../CommonIcons/commonIcons"
import { BiBrain } from "react-icons/bi"
import { GiBrain } from "react-icons/gi"
import { MdDataSaverOff } from "react-icons/md"
import { VscDebugConsole, VscDebugRerun } from "react-icons/vsc"
import { LlmPlayground } from "../LlmPlayground/LlmPlayground"




export  default function ActivityHeader({ activity, children}:{
    activity:ActivityBase
    children?:any
}){
  
    
    const [popup, setPopup] = useState<any>()

    function getActivityComponent(activity:any){
        if (activity?.activity_type=="action"){
            return  <Box direction="row" gap="xsmall" >
                        <FiTool/><Text size="small" color="brand" >
                            <Text size="small" weight={900}>{activity.tool_type}</Text>
                        </Text>
                    </Box>
        }
        else if (activity?.activity_type=="question"){
            return  <></>
        }
        else if (activity?.activity_type=="answer"){
            return  <></>
        }
        else if (activity?.activity_type=="log"){
            return  <Box direction="row" gap="xsmall" >
                        <GoNote/><Text size="small" color="brand" >Log</Text>
                    </Box>
        }
        else if (activity?.activity_type=="llm-prompt"){
            return  <Box direction="row" gap="xsmall" align="center" >
                        <BiBrain size="18px"/><Text size="small" color="brand" >LLM prompt</Text>
                    </Box>
        }
        else if (activity?.activity_type=="parallel-prompt"){
            return  <Box direction="row" gap="xsmall" align="center" >
                         <BiBrain size="18px"/><Text size="small" color="brand" >Parallel prompt</Text>
                    </Box>
        }
        else if (activity?.activity_type=="retrieved-docs"){
            return  <Box direction="row" gap="xsmall" >
                        <TbListSearch/><Text size="small" color="brand" >Retrieved documents</Text>
                    </Box>
        }
        else if (activity?.activity_type=="chain-sequence"){
            return <></>
        }
        else{
            return (<Text>{activity?.activity_type}</Text>)
        }
     }

    function getExtraButtons(activity:any){
        if (activity?.activity_type=="llm-prompt"){
            return  <Box direction="row"> <Button 
            round="25px"
            border={true}
            
            icon={<PromptTemplateIcon/>} size="10px" text="Show prompt"
            onClick={()=>{
                setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt" >
                    <Box pad="small">
                        <FormattedPrompt 
                            text={activity.prompt} 
                            template={activity.prompt_template} 
                            params={activity.prompt_input_values} 
                            generated={activity.generated}
                            metadata={activity.metadata}
                            />
                    </Box>
                </Popup>)
            }}
            />
            <Button 
            round="25px"
            border={true}
            
            icon={<VscDebugConsole size="15px"/>} size="10px" text="Debug in Playground"
            onClick={()=>{
                setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt" promptBeforeClose>
                    <Box pad="small">
                        <LlmPlayground 
                            sourcePrompt={activity.prompt} 
                            promptTemplate={activity.prompt_template.prompt_template} 
                            params={activity.prompt_input_values} 
                            llm_params={activity.metadata?.llm} 
                            generated={activity.generated}
                            functions={activity.metadata?.invocation_params?.functions}
                            function_call={activity.metadata?.invocation_params?.function_call}
                            />
                    </Box>
                </Popup>)
            }}
            />
            </Box>
        }
        else{
            return null
        }
     }

    return (
            <Box direction="row" justify="between" wrap>
                <Box>
                    {/* <Text>{activity.id} ({activity.parent_activity_id})</Text> */}
                    {getActivityComponent(activity)}
                </Box>
                <Box direction="row">
                    
                <ActionCostsBadge activity={activity}/>
                {children}{getExtraButtons(activity)}
                {activity?.metadata&& activity?.metadata.generation_info && activity?.metadata.generation_info.cached&&(
                    <Badge pad="5px" margin="0px 5px" 
                        tip={
                            <Box>
                            <KeyValuesComponent object={activity.metadata.generation_info} size="small"/>
                            </Box>
                        }
                        icon={<MdDataSaverOff/>} 
                        text={<Text size="small">Cached</Text>}/>
                ) }
                
                {activity?.metadata && activity.activity_type!=="chain-sequence" &&  Object.keys(activity?.metadata).length>0 && <Button 
                    border={false}
                    
                    icon={<BsInfo size="20px"/>} size="10px" pad="0px"
                    onClick={()=>{
                        setPopup(<Popup caption="Additional info" onClose={()=>setPopup(null)}>
                            <Box pad="small">
                                <Text>Metadata</Text>
                            <KeyValuesComponent object={activity.metadata} size="small"/>
                            </Box>
                        </Popup>)
                    }}
                />}
                {popup}
                </Box>
            </Box>
    )

    
}

