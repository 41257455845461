import { Text, TextInput } from "grommet";
import { FocusEventHandler, useEffect, useState } from "react";
import "./editableText.css"
const EditableTextBlock=({text, onChange, readonly=false, initValue,...rest}:
    {
        text:string
        readonly?:boolean
        onChange:(text:string)=>any
        initValue?:string
        [key:string]:any
    })=>{
       const [isBeingEdited,setIsBeingEdited] = useState<boolean>(initValue==text?true:false)
       const [value,setValue] = useState<string>(text)
       useEffect(()=>{

            setValue(text)

        },[text])
    
    if (isBeingEdited){
        return <TextInput className={readonly?undefined:"editableText"} value={value} autoFocus 
            onChange={e=>setValue(e.target.value)}
            onBlur={e=> {
                onChange(e.target.value)
                setIsBeingEdited(false)
            }}
            {...rest}
        />
    }else{
        return <Text className={readonly?undefined:"editableText"} {...rest} onClick={()=>!readonly && setIsBeingEdited(true)}>{value}</Text>
    }


}

export default EditableTextBlock;