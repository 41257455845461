import { Box } from "grommet"
import "./minibadge.css"

export const Minibadge=({children, color}:{color?:string, children:any})=>{
 
    return  <span 
                style={color?{backgroundColor:color}:undefined}
                className="minibadge-value">
                {children}
                </span>
    
}