import { ChatMessage } from "../models/dataModel";
import { SSE } from 'sse.js';
export class OpenAI{
    apiKey:string
    type:string
    _lastResponse:string
    

    constructor(apiKey: string,type:"chat"|"completion"){
        this.apiKey=apiKey
        this.type=type
        
    }

    _prepareParams(prompt:string|ChatMessage[], modelParams:any){
        let requestParams={...modelParams}
        if (!requestParams.stop?.length) {
            delete requestParams.stop
        }
        let endpointURL=undefined
        if (this.type=="chat"){
            requestParams["messages"] = (prompt as ChatMessage[]).map((msg)=>({role:msg.role, content:msg.text, name:msg.name}))
            endpointURL ="https://api.openai.com/v1/chat/completions"
            
        }else if(this.type=="completion"){
            requestParams["prompt"] = prompt
            endpointURL = "https://api.openai.com/v1/completions"
        }
        else{
            throw new Error("unsupported api type")
        }
        
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey}`
        }

        return {endpointURL, headers, requestParams}

    }

    

    async execute(
        prompt:string|ChatMessage[], modelParams:any, onNewToken?:(string)=>void
    ){
        
        let {endpointURL, headers, requestParams} = this._prepareParams(prompt, modelParams)
        let fetchPromise = undefined
        if (onNewToken){
            const _extractResponseText = (response:any)=>{
                if (this.type==="chat"){
                    return response.choices[0].message?.content || response.choices[0].delta?.content
                }
                else{
                    return response.choices[0].text || response.choices[0].delta?.text
                }
            }
            const _extractFunctionCall = (response:any)=>{
                if (this.type==="chat"){
                    return response.choices[0].message?.function_call || response.choices[0].delta?.function_call
                }
                
            }
            let _lastResponse=""
            let function_call_name=""
            let function_call_arguments=""
            fetchPromise = new Promise((resolve, reject) => {
                requestParams["stream"]=true
                let sse = new SSE(endpointURL, {
                    method: "POST",
                    headers: headers,
                    payload: JSON.stringify(requestParams)
                }
                )
                sse.addEventListener('message', function (res) {
                    if (res.data === '[DONE]') {
                        if (!res.readyState !== res.source?.CLOSED)
                            sse.close();
                        let func_call=undefined        
                        if( function_call_name){
                            
                            func_call={
                                name:function_call_name,
                                arguments:function_call_arguments
                            }
                        }
                        resolve({text:_lastResponse, function_call:func_call});
                    return;
                    }
                    
                    let responseJson = null;
                    try{
                        if ( res.data.includes(`"finish_reason":null}]}{"id":"`)){
                            res.data = "["+res.data.replace(`"finish_reason":null}]}{"id":"`,`"finish_reason":null}]},{"id":"`)+"]"
                        }
                        responseJson = JSON.parse(res.data)
                    }
                    catch{
                        console.log("sream error parsing json",res.data)
                        return
                    }
                    let responses=responseJson
                    if (!Array.isArray(responseJson)){
                        responses=[responseJson]
                    }
                    for (let i=0;i<responses.length;i++){   
                        let responseJson=responses[i]
                        let newToken = _extractResponseText(responseJson)
                        if (newToken){
                            _lastResponse+=newToken
                            onNewToken(newToken)
                        }
                        let deltaFunctionCall = _extractFunctionCall(responseJson)
                        if (deltaFunctionCall){
                            if (deltaFunctionCall.name){
                                function_call_name+=deltaFunctionCall.name
                            }
                            if (deltaFunctionCall.arguments){
                                function_call_arguments+=deltaFunctionCall.arguments
                            }
                        }
                    }


                    // if (responseJson["choices"][0].finish_reason){
                    //     let close_res = sse.close();
                    //     resolve(_lastResponse);
                    // }
                });

                sse.addEventListener('error', (err) =>{
                    if (err.data == '[DONE]') {
                        
                        return;
                    }
                    let error="unknown error"
                    if (err.data && typeof(err.data)==="string"){
                        try{
                            let data = JSON.parse(err.data)
                            if (data.message){
                                error = data.message
                            }
                            if (data.error.message){
                                error = data.error.message
                            }
                            else{
                                error = err.data
                            }
                        }
                        catch{
                            error = err.data
                        }
                    }
                    else if (err.data && err.data.message){
                        error = err.data.message
                    }
                    else if (err.data){
                        error=err.data
                    }
                    reject('OpenAI Completion action error: '+error)
                }
                );
                sse.addEventListener("readystatechange", (e) => {
                    console.log("readystatechange ",e)
                })
                return sse.stream();
            })
        }else{
            fetchPromise = fetch(endpointURL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestParams)
            }
            ).then( async r => {
                
                if (r.status > 300) {
                    r.json().then(errorData => {
                        throw Error(errorData.error.message)
                    })
                    console.error(r)
                }
                else {
                    let data = await  r.json()
                    if (this.type=="chat"){
                        return (data.choices[0]["message"]["content"])
                    }
                    else {
                        return(data.choices[0]["text"])
                    }
                
                }
            })
        }
        return fetchPromise
        
    }


}