
import { Avatar, Box, DataTable, Heading, ResponsiveContext, Text, Tip } from "grommet";
import { useContext, useEffect, useMemo, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session } from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";
import Button from "../components/Button/button";
import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";
import UserAvatar from "../components/UserAvatar/userAvatar";
import { MdLogout } from "react-icons/md";
import ChartWidget from "../components/Widgets/chartWidget";
import CardWidget from "../components/Widgets/cardWidget";
import WidgetBox from "../components/Widgets/WidgetBox";
import Loader from "../components/Widgets/loader";
import { ProjectBadge, TenantBadge } from "../components/CommonElements/commonBadges";
import { HiSparkles } from "react-icons/hi2";
import { VscDebugLineByLine } from "react-icons/vsc";
import { LlmPlayground } from "../components/LlmPlayground/LlmPlayground";




export default function DahsboardPage() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [popup, setPopup] = useState<any>()


    
    const until_now = new Date()
    const since_30d = new Date(until_now.getTime() - (30 * 24 * 60 * 60 * 1000));
    const size = useContext(ResponsiveContext);

   

    return (
        <Box  background="light-2"  flex={false} width="calc(100% - 80px)" height="100vh" overflow="auto" >
            {popup}
            <Box pad="2px 25px 2px" direction="row" justify="between" alignSelf="stretch" wrap flex={false}>
                <Text size="30px" weight={900} color="dark-2">
                    Welcome
                </Text>
                <Box align="end" gap="5px">

                    <Box pad="0px 10px" onClick={() => navigate("/settings")}>
                        <UserAvatar size={25} showName />
                    </Box>
                    <Box direction="row" gap="10px">
                        <Button alignSelf="end" margin="0px 0px 2px" primary size="large" icon={<VscDebugLineByLine size="20px" />}
                            text="LLM playground"
                            onClick={() =>   setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt" promptBeforeClose>
                            <Box pad="small">
                                <LlmPlayground 
                                    sourcePrompt={[]} 
                                    promptTemplate={[]} 
                                    params={{}} 
                                    //llm_params={activity.metadata?.llm} 
                                    //generated={activity.generated}
                                    functions={[]}
                                    function_call={undefined}
                                    />
                            </Box>
                        </Popup>)
                            } />
                        <Button alignSelf="end" margin="0px 0px 2px" primary size="large" icon={<HiSparkles size="20px" />}
                            text="Quick start"
                            onClick={() => navigate("/get-api-key")} />
                    </Box>
                </Box>
            </Box>
        

            <Box margin="0px 15px"  flex={false}>

                <Box direction="row"  justify="stretch" wrap overflow="hidden" flex={false} >


                    <Box>
                        <Loader getDataFunc={()=>getApi().getStats({metric:"count",since:since_30d, until:until_now,over:"time"})}>
                            { (sessionStats)=>
                                <CardWidget
                                    size="large"
                                    //tip="test"
                                    caption="Total sessions"
                                    note="last 30 days"
                                    loading={!sessionStats}
                                    value={sessionStats?.total}
                                    
                                    chart={sessionStats?.data && {
                                        data: sessionStats?.data ,
                                        //isCategorical:true,
                                        //data:{"A":423,B:234,C:456},
                                        //type:"bar-horizontal", 
                                        type: "area",
                                        position: "right"
                                    }}
                                />}
                        </Loader>
                        <Loader getDataFunc={()=>getApi().getSessions({skip:0, limit:20})}>
                            {data=>{
                            const hasTenants = data?.some(item => item.tracking_tenant)
                            const hasProjects = data?.some(item => item.tracking_project)
                            return (!(data && data.length==0) && <WidgetBox 
                                        caption="Last sessions" 
                                        topRightCorner={
                                            <Button
                                            text="Show more"
                                            onClick={()=>navigate("/sessions")}
                                            />
                                        }
                                        size="large"
                                    >
                                        <DataTable
                                        size="550px"
                                        pad="2px"
                                        onClickRow={(rec)=>{
                                            navigate("/sessions?session_id="+rec.datum.id)
                                        }}
                                        columns={[
                                                {
                                                property: 'session_name',
                                                //header: <Text>Name</Text>,
                                                primary: true,
                                                size: (hasProjects||hasTenants)?"1/2":"2/3" ,
                                                render:(item)=> 
                                                (<Box  flex="grow">
                                                    {item.session_name ?(

                                                    <Text truncate={true} color={item.is_error?"red":undefined}>
                                                    {item.session_name}
                                                    </Text>
                                                    ):(
                                                        <Box pad="1px 5px">

                                                            <Text size="small"  weight={200} color={item.is_error?"red":"gray"}>
                                                                /nameless session/
                                                            </Text>
                                                        </Box>
                                                    )}
                                                </Box>)
                                                },
                                                {
                                                property: 'tracking_project',
                                                // header: <Text>Project</Text>,
                                                size:"15%",
                                                render:(item)=> item.tracking_project && <Box align="center"> <ProjectBadge tracking_project={item.tracking_project} /></Box>
                                                },
                                                {
                                                    property: 'tracking_tenant',
                                                    primary:true,
                                                    //header: <Text>Name</Text>,
                                                    size:"15%",
                                                    render:(item)=> item.tracking_tenant && <Box align="center">  <TenantBadge tracking_project={item.tracking_tenant} /></Box>
                                                },
                                                {
                                                    property: 'start_time',
                                                    size:"15%",
                                                    render:(item)=> 
                                                        <Box pad="0px 8px" flex={false} background="rgb(230,230,230)" round alignSelf="center" >
                                                        <Text wordBreak="keep-all" className="no-wrap" size="small" weight={500} >
                                                            <Moment fromNow>{item.end_time || item.start_time}</Moment>
                                                            </Text>
                                                        </Box>
                                                    
                                                },
                                            ].filter(c=>(c.property !=="tracking_tenant"||hasTenants)&&(c.property !=="tracking_project"|| hasProjects)  )}
                                        data={data}
                                        />    
                                    </WidgetBox>)
                            }}
                        </Loader>
                    </Box>
                    <Box>
                    <Box direction="row">
                        <Box>
                        <Loader getDataFunc={()=>getApi().getStats({metric:"errors",since:since_30d, until:until_now,over:"time"})}>
                            { (stats)=>
                                 (stats?.data||1) ? (<CardWidget
                                 size="medium"
                                 loading={!stats}
                                 
                            
                                 caption="Errors"
                                 note="This month"
                                 value={stats?.total}
                                 
                                 chart={stats?.data && {
                                     //data: demo_data,
                                     color: "rgb(255,100,100)",
                                     data: stats?.data ,
                                     type: "line",
                                     //type: "area",
                                     position: "right"
                                 }}
                             />):(<></>)
                                }
                        </Loader>
                           
                        <Loader getDataFunc={()=>getApi().getStats({metric:"count",since:since_30d, until:until_now,over:"tracking_tenants"})}>
                            { (stats)=>
                                 stats?.data ? (<CardWidget
                                 size="medium"
                                 loading={!stats}
                                
                                 caption="Sessions per tenant"
                                 note="last 30 days"
                                 value={stats?.total}
                                 
                                 chart={stats?.data && {
                                     //data: demo_data,
                                     isCategorical: true,
                                     data: stats?.data ,
                                     type: "bar-horizontal",
                                     //type: "area",
                                     position: "bottom"
                                 }}
                             />):(<></>)
                                }
                        </Loader>
                        <Loader getDataFunc={()=>getApi().getStats({metric:"costs",since:since_30d, until:until_now,over:"tracking_tenants"})}>
                            { (stats)=>
                                 (stats?.data||1) ? (<CardWidget
                                 size="medium"
                                 loading={!stats}

                                 caption="Est. costs"
                                 note="last 30 days / per tenant"
                                 value_unit="$"
                                 value={stats?.total}
                                 
                                 chart={ !stats?.data ?(undefined):{
                                     //data: demo_data,
                                     isCategorical: true,
                                     data: stats?.data ||{},
                                     type: "bar-horizontal",
                                     //type: "area",
                                     position: "bottom"
                                 }}
                             />):(<></>)
                                }
                        </Loader>

                            {/* <CardWidget
                                size="medium"
                                tip="test"
                                caption="Est. costs"
                                note="This month / Per tenant"
                                value={1226}
                                value_unit="$"
                                chart={{

                                    isCategorical: true,
                                    data: { "A": 423, B: 234, "ABC": 456, D: 456 },
                                    type: "bar-horizontal",

                                    position: "bottom"
                                }}
                            /> */}

                        
                       
                        </Box>
                        <Box>
                            {/* <CardWidget
                                    size="medium"
                                    tip="test"
                                    caption="Most expensive prompts"
                                    note="Last 30 days"
                                    //value={1226}
                                    //value_unit="$"
                                    chart={{

                                        isCategorical: true,
                                        data: { "A": 423, B: 234, "ABC": 456, D: 456 },
                                        type: "bar-horizontal",

                                        position: "bottom"
                                    }}
                            /> */}
                            {/* <CardWidget
                                size="medium"
                                tip="test"
                                caption="Avg response duration"
                                note="Lost 30"
                                value={26}
                                value_unit="s"
                            /> */}
                           
                        {/* </Loader>
                            <CardWidget
                                size="small"
                                tip="test"
                                caption="Avg session duration"
                                note="This month"
                                value={26}
                                value_unit="s"
                            />
                            <CardWidget
                                size="small"
                                tip="test"
                                caption="Avg session costs"
                                note="This month"
                                value={26}
                                value_unit="s"
                            /> */}
                        </Box>
                    </Box>
                      

                    </Box>
                </Box>



            </Box>
        </Box>
    )
}