import axios, { Axios } from 'axios';
import { Session, ActivityBase,  LlmPrompt as LlmPrompt, PromptTemplateDefinition, PromptTemplateVersion, PromptUnitTest, PromptUnitTestRun, TestCaseResult, TestCase} from './models/dataModel';
import { getAuth, onAuthStateChanged } from "firebase/auth";

let api:ApiService = null;
function getApi(): ApiService{
  
    
    
    if (!(api)){ 
        const auth = getAuth();
        onAuthStateChanged(auth, (currentUser) => {
            if (api){
                api.currentUser=currentUser;
            }
        });

        api = new ApiService(process.env.REACT_APP_API_URL || "http://localhost:4080" )//, auth.currentUser);
        
        //api = new ApiService("http://localhost:4000/", auth.currentUser);
    }
    
    return api
}



class ApiService{
    baseUrl: string;
    
    currentUser: any;
    axiosInstance:Axios;
    

    constructor(baseUrl: string) {
      this.baseUrl = baseUrl ;

      
      this.axiosInstance= axios.create();
      
      this.axiosInstance.interceptors.request.use(
        async config =>  {
            if (!(api&&api.currentUser))
                await this.tryWaitForAuth(10)
            if ( api &&(  api.currentUser)){
                const token = await api.currentUser.getIdToken();
                if (token && config &&  config.headers) {
                    config.headers['authorization'] = 'Bearer ' + token;
                }
            }
            config.headers['Content-Type'] = 'application/json';
            
            const tempApiKey = new URLSearchParams(window.location.search).get('temp-api-key');
            if (tempApiKey){
              config.headers['x-api-key'] = tempApiKey;
            }
            
            return config;
        },
        error => {
            
            Promise.reject(error)
        });
        this.axiosInstance.interceptors.response.use(undefined, (error)=>{
            if (error.response && error.response.status===403){
                const tempApiKey = new URLSearchParams(window.location.search).get('temp-api-key');
                if (!tempApiKey){
                  (!window.location.href.endsWith("/login")) && setTimeout(()=>{window.location.href="/login?force=true"},500);
                }
                return Promise.resolve(error.response);

              } 
            else
                return Promise.reject(error);
        })
    }

    async tryWaitForAuth(countdown:number) {
      if (!this.currentUser && countdown>0){
          await new Promise(r => setTimeout(r, 200)); //Wait 1s for auth
          if (!this.currentUser){
              await this.tryWaitForAuth(countdown-1)
          }
      }
  }


    getSessions(filter?:{[key:string]:string|number|null|Date}): Promise<Session[]> {
        return this.axiosInstance.get(this.baseUrl + "/sessions", {params:filter})
          .then((response) => {
            return response.data;
          }); 
      }

    getSession(session_id): Promise<Session> {
        return this.axiosInstance.get(this.baseUrl +`/sessions/${session_id}`)
          .then((response) => {
            return response.data;
          }); 
      }

      getSessionShareApiKey(session_id): Promise<{"temp-api-key":string}> {
        return this.axiosInstance.post(this.baseUrl +`/sessions/${session_id}/share`)
          .then((response) => {
            return response.data;
          }); 
      }

    getTrackingTenants(): Promise<{name:string}[]> {
        return this.axiosInstance.get(this.baseUrl + "/meta/tracking-tenants")
          .then((response) => {
            return response.data;
          }); 
      }
      getTrackingProjects(): Promise<{name:string}[]> {
        return this.axiosInstance.get(this.baseUrl + "/meta/tracking-projects")
          .then((response) => {
            return response.data;
          }); 
      }

      getSessionStats(filter:{[key:string]:string|number|null|Date}): Promise<{[key:string]:number}> {
        return this.axiosInstance.get(this.baseUrl + "/stats/sessions/details", {params:filter})
          .then((response) => {
            return response.data;
          });

          
      }

    getStats({
      metric, since, until, tracking_project,tracking_tenant, over
    }:{
      metric:"count"|"errors"|"token_usage"|"costs", 
      since, 
      until, 
      tracking_project?,
      tracking_tenant?, 
      over?:"time"|"tracking_projects"|"tracking_tenants"
    }): Promise<StatsResponse> {

        return this.axiosInstance.get(this.baseUrl + "/stats/"+metric, {params:{since, until, tracking_project,tracking_tenant, over}})
          .then((response) => {
            return response.data;
          });

          
      }

    getActivity(activityId: string): Promise<ActivityBase> {
        return this.axiosInstance.get(this.baseUrl + `/activities/${activityId}`)
          .then((response) => {
            return response.data;
          });
      }

      getActivityWithChildren(activityId: string): Promise<ActivityBase[]> {
        return this.axiosInstance.get(this.baseUrl + `/activities/${activityId}`, {params:{include_children:true}})
          .then((response) => {
            return response.data;
          });
      }

    getSessionActivities(sessionId: string): Promise<ActivityBase[]> {
        return this.axiosInstance.get(this.baseUrl + `/sessions/${sessionId}/activities`)
          .then((response) => {
            return response.data;
          });
      }
      patchActivity(activity_id, patch_obj:{
        feedback_label?:number|undefined, 
        feedback_rating?:number|undefined, 
        feedback_notes?:string|undefined
      }): Promise<any> {
        return this.axiosInstance.patch(this.baseUrl + `/activities/${activity_id}`, patch_obj)
          .then((response) => {
            return response.data;
          });
      }
      
      
      getPromptTemplates(search_query:string, limit:undefined|number=undefined): Promise<PromptTemplateDefinition[]> {
        return this.axiosInstance.get(this.baseUrl + "/prompts-templates", {params:{search_query, limit}})
          .then((response) => {
            return response.data;
          });
      }

      getPromptVersions(template_definition_id:string): Promise<PromptTemplateVersion[]> {
        return this.axiosInstance.get(this.baseUrl + `/prompts-templates/${template_definition_id}/versions`)
          .then((response) => {
            return response.data;
          });
      }

      getPromptRuns(prompt_template_version_id:string): Promise<LlmPrompt[]> {
        return this.axiosInstance.get(this.baseUrl + `/prompts-templates/versions/${prompt_template_version_id}/runs`)
          .then((response) => {
            return response.data;
          });
      }




      getUnitTests(search_query,skip, limit): Promise<PromptUnitTest[]> {
        return this.axiosInstance.get(this.baseUrl + `/tests/`, {params:{search_query,skip, limit}})
          .then((response) => {
            return response.data;
          });
      }

      getUnitTest(test_name): Promise<PromptUnitTest> {
        return this.axiosInstance.get(this.baseUrl + `/tests/${test_name}`)
          .then((response) => {
            return response.data;
          });
      }

      postUnitTest( unit_test:PromptUnitTest): Promise<PromptUnitTest> {
        return this.axiosInstance.post(this.baseUrl + `/tests/${unit_test.test_name}`, unit_test)
          .then((response) => {
            return response.data;
          });
      }

      getUnitTestRuns(search_query,skip, limit): Promise<PromptUnitTestRun[]> {
        return this.axiosInstance.get(this.baseUrl + `/test-runs`, {params:{skip, limit, search_query}})
          .then((response) => {
            return response.data;
          });
      }

      getUnitTestForTemplate(template_name:string, template_version:string): Promise<PromptUnitTestRun> {
        return this.axiosInstance.get(this.baseUrl + `/test-runs`, {params:{skip:0, limit:1, for_template_name:template_name, template_version:template_version}})
        .then((response) => {
            return response.data[0];
          });
      }

      getUnitTestCases(conditions:{for_tracking_project:string,for_template_name:string},skip, limit): Promise<TestCase[]> {
        return this.axiosInstance.get(this.baseUrl + `/test-cases`, {params:{skip, limit, exclude_inactive:false, ...conditions}})
          .then((response) => {
            return response.data;
          });
      }

      postUnitTestCase(testCase:TestCase): Promise<TestCase> {
        
        return this.axiosInstance.post(this.baseUrl + `/test-cases`,testCase)
          .then((response) => {
            return response.data;
          });
      }

      getUnitTestRun(run_id): Promise<PromptUnitTestRun> {
        return this.axiosInstance.get(this.baseUrl + `/test-runs/${run_id}`)
          .then((response) => {
            return response.data;
          });
      }

      getUnitTestRunDetails(test_name,run_id,skip=0, limit=1000): Promise<TestCaseResult[]> {
        return this.axiosInstance.get(this.baseUrl + `/test-runs/${run_id}/result-details`, {params:{skip, limit}})
          .then((response) => {
            return response.data;
          });
      }

      patchUnitTestRunDetails(run_id, test_case_id, patch_obj:{llm_prompt:{feedback_label:null|number}}): Promise<any> {
        return this.axiosInstance.patch(this.baseUrl + `/test-runs/${run_id}/result-details/`+test_case_id, patch_obj)
          .then((response) => {
            return response.data;
          });
      }




      getUnitTestCase(test_case_id): Promise<TestCase> {
        return this.axiosInstance.get(this.baseUrl + `/test-cases/${test_case_id}`)
          .then((response) => {
            return response.data;
          });
      }


      getAccountInfo(): Promise<AccountInfoResponse> {
        return this.axiosInstance.get(this.baseUrl + "/settings/account")
          .then((response) => {
            return response.data;
          });          
      }

      pathAccountInfo(payload:AccountInfoResponse): Promise<AccountInfoResponse> {
        return this.axiosInstance.patch(this.baseUrl + "/settings/account", payload)
          .then((response) => {
            return response.data;
          });          
      }

      getApiKeys(): Promise<ApiKey[]> {
        return this.axiosInstance.get(this.baseUrl + "/settings/keys")
          .then((response) => {
            return response.data;
          });          
      }

      createApiKey(): Promise<ApiKey> {
        return this.axiosInstance.post(this.baseUrl + "/settings/keys/create")
          .then((response) => {
            return response.data;
          });          
      }

      createTempApiKey(): Promise<ApiKey> {
        return this.axiosInstance.post(this.baseUrl + "/settings/keys/temp/create")
          .then((response) => {
            return response.data;
          });          
      }

      getApiKeyValue(key_id): Promise<ApiKey> {
        return this.axiosInstance.get(this.baseUrl + "/settings/keys/"+key_id)
          .then((response) => {
            return response.data;
          });          
      }

      disableApiKeyValue(key_id): Promise<ApiKey[]> {
        return this.axiosInstance.delete(this.baseUrl + "/settings/keys/"+key_id)
          .then((response) => {
            return response.data;
          });          
      }



}
    

export {getApi}