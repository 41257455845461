


import { Box,   CheckBox,   Drop,    Text, TextInput} from "grommet"

import { useMemo, useRef, useState } from "react"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"
import { SiAzurefunctions } from "react-icons/si"
import FunctionCallArguments from "./functionCallArguments"
import { TbMathFunction } from "react-icons/tb"
import DropDownButton from "../DropDownButon/dropDownButton"
import Button from "../Button/button"
import DeleteWithConfirmation from "../DeleteWithConfirmation/deleteWithConfirmation"

type DataTypes="boolean"|"string"|"number"|"integer"|"object"|"array"
type PropertyInfo = {
    type:DataTypes,
    description:string
    items?:{
        type:DataTypes,
        description?:string
        properties?:{[key:string]:PropertyInfo}
        required?:string[]    
    },
    properties?:{[key:string]:PropertyInfo}
    required?:string[]
}
interface FunctionInfo{
    name:string, 
    description?:string
    parameters:{
        type:string,
        description:string,
        properties:{
            [key:string]:PropertyInfo
        },
        required:string[]
    }
}

const PropertyEdit=({name, propertyInfo, required, onChange, onDelete}:{
    name:string,
    propertyInfo:PropertyInfo,
    required:boolean
    onChange:(name:string, propertyInfo:PropertyInfo, required:boolean)=>any
    onDelete?:()=>any
})=>{

    return <Box>
        <Box direction="row" gap="5px" align="end">
            <TextInput  value={name} onChange={e=> {
                onChange(e.target.value, propertyInfo, required)
                }} placeholder="name"/>
            :
            <Box width="70px">
            <DropDownButton 
            
            text={propertyInfo.type}
            options={Object.fromEntries([
                "boolean","string","number","integer","object","array"
            ].map(key=>[key,{label:key}]))}
            allowEmpty={false}
            onOptionSelected={(key)=>{
                onChange(name,{...propertyInfo,...{type:key as any}},required)
            }}
            />
            </Box>
            <CheckBox label="optional" checked={!required} onChange={(e)=>onChange(name,propertyInfo, !e.target.checked)}/>
            <DeleteWithConfirmation onDelete={()=>onDelete()}/>
        </Box>
        <TextInput  style={{color:"gray", borderColor:"lightgray"}} size="small" placeholder="description" value={propertyInfo.description} onChange={e=>onChange(name, {...propertyInfo,...{description:e.target.value}},required)}/>
        {
            propertyInfo.type=="object"?(
                <Box>
                    <Text>{"{"}</Text>
                    <ObjectEdit  properties={propertyInfo.properties} required={propertyInfo.required} onChange={(properties,required_subproperties)=>onChange(name, {
                        type:"object", 
                        properties:properties, 
                        description:propertyInfo.description, 
                        required:required_subproperties
                        },required )}/>
                    
                    <Text>{"}"}</Text>
                </Box>
            ):(
                propertyInfo.type=="array"?(
                    <Box direction="row" gap="10px" align="start">
                        <Text>{"["}</Text>
                        
                            <Text>Item type</Text>
                            <DropDownButton 
                            text={propertyInfo.items?.type}    
                            options={Object.fromEntries([
                                "boolean","string","number","integer","object","array"
                                ].map(key=>[key,{label:key}]))}
                                allowEmpty={false}
                                onOptionSelected={(key)=>{
                                    onChange(name, {...propertyInfo,...{items:(
                                        {...(propertyInfo.items||{}), ...{type:key as any}}
                                    )}},required)
                                }}
                            />
                            {
                                propertyInfo.items?.type=="object"&&(
                                 <Box gap="10px">
                                    
                                     <Text>{"{"}</Text>
                                     <ObjectEdit properties={propertyInfo.items?.properties||{}} required={propertyInfo.items?.required} onChange={(properties,required_subproperties)=>onChange(name,{...propertyInfo,
                                        items:{
                                                type:"object", 
                                                properties:properties, 
                                                required:required_subproperties
                                            }
                                        },required )}/>
                                        <Text color="red">Attention: nested argument properties are being ignored by OpenAI</Text>
                                     <Text>{"}"}</Text>
                                </Box>   
                                )
                            }

                        
                        <Text>{"]"}</Text>
                    </Box>
                ):(
                    <></>
                )
            )
        }
    </Box>
}

const ObjectEdit=({
    
    properties,
    required,
    onChange
}:{
    
    properties:{[key:string]:PropertyInfo},
    required?:string[],
    onChange:( properties:{[key:string]:PropertyInfo}, required:string[])=>any
})=>{
    return (<Box gap="15px">
        
        {properties && Object.keys(properties).map(p=>(
                        <PropertyEdit 
                            name={p}
                            propertyInfo={properties[p]} 
                            required={required &&required.includes(p) } 
                            onDelete={()=>{
                                let changedInfo = {...properties}
                                delete changedInfo[p]
                                onChange(changedInfo, required?required.filter(r=>r!=p):required)
                            }}
                            onChange={(name,prop, isRequired)=>{
                                var _required=required
                                if (isRequired && required && !required.includes(p)){
                                    _required=[...required,p]
                                }
                                else if (!isRequired && required && required.includes(p)){
                                    _required=required.filter(r=>r!=p)
                                }
                                let changedInfo = undefined
                                if (name!=p){
                                    changedInfo = {...properties}
                                    delete changedInfo[p]
                                    changedInfo[name]=properties[p]
                                    
                                }
                                else{
                                    changedInfo = {...properties}
                                    changedInfo[name]=prop
                                }
                                onChange( changedInfo,_required)
                            }}
                            />
                    ))}
        <Box align="start">
            <Button secondary text="Add" onClick={()=>{
                let newVal = {...properties, ...{"new_argument":{type:"string", description:""}}} as any
                onChange(newVal, required)
              }}/>
        </Box>
    </Box>)
}

export  default function FunctionEdit({functionInfo,  onApplyEdit, onCancel}:{
    functionInfo:FunctionInfo,
    onApplyEdit:(functionInfo:FunctionInfo)=>any
    onCancel:()=>any
}){

    const [functionInfoData, setFunctionInfoData]=useState(functionInfo)
   
    return (
        <Box flex={{shrink:0}}>

         {functionInfoData&&(
                            <Box flex={{shrink:0}} border round="8px" background="white" pad="5px" margin="0px 3px">
                                <Box direction="row" justify="stretch" align="center" flex="grow" >
                                    <Box direction="row" gap="5px"   align="center" background="rgba(101, 255, 132, 0.2)" flex="grow" style={{maxWidth:"500px"}}>
                                        <Text ><TbMathFunction size="15px"/></Text>
                                        {/* <Text weight={900} >{functionInfo.name}</Text> */}
                                        <Box flex="grow" >
                                        <TextInput value={functionInfoData.name} onChange={(e)=>setFunctionInfoData(
                                            {
                                                name:e.target.value,
                                                description:functionInfoData.description,
                                                parameters:functionInfoData.parameters
                                                
                                            }
                                        )}/>
                                        </Box>
                                    </Box>
                                   
                                </Box>
                                <Box pad="0px 20px">
                                    <TextInput   style={{color:"gray", borderColor:"lightgray"}} size="small" placeholder="description" value={functionInfoData.description||""} onChange={e=>{
                                        setFunctionInfoData(
                                            {
                                                name:functionInfoData.name,
                                                description:e.target.value,
                                                parameters:functionInfoData.parameters
                                                
                                            }
                                        )
                                    }}/>
                                </Box>
                                    <Text margin="10px" weight={900} size="large">Function arguments:</Text>
                                    <Box pad="4px 25px">
                                        <ObjectEdit  properties={functionInfoData.parameters?.properties||{}} 
                                            required={functionInfoData.parameters?.required||[]} onChange={(properties,required)=>{
                                            let newVal:any = {
                                                type:"object",
                                                
                                                properties:properties,
                                                required:required
                                            }
                                           
                                            setFunctionInfoData(
                                                {
                                                    name:functionInfoData.name,
                                                    description:functionInfoData.description,
                                                    parameters:newVal
                                                }
                                            )
                                        }}/>
                                        
                                    </Box>

                            </Box>
                        )}
            <Box direction="row" alignSelf="end" pad="5px" gap="5px">
                <Button secondary onClick={()=>onCancel()} text="Cancel"/>
                <Button primary   onClick={()=>onApplyEdit(functionInfoData)} text="OK"/>

            </Box>
        </Box>    
    )

    
}

