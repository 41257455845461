


import { Box, Button, Layer, Tip, Text, Meter } from "grommet"
import { ChatMessage, ChatMessageTemplate, PromptUnitTestRun } from "../../models/dataModel"
import { ChatMessageBox } from "../ChatMessageBox/chatMessageBox"
import Badge from "../Badge/badge"
import Moment from "react-moment"
import { BsStopwatch } from "react-icons/bs"
import SourceDataBadge from "./sourceDataBadge"


function nonNan(val: number) {
    return isNaN(val) ? 0 : val
}

export default function PromptTestRunCard({ promptTestRun }: {
    promptTestRun: PromptUnitTestRun
}) {



    return (
        <Box flex={false}>
            <Box flex={false}>
                <Box direction="row" justify="between">
                    <Badge>

                        <Text size="small" color="dark-3">
                            <Moment fromNow>{promptTestRun.start_time}</Moment>
                        </Text>
                    </Badge>
                    <Badge
                        icon={<BsStopwatch />}
                        text={
                            <Text size="small" color="dark-3">
                                {promptTestRun.end_time ? <Moment duration={promptTestRun.start_time} date={promptTestRun.end_time} /> : "n/a"}
                            </Text>}
                    />

                </Box>
                
                <Box align="start"  pad="2px 0px 2px 5px" >
                    <Box direction="row" justify="between" width="100%" align="center">
                        Test for:
                        <SourceDataBadge conditions={promptTestRun?.conditions}/>


                    </Box>

                    <Box direction="row" justify="between" width="100%" align="center">
                        Average score:
                        <Badge background="accent-1" border={false} >
                            <Text color="dark-1" weight={600}>
                                {nonNan(Math.round((promptTestRun.results.overall_score) * 100))} %

                            </Text>
                        </Badge>


                    </Box>
                    <Box direction="row" justify="between" width="100%" align="center" >
                        <Text color="dark-1">Passed </Text>
                        <Badge >
                            <Text color="dark-1" weight={900}>{promptTestRun.results.passed}/{promptTestRun.results.total_processed} </Text>
                        </Badge>
                    </Box>
                </Box>
            </Box>
            <Meter
                size="large"
                round thickness="small"
                max={promptTestRun.results.total_processed}
                background={"light-4"}
                values={[{
                    value: promptTestRun.results.passed,
                    label: 'sixty',

                }]}
                aria-label="meter"
            />

        </Box >
    )

}