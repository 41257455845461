


import { Box, DataTable, Meter, Spinner, Stack, Tab, Tabs, Text, TextArea, TextInput, ThemeContext } from "grommet"
import { PromptTemplateDefinition, PromptTemplateVersion, PromptUnitTest, PromptUnitTestRun, TestCase } from "../../models/dataModel"


import { useEffect, useMemo, useState } from "react"
import SearchBox from "../SearchBox/searchBox"
import { getApi } from "../../apiService"

import "./styles.css"
import { PromptTemplateIcon, SessionsIcon, UnitTestIcon, UnitTestRunIcon } from "../CommonIcons/commonIcons"
import Button from "../Button/button"
import Badge from "../Badge/badge"
import Popup from "../Popup/popup"


import Moment from "react-moment"

import ChatPromptTemplate from "./promptTestRunCard"
import { BsStopwatch } from "react-icons/bs"
import PromptTestRunCard from "./promptTestRunCard"
import { FaCode } from "react-icons/fa"
import DropDownButton from "../DropDownButon/dropDownButton"
import InfoToast from "../InfoToast.tsx/infoToast"
import TestCaseDetail from "./testCaseDetail"
import { AiTwotoneEdit } from "react-icons/ai"
import { useNavigate, useSearchParams } from "react-router-dom"

export default function PromptUnitTestDefinition({ unitTestName }: {
    unitTestName?: string,

}) {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useSearchParams()

    const [popup, setPopup] = useState<any>()

    const [loadingRuns, setLoadingRuns] = useState<any>()
    const [loadingCases, setLoadingCases] = useState<any>()
    const [savePending, setSavePending] = useState<any>()
    const [saving, setSaving] = useState<any>()
    const [error, setError] = useState<string>()
    const [pageSize, setPageSize] = useState<number>(100)
    const [hasMoreRuns, setHasMoreRuns] = useState<boolean>()
    const [hasMoreCases, setHasMoreCases] = useState<boolean>()

    const [testRuns, setTestRuns] = useState<{test_name:string, pageSize:number,data:PromptUnitTestRun[]}>()
    const [testCases, setTestCases] = useState<{test_name:string, pageSize:number, for_template_name:string, for_tracking_project:string, data:TestCase[]}>()
    
    const [innerUnitTestState, setInnerUnitTestState] = useState<PromptUnitTest>()
    useEffect(() => {
        getApi().getUnitTest(unitTestName).then(d=> setInnerUnitTestState(d))
    }, [unitTestName])
    const [sourceOfData, setSourceOfData] = useState<"unspecified"|"for_template_name"|"for_tracking_project">()
    useEffect(() => 
    setSourceOfData(
        (innerUnitTestState?.definition.for_template_name&&"for_template_name" )
        ||( innerUnitTestState?.definition.for_tracking_project&&"for_tracking_project") 
        ||   "unspecified"), [innerUnitTestState])

    useEffect(() => {
        if (innerUnitTestState && !(testRuns &&testRuns.test_name == innerUnitTestState.test_name && testRuns.pageSize == pageSize)) {
            setLoadingRuns(true)
            
            getApi().getUnitTestRuns(innerUnitTestState.test_name, 0, pageSize).then(d => {
                setTestRuns({test_name:innerUnitTestState.test_name, pageSize, data:d})
                setHasMoreRuns(d.length == pageSize)
            }).finally(() => setLoadingRuns(false))
        }

    }, [innerUnitTestState, pageSize])



    useEffect(() => {
        if (!savePending && innerUnitTestState && !(testCases &&
                testCases.test_name == innerUnitTestState.test_name && 
                testCases.pageSize == pageSize && 
                testCases.for_template_name == innerUnitTestState.definition.for_template_name && 
                testCases.for_tracking_project == innerUnitTestState.definition.for_tracking_project)) {
            if (innerUnitTestState.definition.for_template_name || innerUnitTestState.definition.for_tracking_project){

            
                setLoadingCases(true)
                
                getApi().getUnitTestCases({
                        for_template_name:innerUnitTestState.definition.for_template_name, 
                        for_tracking_project:innerUnitTestState.definition.for_tracking_project 
                    }, 0, pageSize).then(d => {
                    setTestCases({
                        test_name:innerUnitTestState.test_name,
                        for_template_name:innerUnitTestState.definition.for_template_name, 
                        for_tracking_project:innerUnitTestState.definition.for_tracking_project,
                        pageSize, data:d})
                    setHasMoreCases(d.length == pageSize)
                }).finally(() => setLoadingCases(false))
            }
            else{
                setTestCases(null)
            }
        }

    }, [innerUnitTestState, pageSize])
    

    function modifyUnitTest(func:(unitTest:PromptUnitTest)=>any){
         
        let newUnitTest  = JSON.parse(
            JSON.stringify(innerUnitTestState)
        )
        func(newUnitTest)
        if (JSON.stringify(newUnitTest) != JSON.stringify(innerUnitTestState)){

            setInnerUnitTestState(newUnitTest)
            setSavePending(true)
        }

    }

    function modifyTestCase(index:number,newTestCase:TestCase){
        
            if (!newTestCase){
                let newTestCases={...testCases}
                newTestCases.data=[...newTestCases.data]
                // delete the item at index:
                newTestCases.data.splice(index,1)
                setTestCases(newTestCases)
            }
            let newTestCases={...testCases}
            newTestCases.data=[...newTestCases.data]
            newTestCases.data[index]=newTestCase
            setTestCases(newTestCases)
        
    }

    function saveUnitTest(){
        setSaving(true)
        getApi().postUnitTest( innerUnitTestState).then(d=>{
            setInnerUnitTestState(d)
            setError(null)
            setSavePending(false)
        })
        .catch(e=>setError(e.message))
        .finally(()=>setSaving(false))
    }





    if (!innerUnitTestState) {
        return <></>
    }

    return (
        <Box flex={false}>
            {savePending?"Save pending":""}
            <Box fill flex={false}>
                {popup}
                <Box className="unit-test-header">
                    <Box direction="row" wrap justify="between" align="start" flex={false} pad="10px 10px 5px">
                        <Box direction="row" gap="5px" wrap align="center">
                        <Text color="brand"><UnitTestIcon size="large" /> </Text>
                            <Text color="brand" size="xlarge" >      Unit test </Text>
                            <Box className="prompt-test-run-badge" >
                                <Text color="brand" size="xlarge" weight={900}>{innerUnitTestState.test_name} </Text>
                            </Box>
                            <Text color="brand" size="xlarge" > definition</Text>
                        </Box>


                        <Box pad="5px" style={{ maxWidth: "300px" }} onClick={()=>setSearchQuery(new URLSearchParams({"unit-test-run":innerUnitTestState.last_run.run_id}))}>

                        <Box direction="row" align="center"  justify="between">
                            <Text color="brand" weight={900}>  <UnitTestRunIcon />    Last run: </Text>
                            <Badge tip={"Last run at " + innerUnitTestState.last_run.start_time}>
                                <Text size="small" color="dark-3">
                                    <Moment fromNow>{innerUnitTestState.last_run.start_time}</Moment>
                                </Text>
                            </Badge>

                        </Box>

                            <Box align="between" direction="row" justify="between" pad="0px 0px 0px 15px">
                                <Box direction="row" justify="between" width="100%" align="center">
                                    <Text color="brand" weight={900}>Average score:</Text>
                                    <Badge background="accent-1" border={false} >
                                        <Text color="dark-1" weight={600}>
                                            {nonNan(Math.round((innerUnitTestState.last_run.results.overall_score) * 100))} %

                                        </Text>
                                    </Badge>


                                </Box>

                            </Box>
                            <Box  pad="0px 0px 0px 15px">
                                <Box direction="row" justify="between" width="100%" align="center"  >
                                    <Text color="brand" weight={900}>Passed test cases</Text>
                                    <Badge >
                                        <Text color="dark-1" weight={900}>{innerUnitTestState.last_run?.results.passed}/{innerUnitTestState.last_run?.results.total_processed} </Text>
                                    </Badge>
                                </Box>
                                <Meter
                                    round thickness="small"
                                    background={"light-4"}
                                    max={innerUnitTestState.last_run?.results.total_processed}
                                    values={[{
                                        value: innerUnitTestState.last_run?.results.passed,
                                    }]}
                                    aria-label="meter"
                                />
                            </Box>
                        </Box>
                       
                    </Box>

                    <Box direction="row" wrap justify="between" align="end">


                    </Box>
                </Box>

                <Box pad="10px">

                {error && <InfoToast type="error">{error}</InfoToast>}
                <Tabs className="unit-test-tabs" alignControls="start">
                <Tab title="Recent test runs">
                    <DataTable<PromptUnitTestRun>
                        onClickRow={({datum})=>{
                            setSearchQuery(new URLSearchParams({"unit-test-run":datum.run_id}).toString())
                        }}
                        columns={[
                        {
                            property: 'start_time',
                            header: <Text><UnitTestRunIcon/>Run started</Text>,
                            primary: true,
                            render: (run) => (
                                <Box direction="row" gap="5px" align="center">
                                    <Text truncate="tip" >
                                        <Moment format="YYYY-MM-DD HH:mm">{run.start_time}</Moment>
                                    </Text>
                                    <Badge>

                                    <Text  truncate="tip" >
                                        <Moment fromNow>{run.start_time}</Moment>
                                    </Text>
                                    </Badge>
                                </Box>
                            )
                        },
                        {
                            property: 'duration',
                            header: <Text>Duration</Text>,
                            primary: true,
                            render: (run) => (!run.end_time)?(
                                <></>
                            ):(
                                <Badge
                                icon={<BsStopwatch />}
                                text={
                                    <Text size="small" color="dark-3">
                                        {run.end_time ? <Moment duration={run.start_time} date={run.end_time} /> : "n/a"}
                                    </Text>}
                                />
                            )
                        },
                        {
                            property: 'passing',
                            header: 'Passed test cases',
                            render: (run) => (!run.results?(
                                <></>
                            ):(
                            <Box direction="row" align="center">
                                <Meter
                                max={run?.results.total_processed}
                                values={[{ value: run.results?.passed }]}
                                
                                    round thickness="small"
                                    background={"light-4"}
                                
                                size="small"
                                />
                                <Badge pad="0px 5px">
                                        <Text color="dark-1" weight={900}>{run.results?.passed}/{run.results?.total_processed} </Text>
                                </Badge>
                            </Box>)
                            ),
                        },
                        {
                            property: 'score',
                            header: 'Average score:',
                            render: (run) => (!run.results?(
                                <></>
                            ):(
                            <Box align="start">
                                <Badge width="40px" background="accent-1" border={false}  >
                                    <Box align="end" width="100%">

                                <Text color="dark-1" weight={600} textAlign="center">
                                    {nonNan(Math.round((run.results.overall_score) * 100))} %
    
                                </Text>
                                    </Box>
                            </Badge>
                            </Box>
                            )
                            ),
                        },
                        ]}
                        data={testRuns?.data}
                        />
                    </Tab>  
                    <Tab title="Test cases" >
                        <Box pad="10px" flex={false} direction="row" align="center" gap="5px">
                            <Text truncate="tip" color="brand">Source of data</Text>
                            <DropDownButton icon={<AiTwotoneEdit/>}
                            text={(innerUnitTestState.definition.for_template_name&&"for_template_name" )||( innerUnitTestState.definition.for_tracking_project&&"for_tracking_project") || sourceOfData ||  "unspecified"}
                            
                            options={{
                                "for_template_name": {
                                    label:(<Box margin="10px">
                                            <Text weight={900} color="brand">for_template_name</Text>
                                            <Text size="small">generated by annotated LLM runs of specified Prompt template</Text>
                                        </Box>),
                                    onClick: () => {
                                        setSourceOfData("for_template_name")
                                    }
                                },
                                "for_tracking_project": {
                                    label:(<Box margin="10px">
                                            <Text weight={900} color="brand">for_tracking_project</Text>
                                            <Text size="small">generated by annotated sessions of specified project</Text>
                                        </Box>),
                                    onClick: () => {
                                        setSourceOfData("for_tracking_project")
                                    }
                                },
                                "unspecified": {
                                    label:(<Box margin="10px">
                                            <Text weight={900} color="brand">unspecified</Text>
                                            <Text size="small">source of test cases is defined by code</Text>
                                        </Box>),
                                    onClick: () => {}
                                },
                                
                            }} />
                            <Box flex="grow">
                            {sourceOfData == "for_template_name" && <TextInput value={innerUnitTestState.definition.for_template_name} onChange={(e)=>modifyUnitTest(ut=>{
                                ut.definition.for_template_name = e.target.value
                            })}
                            onBlur={()=>savePending&& saveUnitTest()}
                            />}
                            {sourceOfData == "for_tracking_project" && <TextInput value={innerUnitTestState.definition.for_tracking_project} onChange={(e)=>modifyUnitTest(ut=>{
                                ut.definition.for_tracking_project = e.target.value
                            })}
                            onBlur={()=>savePending&&saveUnitTest()}
                            />}
                            </Box>
                           
                            
                        {/* <Select
                        options={['', 'medium', 'large']}
                        value={value}
                        onChange={({ option }) => setValue(option)}
                        /> */}

                        </Box>

                        <Box flex="grow">
                            {/* ---
                            {testCases?.for_template_name }
                            {testCases?.for_tracking_project }
                            --- */}
                            {testCases?.data?.map((testCase, index) => (
                                <TestCaseDetail key={index} testCase={testCase} applyChange={(val)=>modifyTestCase(index,val)}/>
                                // {<Box key={index} direction="row" gap="5px" align="center" border>testCase</Box>}
                            ))
                            }
                            { testCases?.data?.length==0 && (
                                    <Box >
                                        <InfoToast type="info">No test cases found</InfoToast>
                                        <Box pad="10px">
                                            {sourceOfData==="unspecified"?(
                                                <Text weight={900} color="dark-2">You can generate test cases in your code or define in code.</Text>
                                            ):(
                                                sourceOfData==="for_template_name"?(
                                                    <Box align="center" gap="4px">
                                                    <Text weight={900} color="dark-2">You need to label some previous template runs in order to generate test cases.</Text>
                                                    <Text weight={900} color="dark-2">They can be found in the <Text color="brand">detail of</Text> particular <Text color="brand"> template version</Text> at <Text color="brand"><PromptTemplateIcon/> Prompts</Text> page </Text>
                                                    <Button secondary icon={<PromptTemplateIcon/>} margin="10px 0px" text="Go to Prompts" onClick={()=>navigate("/prompt-templates?template_name="+encodeURIComponent(innerUnitTestState.definition.for_template_name))}/>
                                                    </Box>

                                                ):(
                                                    <Box align="center" gap="4px">
                                                    <Text weight={900} color="dark-2">You need to label some root chains at <Text color="brand">Sessions</Text> page in order to generate test cases.</Text>
                                                    <Text weight={900} color="dark-2">Also ensure that your sessions are marked with <code className="inline">tracking_project="{innerUnitTestState.definition.for_tracking_project}"</code> label</Text>

                                                    
                                                    <Button secondary icon={<PromptTemplateIcon/>} margin="10px 0px" text="Go to sessions" onClick={()=>navigate("/sessions?tracking_project="+encodeURIComponent(innerUnitTestState.definition.for_tracking_project))}/>
                                                    </Box>
                                                )

                                            )}
                                        </Box>
                                        <Text weight={900} color="dark-2">Or change the source of data for this test case.</Text>
                                    </Box>
                            )
                            }
                        </Box>

                    </Tab>  
                   
                </Tabs>
                </Box>
                

                {/* <Box

                    flex={false}

                    overflow="auto"
                >
                    {data?.map(run => (
                        <Box className="prompt-test-box run" flex={false} >
                            <PromptTestRun promptTestRun={run} />
                        </Box>
                    ))}

                </Box> */}

            </Box>


        </Box>
    )


}



function nonNan(val: number) {
    return isNaN(val) ? 0 : val
}


