


import { Box, Spinner, Text, TextArea, TextInput } from "grommet"
import {  PromptTemplateDefinition, PromptTemplateVersion } from "../../models/dataModel"


import { memo, useCallback, useEffect, useMemo, useState } from "react"
import SearchBox from "../SearchBox/searchBox"
import { getApi } from "../../apiService"

import "./promptTemplateBox.css"
import { PromptTemplateIcon } from "../CommonIcons/commonIcons"
import Button from "../Button/button"
import Badge from "../Badge/badge"
import Popup from "../Popup/popup"
import { FormattedPrompt } from "../FormatedPrompt/formatedPrompt"
import { LlmPlayground } from "../LlmPlayground/LlmPlayground"
import Moment from "react-moment"

import ChatPromptTemplate from "./chatPromptTemplate"
import PromptTemplateDetail from "./promptTemplateDetail"
import { useSearchParams } from "react-router-dom"
import { UnitTestBadgeForTemplate } from "../UnitTests/unitTestBadge"

const PromptTemplatesVersions = memo(({promptTemplateDefinition}: {
    promptTemplateDefinition?:PromptTemplateDefinition,
    
}) =>{
    const [popup,setPopup]=useState<any>()
    
    const [loading,setLoading]=useState<any>()
    const [queryParams,setQueryParams]=useSearchParams()
    const [data,setData] = useState<PromptTemplateVersion[]>()
    useEffect(()=>{
        let versionToSelect=queryParams.get("v")
        if (promptTemplateDefinition){
            setLoading(true)
            getApi().getPromptVersions(promptTemplateDefinition.id).then(d=>{
                setData(d)
                if (versionToSelect){
                    let found = d.find(pt=>pt.template_version_final==versionToSelect)
                    if (found){
                        openDetail(found)
                    }
                }
            }).finally(()=>setLoading(false))
        }
        
    },[promptTemplateDefinition])
    
    function openDetail(templateVersion){
        setPopup(<Popup onClose={()=>setPopup(null)} caption={`'${templateVersion.template_name}' template version [${templateVersion.template_version_final}]`}>
                <Box pad="20px">

                <PromptTemplateDetail templateVersion={templateVersion}/>
                </Box>
            </Popup>
            )
    }
    const onOpenDetail = useCallback((templateVersion)=>{
        openDetail(templateVersion)
    },[])
    return (
        <Box direction="row"  pad="10px">
         
        <Box   fill>
            {popup}
            <Box justify="start" align="center" pad="10px 10px" direction="row" gap="5px">
                <Box className="version-template-name">
                    <Text color="brand" size="xlarge" weight={900}>{promptTemplateDefinition.template_name} </Text>
                </Box>
                <Text color="brand" size="xlarge" >template versions</Text>
            </Box>
            <Box 
                
                flex={false}
                
                overflow="auto"
                >
                {data?.map((pt,i)=>(
                    <TemplateVersionCard key={i} templateVersion={pt} onOpenDetail={onOpenDetail}/>
                ))}
                {/* { (hasMore)&&(
                    <Box margin="20px" round="5px" background="dark-2" width="200px" alignSelf="center" pad="5px">
                        <Text color="white" weight={900}>There seems to be more. Please use filter to narrow down the search. </Text>
                    </Box>
                )} */}
                
            </Box>

        </Box>

       
        </Box>
    )


},(prevProps,nextProps)=>prevProps.promptTemplateDefinition?.id===nextProps.promptTemplateDefinition?.id)

const TemplateVersionCard = memo(({templateVersion,onOpenDetail}:{templateVersion:PromptTemplateVersion, onOpenDetail:(templateVersion: PromptTemplateVersion)=>any })=>{
    return(
<Box  className="prompt-template-box version" flex={false} 
                    onClick={()=> onOpenDetail(templateVersion)}
                        >
                        <Box direction="row" justify="between"  width="100%" > 
                            <Box direction="row" align="center" gap="2px" className="prompt-template-version-badge"
                            > 
                                <PromptTemplateIcon/> 
                                <span className="versionNumber">
                                <Text size="small">version:</Text>
                                <Text weight={900}>{templateVersion.template_version}</Text>
                                <Text >{templateVersion.template_version_final?.replace(templateVersion.template_version,"")}</Text>
                                </span>
                            </Box>
                            
                                <Badge tip={`Last run at: ${templateVersion.last_run}`} text={<Text size="small" color="dark-1" >
                                    Last run:  <b><Moment fromNow>{templateVersion.last_run}</Moment></b>
                                    </Text>}/>
                        </Box>
                            
                            
                            <Box className="prompt-template-box-text fadeout-parent" style={{whiteSpace:"pre-wrap"}}>
                                {typeof(templateVersion.prompt_template)==="string"?(
                                    <Text>{templateVersion.prompt_template}</Text>
                                ):(
                                    Array.isArray(templateVersion.prompt_template)?(
                                        <ChatPromptTemplate items={templateVersion.prompt_template.slice(0,3)}/>
                                    ):(
                                        "unsupported prompt type"
                                    )
                                )}
                                <div className="fadeout">
                                    
                                        <Button
                                        
                                        background="white"
                                        border
                                        // onClick={()=> 
                                        //     setPopup(<Popup onClose={()=>setPopup(null)} caption={`'${pt.template_name}' template version [${pt.template_version_final}]`}>
                                        //                 <Box pad="20px">

                                        //                 <PromptTemplateDetail templateVersion={pt}/>
                                        //                 </Box>
                                        //             </Popup>
                                        //             )
                                        //         }
                                        >
                                            <Text weight={900}>Show more</Text>
                                        </Button>
                                        
                                </div>
                            </Box>
                            <Box  margin="5px 0px 0px" direction="row" justify="between">
                                <Box align="start">

                                    <Text weight={900} >Parameters:</Text>
                                    <Box direction="row">
                                        {templateVersion.prompt_input_params?.map(p=>(
                                            <Badge text={p}/>
                                        ))}
                                    </Box>
                                </Box>
                                <Box justify="end" >
                                    
                                <UnitTestBadgeForTemplate template_name={templateVersion.template_name} template_version_number={templateVersion.template_version_final}/>
                                </Box>
                            </Box>
                    </Box>
    )
})

export default PromptTemplatesVersions