
import { Avatar, Box, Footer, Heading, Text } from "grommet";
import { useEffect, useState } from "react";

import Popup from "../components/Popup/popup";

import { Session } from "../models/dataModel";

import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";
import Button from "../components/Button/button";
import { useAppContext } from "../appContext";
import { FaGithub, FaUser } from "react-icons/fa";
import UserAvatar from "../components/UserAvatar/userAvatar";
import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";
import { HiSparkles } from "react-icons/hi2";
import "./homePage.css"
import CodeBlock from "../components/CodeBlock/codeBlock";

import {FaRoute} from 'react-icons/fa';
import {BiMessageSquareDetail} from 'react-icons/bi';
import {AiTwotoneExperiment} from 'react-icons/ai';
import {VscDebugLineByLine, VscVersions} from 'react-icons/vsc';
import {RiSpeedFill} from 'react-icons/ri'; 
import { BsTwitter } from "react-icons/bs";
import { LlmPlayground } from "../components/LlmPlayground/LlmPlayground";

export default function HomePage() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [popup, setPopup] = useState<any>()
    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])




    return (
        <Box   align="stretch" overflow="auto" height="100vh" flex={false} className="homePage" background="dark-1">
            {popup}
            <Box align="start" margin="20px 10px 0px" direction="row" justify="between" flex={false} wrap>
                <Logo size="xlarge" withText />

                    <Box direction="row" align="start" gap="medium" >
                    <a className="nav" href="https://github.com/blip-solutions/promptwatch-client" ><FaGithub size="25px"/></a>
                    <a className="nav" href="https://docs.promptwatch.io" >Docs</a>
                   
                    {currentUser ? (
                    <Box>
                        
                        <Box direction="row" gap="small"> 
                            
                            <UserAvatar size={15} showName />
                        </Box>
                        <Box direction="row" justify="end" pad="5px" gap="5px">
                            <Button primary text="Go to app" onClick={()=>navigate("/home")} />
                            <Button secondary text="Logout" icon={<MdLogout/>} onClick={logOut} />
                        </Box>
                    </Box>
                    ) : (
                        <Box align="center" justify="center" gap="small" direction="row">

                            <Button primary size="large" text="Sign up" onClick={() => navigate("/signup?followup=" + encodeURIComponent("/get-api-key"))} />
                            <Button secondary size="large" text="Login" onClick={() => navigate("/login?followup=" + encodeURIComponent("/get-api-key"))} />

                        </Box>

                    )}
                    </Box>
                   
            </Box>
            <div className="page-body">
            <Box   margin="50px 25% 0px 5%" align="start" className="firstBlock" flex="grow"  width="90%" direction="row" justify="between" wrap>
               <div className="headline" >
                        <h1 >
                            Track and tweak your LLM Chains
                        </h1>
                        <h2><mark>Replay any previous prompt</mark>, and tweak it until it works. Then test it against <mark>our prompt unit tests</mark> </h2>
                        
                        
                        <div className="get-api-key-btn-box">

                       
               
                </div>         
               </div>
                <Box className="rightSide" align="center">   
                <Box gap="20px">
                <CodeBlock language="bash">pip install promptwatch</CodeBlock>
               <CodeBlock caption="Super simple integration" highlightLines={[2, 8]} className="codeBlock">
                            {`from langchain import OpenAI, LLMChain, PromptTemplate
from promptwatch import PromptWatch

template_string = "Finish this sentence {input}"
prompt_template = PromptTemplate.from_template(template_string)
my_chain = LLMChain(llm=OpenAI(), prompt=prompt_template)

with PromptWatch(api_key="<your-api-key>") as pw:
    my_chain("The quick brown fox jumped over")
    `}

                        </CodeBlock>
                        <Box  width="100%" align="start"  margin="5px 0px 2px -15px">

<Button  primary size="medium" icon={<HiSparkles size="20px" />}
    text="Get your temporary API key"
    onClick={() => navigate("/get-api-key")} />
<Text weight={900} color="light-4">Try it... no registration needed</Text>
</Box>
</Box>
                </Box>
                <Box align="center" justify="center" direction="row">
                                <Button alignSelf="end" margin="0px 0px 2px" primary size="large" icon={<VscDebugLineByLine size="20px" />}
                                    text="Try our LLM playground"
                                    onClick={() =>   setPopup(<Popup onClose={()=>setPopup(null)} caption="LLM Prompt" >
                                    <Box pad="small">
                                        <LlmPlayground 
                                            sourcePrompt={[]} 
                                            promptTemplate={[{role:"user", text:""}]} 
                                            params={{}} 
                                            //llm_params={activity.metadata?.llm} 
                                            //generated={activity.generated}
                                            functions={[]}
                                            function_call={undefined}
                                            />
                                    </Box>
                                    
                                </Popup>)
                                    } />
                                    <Box align="end" margin="0px 5px">
                                    <Text size="15px">👈 Test your prompts and experiment with your prompts with OpenAI functions.</Text>
                                    <Text size="10px">No registration needed!</Text>
                                    </Box>
                            </Box>

                {/* <Box direction="row" align="end" margin="0px -50px" className="rightSide">
                    <img className="fadeout-img" src="/homePageWidget.png" />
                    <Box align="end" justify="center" className="exampleIntegration">
                        
                        <Box alignSelf="start" margin="0px 0px -28px 5px" style={{ zIndex: 20 }} >
                            <Text weight={900} color="accent-1" size="25px">Simple integration</Text>
                        </Box>
                        <Box style={{zIndex:8}}>

                        <CodeBlock highlightLines={[2, 8]}>
                            {`from langchain import OpenAI, LLMChain, PromptTemplate
from promptwatch import PromptWatch

template_string = "Finish this sentence {input}"
prompt_template = PromptTemplate.from_template(template_string)
my_chain = LLMChain(llm=OpenAI(), prompt=prompt_template)

with PromptWatch(api_key="<your-api-key>") as pw:
    my_chain("The quick brown fox jumped over")
    `}

                        </CodeBlock>
                        </Box>
                        
                        
                    </Box>
                </Box> */}
            </Box>
            <Box className="dashboard-img"  align="center">
                {/* <img src="/dashboard-screen.png"  /> */}
                <VideoWithPlaceholder src={"quick_intro.webm"} placeholderSrc={"/dashboard-screen.png" }/>
            </Box>
            <Box className="" > 
            <Box alignSelf="center" align="start">

                <HomepageFeatures/>
            </Box>
            </Box>
            </div>
            <Footer>
                <Box align="center" pad="10px 10vw" width="100%" background="dark-2">
                <Box direction="row" justify="between" wrap  width="100%">
                <Box align="start" gap="10px">
                        <a className="no-style" href="https://docs.promptwatch.io/">
                        <Text size="xlarge" weight={900} color="light-4">Documentation</Text>
                        </a>
                        <a className="no-style" href="https://docs.promptwatch.io/docs/intro">
                            <Text size="large" color="light-4">Introduction</Text>
                        </a>
                        <a className="no-style" href="https://docs.promptwatch.io/docs/quickstart">
                            <Text size="large" color="light-4">Quickstart</Text>
                        </a>
                        
                    </Box>
                    <Box align="start" gap="10px">
                        
                        <Text size="xlarge" weight={900} color="light-4">Social</Text>
                        
                        <a className="no-style" href="https://github.com/blip-solutions/promptwatch-client">
                            <FaGithub/> <Text size="large" color="light-4">Github</Text>
                        </a>
                        <a className="no-style" href="https://twitter.com/BezdekJuraj">
                            <BsTwitter/> <Text size="large" color="light-4">Twitter</Text>
                        </a>
                        
                    </Box>
                    <Box align="start" gap="10px">
                        <a className="no-style" href="https://docs.promptwatch.io/">
                        <Text size="xlarge" weight={900} color="light-4">Legal</Text>
                        </a>
                        <a className="no-style" href="https://docs.promptwatch.io/docs/legal/privacy-policy">
                            <Text size="large" color="light-4">Privacy policy </Text>
                        </a>
                        <a className="no-style" href="https://docs.promptwatch.io/docs/legal/terms-of-service">
                             <Text size="large" color="light-4">Terms of service</Text>
                        </a>
                        
                    </Box>
                </Box>
                        <a className="no-style" href="https://www.blip.solutions">
                        <Text size="small" color="light-4">© {new Date().getFullYear()} Blip.solutions</Text>
                        </a>
                        </Box>
            </Footer>
        </Box>
    )
}

const VideoWithPlaceholder = ({ src, placeholderSrc }) => {
    const [videoLoaded, setVideoLoaded] = useState(false);
  
    const handleVideoLoaded = () => {
      setVideoLoaded(true);
    }
  
    return (
      <div className="vid">
        {!videoLoaded && (
          <img width="110%" 
            style={{margin:"-2%"}}
            src={placeholderSrc}
            
          />
        )}
        <video
          src={src}
          style={{ display: videoLoaded ? 'block' : 'none', width: '100%', height: 'auto' }}
          autoPlay
          loop
          muted
          onLoadedData={handleVideoLoaded}
          
        />
      </div>
    );
  }


  const FeatureList = [
    {
      title: 'Tracing',
      icon: <FaRoute size="35px"/>,
      description: (
        <>
          Track and store all your executed chain runs. <br/>Gain ultimate insights into your LLM based application.
          
        </>
      ),
      link: 'docs/category/chain-tracing',
    },
    {
      title: 'Prompt tweaking', 
      icon: <BiMessageSquareDetail size="35px"/>,
      description: (
        <>
        
        Tweak your prompts on the production data. Test your prompts on the actual data for every prompt executed. 
        
        </>
      ),
      link:"docs/prompt_tweaking"
    },
    {
      title: 'Template versioning', 
      icon: <VscVersions size="35px"/>,
      description: (
        <>
        <p>Track versions of your prompt templates and see the impact of your changes</p>
        </>
      ),
      link:"docs/prompt_template_versioning"
    },
    {
      title: 'LLM caching', 
      icon: <RiSpeedFill size="35px"/>,
      description: (
        <>
        <p>
       Save time and money by caching your LLM response. Single line of code to speed up your most common prompts.
        </p>
        
        </>
      ),
      link:"docs/caching"
    },
    {
      title: 'Prompt Unit Testing', 
      icon: <AiTwotoneExperiment size="35px"/>,
      description: (
        <>
        <p>
        Do not guess what impact your changes will have. Measure it!
        </p>
        
        </>
      ),
      link:"docs/category/unit-testing"
    },
  ];

function Feature({title, description, icon, link}:{
    title:string
    description:any
    icon:any
    link:string
}) {
  
  return (
    <a className="no-style" href={"https://docs.promptwatch.io/"+link}>
    <Box 
    
    style={{maxWidth:"300px"}}
    margin="50px"

      >
      
      <div>
      {/* {styles.featureSvg  &&<Svg className={styles.featureSvg} role="img" />} */}
        {icon}
      </div>
      <div >
        <Heading color="accent-1" level={3}>{title}</Heading>
        <Text size="20px">{description}</Text>
      </div>
      
    </Box>
    </a>
  );
}

function HomepageFeatures() {
  return (
    <section >
      <div className="container">
        <Box direction="row" wrap pad="5vw" justify="center">
          {FeatureList.map((props, idx) => (
            <Feature key={idx} {...props} />
          ))}
        </Box>
      </div>
    </section>
  );
}
