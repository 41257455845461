


import { Box, Text, Tip } from "grommet"
import { useMemo } from "react"
import {TbLetterCase} from "react-icons/tb"
import {BiDollar} from "react-icons/bi"
import { KeyValuesComponent } from "../KeyValuesComponent/keyValues"

export const ActionCostsBadge=({activity})=>{
    const tokensCount = (activity.metadata && activity.metadata["total_tokens"]) ? parseInt(activity.metadata["total_tokens"]) :null
    

    return (tokensCount || activity?.costs)&&
    (
        <Tip plain  content={
            <Box  gap="3px" align="start" pad="0px 5px" background="white" round="5px" border>

                <Box direction="row"  gap="3px" >
                    <TbLetterCase color="dimgrey"/>
                    <Text color="dimgrey" size="14px">Tokens  used:</Text>
                    <Text size="13px" weight={900}>{tokensCount}</Text>   
                </Box>
                <Box pad="0px 0px 0px 15px">
                    {activity?.metadata?.llm_output?.token_usage && <KeyValuesComponent size="xs" object={activity?.metadata.llm_output?.token_usage}/>}
                </Box>
                
            </Box>
        }>
            {!activity?.costs ?(
            
            <Box direction="row" border round="large" gap="3px" align="center" pad="0px 5px">
                
                <TbLetterCase color="dimgrey"/>
                <Text color="dimgrey" size="10px">Tokens used:</Text>
                <Text size="10px" weight={900}>{tokensCount}</Text>
                
            </Box>
        ):(
        

            <Box direction="row" border round="large" gap="3px" align="center" pad="0px 5px" >
                
                <BiDollar color="dimgrey"/>
                <Text color="dimgrey" size="10px">Costs:</Text>
                <Text size="10px" weight={900}>{Math.round(activity?.costs*100*1000)/(100*1000)}</Text>
                
            </Box>
        )}
        </Tip>
    )
}
